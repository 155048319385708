!(function () {
  var t = L.Marker.prototype._initIcon,
    o = L.Marker.prototype._setPos,
    i = "msTransform" === L.DomUtil.TRANSFORM;
  L.Marker.addInitHook(function () {
    var t =
      this.options.icon &&
      this.options.icon.options &&
      this.options.icon.options.iconAnchor;
    t && (t = t[0] + "px " + t[1] + "px"),
      (this.options.rotationOrigin =
        this.options.rotationOrigin || t || "center bottom"),
      (this.options.rotationAngle = this.options.rotationAngle || 0),
      this.on("drag", function (t) {
        t.target._applyRotation();
      });
  }),
    L.Marker.include({
      _initIcon: function () {
        t.call(this);
      },
      _setPos: function (t) {
        o.call(this, t), this._applyRotation();
      },
      _applyRotation: function () {
        this.options.rotationAngle &&
          ((this._icon.style[L.DomUtil.TRANSFORM + "Origin"] =
            this.options.rotationOrigin),
          i
            ? (this._icon.style[L.DomUtil.TRANSFORM] =
                "rotate(" + this.options.rotationAngle + "deg)")
            : (this._icon.style[L.DomUtil.TRANSFORM] +=
                " rotateZ(" + this.options.rotationAngle + "deg)"));
      },
      setRotationAngle: function (t) {
        return (this.options.rotationAngle = t), this.update(), this;
      },
      setRotationOrigin: function (t) {
        return (this.options.rotationOrigin = t), this.update(), this;
      }
    });
})();
