<template>
  <b-card class="under-line-title">
    <validation-observer ref="loginForm" #default="{ invalid }">
      <b-form @submit.prevent="createAccount" @reset.prevent="resetAccount">
        <div
          v-if="
            (!isUpdate || isChildUpdate) && $route.name !== 'account-update'
          "
          class="d-flex align-items-center justify-content-between"
        >
          <h4
            class="card-title"
            v-if="
              $route.name == 'consumer-account-update-details' ||
              $route.name == 'consumer-sub-account-update-details'
            "
          >
            {{ $t("user.Update") }}
            {{ $t("Breadcrumb_.Consumer") }}
            {{ $t("AccountsMenu.Accounts") }}
          </h4>
          <h4
            class="card-title"
            v-else-if="
              $route.name == 'sub-account-update-details' ||
              $route.name == 'account-update-details'
            "
          >
            {{ $t("user.Update") }}
            {{ $t("Breadcrumb_.Dealer") }}
            {{ $t("AccountsMenu.Accounts") }}
          </h4>
          <h4
            class="card-title"
            v-if="
              $route.name !== 'consumer-account-update-details' &&
              $route.name !== 'account-update-details' &&
              $route.name !== 'sub-account-update-details' &&
              $route.name !== 'consumer-sub-account-update-details'
            "
          >
            {{
              account.type === "CONSUMER"
                ? $t("tooTip.addConsumerAccount")
                : $t("tooTip.addAccount")
            }}
          </h4>
          <div class="d-flex align-items-center">
            <b-button
              variant="outline-primary"
              size="sm"
              class="back-button v2-back"
              @click="redirectList()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
            </b-button>
          </div>
        </div>
        <div
          v-if="$route.name === 'account-update'"
          class="d-flex align-items-center justify-content-between"
        >
          <h4 class="card-title">
            {{ $t("Breadcrumb_.Account Settings") }}
          </h4>
          <div class="d-flex align-items-center">
            <b-button
              variant="outline-primary"
              size="sm"
              class="back-button v2-back"
              @click="redirectList()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
            </b-button>
          </div>
        </div>

        <div class="ac-card">
          <b-row class="mr-0 ml-0">
            <b-col sm="6">
              <b-form-group :label="$t('account.Name')">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="Name"
                  :rules="{
                    required: true,
                    min: constants.MIN_ACC_NAME,
                    max: constants.MAX_ACC_NAME
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="name"
                      v-model="account.name"
                      class="form-control-merge"
                      :class="
                        errors.length == 0 && account.name ? 'is-valid' : null
                      "
                      name="name"
                      :placeholder="$t('account.NamePlace')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <!-- <b-form-group :label="$t('account.Status')">
              <v-select v-model="selected" :options="option" />
              <b-form-invalid-feedback> </b-form-invalid-feedback>
            </b-form-group> -->

              <b-form-group :label="$t('account.Status')">
                <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="account.status"
                    :placeholder="$t('account.StatusPlace')"
                    :class="
                      errors.length == 0 && account.status
                        ? 'is-valid'
                        : 'is-invalid'
                    "
                    :options="option"
                    :reduce="(option) => option.key"
                    label="label"
                    :disabled="!isChildUpdate"
                  />

                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="$t('account.Address')">
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  vid="Address"
                  :rules="{
                    required: true,
                    min: constants.MIN_ACC_ADDRESS,
                    max: constants.MAX_ACC_ADDRESS,
                    regex: constants.ADDRESS_PATTERN
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="address"
                      v-model.trim="account.address"
                      :state="errors.length > 0 ? false : null"
                      :class="
                        errors.length == 0 && account.address
                          ? 'is-valid'
                          : null
                      "
                      class="form-control-merge"
                      name="address"
                      :placeholder="$t('account.AddressPlace')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <!-- <b-form-group :label="$t('account.Country')">
              <v-select
                id="vue-select"
                :placeholder="$t('user.SelectCountry')"
                :options="countries"
                :reduce="(option) => option.abbr2"
                label="name"
              />
              <b-form-invalid-feedback> </b-form-invalid-feedback>
            </b-form-group> -->
              <b-form-group :label="$t('account.Country')">
                <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="account.country"
                    :placeholder="$t('user.SelectCountry')"
                    :options="countries"
                    :reduce="(option) => option.abbr2"
                    label="name"
                    :class="
                      errors.length == 0 && account.country
                        ? 'is-valid'
                        : 'is-invalid'
                    "
                  />

                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="$t('account.Region')">
                <validation-provider
                  #default="{ errors }"
                  name="Region"
                  vid="Region"
                  :rules="{
                    required: true,
                    min: constants.MIN_ACC_REGION,
                    max: constants.MAX_ACC_REGION
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="region"
                      v-model="account.region"
                      :class="
                        errors.length == 0 && account.region ? 'is-valid' : null
                      "
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="region"
                      :placeholder="$t('account.RegionPlace')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="
                  !account.legal_entity
                    ? $t('account.RegistarationNumber')
                    : $t('account.RegistarationNumberRequired')
                "
              >
                <validation-provider
                  #default="{ errors }"
                  name="registration no"
                  vid="RegistarationNumber"
                  :rules="{ max: constants.MAX_ACC_REGISTRATION_NUM }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="registration_no"
                      :class="
                        errors.length == 0 && account.registration_no
                          ? 'is-valid'
                          : null
                      "
                      v-model="account.registration_no"
                      :disabled="!account.legal_entity"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="registration_no"
                      :rules="{
                        regex: constants.REGISTRATION_NUMBER_PATTERN
                      }"
                      :placeholder="$t('account.RegistarationNumber')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="!isUpdate">
              <b-form-group :label="$t('account.SelectCurrency')">
                <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->
                <validation-provider
                  #default="{ errors }"
                  name="currency"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="account.currency"
                    :class="
                      errors.length == 0 && account.currency
                        ? 'is-valid'
                        : 'is-invalid'
                    "
                    :placeholder="$t('user.SelectCurrency')"
                    :options="currency"
                    :reduce="(option) => option.abbr"
                    label="name"
                  />

                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6" v-if="!isUpdate">
              <b-form-group :label="$t('SubAccounts.Email')">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  :rules="{
                    required: true,
                    email: true
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="email"
                      v-model="account.email"
                      :class="
                        errors.length == 0 && account.email ? 'is-valid' : null
                      "
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="name"
                      @input="
                        () => {
                          account.email =
                            account.email && account.email.toLowerCase();
                        }
                      "
                      :placeholder="$t('user.EnterEmail')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mr-0 ml-0">
            <b-col sm="6">
              <b-row>
                <b-col
                  v-if="!isUpdate || (isUpdate && isChildUpdate)"
                  sm="6"
                  class="RestrictSubAccountsHeight mb-md-2"
                >
                  <b-form-group>
                    <b-form-checkbox
                      v-model="account.restrict_sub_accounts"
                      class="RestrictSubAccountsTop"
                      >{{ $t("account.RestrictSubAccounts") }}
                      <feather-icon
                        icon="HelpCircleIcon"
                        class="text-primary"
                        v-b-tooltip.hover.top="
                          $t('account.IsRestrictSubAccountsInfo')
                        "
                      />
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <!-- style="height: 60px" -->
                  <!-- :style="!account.legal_entity ? 'height: 79px' : ''" -->
                  <b-form-group>
                    <b-form-checkbox
                      v-model="account.legal_entity"
                      class="legal-entity"
                    >
                      {{ $t("account.IsLegalEntity") }}
                      <feather-icon
                        icon="HelpCircleIcon"
                        class="text-primary"
                        v-b-tooltip.hover.top="$t('account.IsLegalEntityInfo')"
                      />
                    </b-form-checkbox>

                    <b-form-invalid-feedback> </b-form-invalid-feedback>
                  </b-form-group>
                </b-col> </b-row
            ></b-col>

            <b-col sm="6"
              ><DateTimeFormat
                v-model="account.settings"
                :dateTimeFormats="dateTimeFormats"
              />
            </b-col>
          </b-row>
          <b-row v-if="isAllowedWhiteLabeling" class="mr-0 ml-0">
            <b-col sm="6" v-if="!isUpdate || (isUpdate && isChildUpdate)">
              <b-form-group>
                <b-form-checkbox
                  :disabled="!isAllowedWhiteLabeling"
                  v-model="account.allow_whitelabeling"
                  class="AllowWhiteLabellinTop"
                  >{{ $t("account.AllowWhiteLabelling") }}
                  <feather-icon
                    icon="HelpCircleIcon"
                    class="text-primary"
                    v-b-tooltip.hover.top="
                      $t('account.IsAllowWhiteLabellingInfo')
                    "
                  />
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="$t('account.DefaultTheme')">
                <div class="d-flex">
                  <div
                    v-for="theme in constants.THEMES"
                    :key="theme.value"
                    :class="
                      theme.value === account.theme
                        ? `theme-outer border-${theme.value} active ${
                            !isAllowedWhiteLabeling ? 'disabled' : ''
                          } `
                        : `theme-outer border-${theme.value} ${
                            !isAllowedWhiteLabeling ? 'disabled' : ''
                          }`
                    "
                  >
                    <div
                      :class="'theme-doth curserPointer ' + theme.value"
                      @click="selectedTheme(theme.value)"
                      v-b-tooltip.hover.top="theme.label"
                    ></div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isAllowedWhiteLabeling" class="mr-0 ml-0">
            <b-col sm="6">
              <!-- <b-form-group :label="$t('account.Status')">
              <v-select v-model="selected" :options="option" />
              <b-form-invalid-feedback> </b-form-invalid-feedback>
            </b-form-group> -->

              <b-form-group :label="$t('account.DefaultLanguage')">
                <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  :rules="{ required: true }"
                >
                  <v-select
                    v-model="account.lang"
                    :disabled="!isAllowedWhiteLabeling"
                    :placeholder="$t('account.DefaultLanguage')"
                    :options="languages"
                    :reduce="(option) => option.locale"
                    label="name"
                    menu-props="auto"
                    class="default-language"
                  >
                    <template #option="{ name }">
                      <!-- <b-img
                        :src="img"
                        height="14px"
                        width="22px"
                        :alt="name"
                      /> -->
                      <span class="ml-50 text-body">{{ name }}</span>
                    </template>
                    <template #selected-option="{ name }">
                      <!-- <b-img
                        :src="img"
                        height="14px"
                        width="22px"
                        :alt="name"
                      /> -->
                      <span class="ml-50 text-body">{{ name }}</span>
                    </template>
                  </v-select>

                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <div class="upload-logo">
                <b-form-group :label="$t('account.UploadLogo')">
                  <!-- <AccountLogoModal
                    :isDisabled="isAllowedWhiteLabeling"
                    v-model="files"
                  ></AccountLogoModal> -->
                  <AccountLogoModal
                    :isDisabled="isAllowedWhiteLabeling"
                    v-model="filesData"
                  ></AccountLogoModal>
                </b-form-group>
                <!-- <b-img class="profile-pic-height-account" :src="files.url" /> -->
              </div>
            </b-col>
          </b-row>
          <!-- <b-row class="mr-0 ml-0">
            <b-col>
              <b-button
                type="submit"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="
                  invalid ||
                  (!invalid &&
                    account.legal_entity &&
                    !account.registration_no) ||
                  (!isValidFiles && isAllowedWhiteLabeling)
                "
                class="mt-2 mb-2 mr-1"
              >
                {{ $t("profile.SaveChanges") }}
              </b-button>
            </b-col></b-row
          > -->
        </div>
        <div class="w-100 mt-1 d-flex justify-content-end">
          <div>
            <b-button
              v-if="
                $route.name === 'account-update' ||
                $route.name === 'account-update-details' ||
                $route.name === 'sub-account-update-details' ||
                $route.name === 'consumer-account-update' ||
                $route.name === 'consumer-account-update-details' ||
                $route.name === 'consumer-sub-account-update-details'
              "
              type="submit"
              variant="primary"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="
                invalid ||
                (!invalid &&
                  account.legal_entity &&
                  !account.registration_no) ||
                (!isValidFiles && isAllowedWhiteLabeling)
              "
              class="mr-1 back-button v2-back"
            >
              <feather-icon icon="SaveIcon" />
              <span class="d-none d-sm-inline ml-1">
                {{ $t("user.Update") }}</span
              >
            </b-button>
            <b-button
              v-if="
                (!isUpdate || isChildUpdate) &&
                $route.name !== 'account-update' &&
                $route.name !== 'account-update-details' &&
                $route.name !== 'sub-account-update-details' &&
                $route.name !== 'consumer-account-update' &&
                $route.name !== 'consumer-account-update-details' &&
                $route.name !== 'consumer-sub-account-update-details'
              "
              type="submit"
              variant="primary"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="
                invalid ||
                (!invalid &&
                  account.legal_entity &&
                  !account.registration_no) ||
                (!isValidFiles && isAllowedWhiteLabeling)
              "
              class="mr-1 back-button v2-back"
            >
              <feather-icon icon="UserIcon" />
              <span class="d-sm-inline ml-1">
                {{ $t("profile.SaveChanges") }}</span
              >
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>

    <Loader :show="show" />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BImg,
  VBTooltip
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import AccountService from "@/libs/api/account-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import AccountLogoModal from "@/layouts/components/ConsumerOnboarding/AccountLogoModal.vue";
import DateTimeFormat from "@/layouts/components/DateTime/DateTimeFormat.vue";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BCard,
    BInputGroup,
    BButton,
    Loader,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormInvalidFeedback,
    BFormCheckbox,
    BImg,
    AccountLogoModal,
    VBTooltip,
    DateTimeFormat
  },
  data() {
    return {
      dateTimeFormats: null,
      selected: "ACTIVE",
      option: [
        {
          label: this.$t("unit.StatusList.ACTIVE"),
          key: "ACTIVE"
        },
        {
          label: this.$t("unit.StatusList.INACTIVE"),
          key: "INACTIVE"
        },
        {
          label: this.$t("unit.StatusList.BLOCKED"),
          key: "BLOCKED"
        }
      ],
      countries: [],
      currency: [],

      languages:
        JSON.parse(localStorage.getItem("LANGUAGES")) ||
        constants.SUPPORTED_LANGUAGES,
      isAllowedWhiteLabeling: false,
      show: false,
      files: {
        url: "",
        logo_file_id: ""
      },
      isValidFiles: true,
      filesData: [
        {
          url: "",
          file_id: "",
          key: "light_expanded",
          label: "Light Mode"
        },
        {
          url: "",
          file_id: "",
          key: "dark_expanded",
          label: "Dark Mode"
        },
        {
          url: "",
          file_id: "",
          key: "light_collapsed",
          label: "Light Mode"
        },
        {
          url: "",
          file_id: "",
          key: "dark_collapsed",
          label: "Dark Mode"
        }
      ],
      url: "",
      isUpdate: false,
      isChildUpdate: false,
      account: {
        name: "",
        address: "",
        status: "ACTIVE",
        country: "",
        email: "",
        region: "",
        registration_no: "",
        currency: "USD",
        legal_entity: true,
        restrict_sub_accounts: false,
        type: "",
        billing_currency: "USD",
        logo_file_id: "",
        lang: "en",
        theme: "default-layout",
        allow_whitelabeling: false,
        settings: {
          date_format: "DD/MM/YYYY",
          date_time_format: "DD/MM/YYYY hh:mm A",
          time_format: "hh:mm A",
          timezone: "UTC"
        }
      }
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  mounted() {
    this.isUpdate =
      this.$route.name === "account-update" ||
      this.$route.name === "consumer-account-update"
        ? true
        : false;
    this.isUpdate =
      this.isUpdate ||
      (this.$route &&
        this.$route.query &&
        this.$route.query.id &&
        this.$route.query.isEdit);
    if (
      this.$route &&
      this.$route.query &&
      this.$route.query.id &&
      this.$route.query.isEdit
    ) {
      this.isChildUpdate = true;
    }
    if (
      localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER" &&
      this.$route.name === "dealer-creation"
    ) {
      this.$router.push({ name: "unauthorized" });
    }
    const account =
      localStorage.getItem("USER_ACCOUNT_DETAILS") &&
      JSON.parse(localStorage.getItem("USER_ACCOUNT_DETAILS"));

    this.isAllowedWhiteLabeling =
      account && account.allow_whitelabeling
        ? account.allow_whitelabeling
        : false;

    this.getRefData();
    if (
      this.isUpdate ||
      (this.$route &&
        this.$route.query &&
        this.$route.query.id &&
        this.$route.query.isEdit)
    ) {
      this.getAccountDetails();
    } else {
      this.getDealer();
      this.account.lang = account.lang;
      this.account.theme = account.theme;
    }
    if (this.isAllowedWhiteLabeling) {
      this.account.lang = account.lang || "en";
    }
    if (!this.$route.query.id) {
      if (this.$route.name === "dealer-creation") {
        this.$route.meta.breadcrumb = JSON.parse(
          JSON.stringify(constants.DEALER_ROUTE_META_BREADCRUMB)
        );
      } else if (this.$route.name === "consumer-creation") {
        this.$route.meta.breadcrumb = JSON.parse(
          JSON.stringify(constants.CONSUMER_ROUTE_META_BREADCRUMB)
        );
      }
      if (
        ![
          "consumer-account-update",
          "dealer-account-update",
          "account-update"
        ].includes(this.$route.name)
      ) {
        this.$route.meta.breadcrumb.push({
          text:
            this.account.type === "DEALER"
              ? this.$t("Breadcrumb_NewDealer")
              : this.$t("Breadcrumb_NewConsumer"),
          active: true,
          index: "new"
        });
      } else if (["consumer-creation"].includes(this.$route.name)) {
        this.$route.meta.breadcrumb.push({
          text:
            this.account.type === "DEALER"
              ? this.$t("Breadcrumb_NewDealer")
              : this.$t("Breadcrumb_NewConsumer"),
          active: true,
          index: "new"
        });
      }

      if (this.$route.meta.breadcrumb && this.$route.meta.breadcrumb.length) {
        this.$route.meta.breadcrumb[1].active = false;
      }
    }
  },
  watch: {
    filesData: {
      deep: true,
      handler(newOptions) {
        this.validateLogos();
      }
    }
  },
  methods: {
    async getRefData() {
      this.show = true;

      try {
        let response = await new AccountService().getRefData({});
        this.show = false;
        const custom_format = "";
        if (response && response.data) {
          this.countries = response.data.list || [];
          this.currency = response.data.currency || [];
          this.dateTimeFormats = response.data.date_time_formats;
          this.dateTimeFormats.push(custom_format);

          // console.log(this.dateTimeFormats);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "EditIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
      }
    },
    validateLogos() {
      const fileCodes = this.filesData.filter(
        (e) => !e.file_id || e.file_id != ""
      );
      if ([0, 4].includes(fileCodes.length)) {
        this.isValidFiles = true;
      } else {
        this.isValidFiles = false;
      }
    },
    redirectList() {
      if (this.$route.query.rd === "cl") {
        if (window.opener) {
          window.opener.location.reload(true);
        }
        window.close();
      }

      if (this.$route.name === "dealer-creation" && !this.$route.query.id) {
        this.$router.push({ name: "dealer" });
      } else if (
        this.$route.name === "consumer-creation" &&
        !this.$route.query.id
      ) {
        this.$router.push({ name: "consumer" });
      } else {
        this.$router.go(-1);
      }
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    selectedTheme(theme) {
      this.account.theme = theme;
    },
    async createAccount() {
      if (this.isUpdate) {
        this.updateAccount();
        return;
      }
      this.show = true;
      try {
        if (this.$route.name === "dealer-creation") {
          this.account.type = "DEALER";
        } else if (this.$route.name === "consumer-creation") {
          this.account.type = "CONSUMER";
        }
        this.validateLogos();
        if (this.isValidFiles) {
          const fileCodes = this.filesData.filter((e) => e.file_id);
          this.account.logo = fileCodes.length === 4 ? this.filesData : null;
        }
        this.account.email =
          this.account.email && this.account.email.toLowerCase();
        this.account.billing_currency = this.account.currency;
        this.account.currency = this.account.currency;
        this.account.logo_file_id =
          this.files && this.files.logo_file_id
            ? this.files.logo_file_id
            : null;
        this.logo = this.logo;
        this.account.account_id = this.$route.query.id || null;
        const isSubAccount =
          localStorage.getItem("USER_ACCOUNT_ID") !== this.account.account_id
            ? true
            : false;
        let response =
          this.account.type === "DEALER"
            ? await new AccountService().createDealerSubAccounts(this.account)
            : this.account.type === "CONSUMER"
            ? await new AccountService().createConsumerSubAccounts(this.account)
            : await new AccountService().createDealerConsumerSubAccounts(
                this.account
              );
        this.show = false;

        if (response && response.data) {
          if (this.$route.name === "dealer-creation") {
            if (this.account.account_id) {
              this.$router.push({
                params: { account_id: this.account.account_id },
                name: "sub_dealer"
              });
            } else {
              this.$router.push({ name: "dealer" });
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("SubAccounts.DealerAccountCreatedTitle"),
                text: this.$t("SubAccounts.DealerAccountCreatedMsg"),
                icon: "EditIcon",
                variant: "error"
              }
            });
          } else if (this.$route.name === "consumer-creation") {
            if (this.account.account_id) {
              if (
                this.$route.query &&
                this.$route.query.tab === "sub_consumer"
              ) {
                this.$router.push({
                  params: { account_id: this.account.account_id },
                  name: "sub_consumer"
                });
              } else {
                this.$router.push({
                  params: { account_id: this.account.account_id },
                  name: "sub_dealer"
                });
              }
            } else {
              this.$router.push({ name: "consumer" });
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("SubAccounts.ConsumerAccountCreatedTitle"),
                text: this.$t("SubAccounts.ConsumerAccountCreatedMsg"),
                icon: "EditIcon",
                variant: "error"
              }
            });
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
      }
    },
    async updateAccount() {
      this.show = true;
      let obj = {};
      if (this.isAllowedWhiteLabeling || this.isChildUpdate) {
        obj = {
          logo_file_id:
            this.files && this.files.logo_file_id
              ? this.files.logo_file_id
              : this.account.logo_file_id
              ? this.account.logo_file_id
              : null,
          lang: this.account.lang,
          theme: this.account.theme
        };
        this.validateLogos();
        if (this.isValidFiles) {
          const fileCodes = this.filesData.filter((e) => e.file_id);
          obj.logo = fileCodes.length === 4 ? this.filesData : null;
        }
      }

      try {
        if (
          this.$route.name === "account-update-details" ||
          this.$route.name === "account-update" ||
          this.$route.name === "sub-account-update-details"
        ) {
          this.account.type = "DEALER";
        } else if (
          this.$route.name === "consumer-account-update-details" ||
          this.$route.name === "consumer-sub-account-update-details" ||
          this.$route.name === "consumer-account-update"
        ) {
          this.account.type = "CONSUMER";
        }
        const settings = {};
        settings.date_time_format =
          this.account.settings.date_time_format ||
          constants.SETTINGS.date_time_format;
        settings.date_format =
          this.account.settings.date_format || constants.SETTINGS.date_format;
        settings.time_format =
          this.account.settings.time_format || constants.SETTINGS.date_format;
        settings.timezone =
          this.account.settings.timezone || constants.timezone;
        if (this.account.settings.date_format == "Custom") {
          settings.date_format = this.account.settings.custom_date;
        }
        if (this.account.settings.time_format == "Custom") {
          settings.time_format = this.account.settings.custom_time;
        }

        const account = {
          name: this.account.name,
          address: this.account.address,
          country: this.account.country,
          region: this.account.region,
          settings: settings,
          registration_no: this.account.registration_no,
          legal_entity: this.account.legal_entity,
          allow_whitelabeling: this.account.allow_whitelabeling || false,
          restrict_sub_accounts: this.account.restrict_sub_accounts || false,
          ...obj
        };
        if (this.isChildUpdate) {
          account.status = this.account.status;
        }
        let response;
        if (
          this.$route &&
          this.$route.query &&
          this.$route.query.id &&
          this.$route.query.account_id
        ) {
          account.child_id = this.$route.query.id;

          response =
            this.account.type === "DEALER"
              ? await new AccountService().updateDealerChildAccountDetails(
                  account
                )
              : this.account.type === "CONSUMER"
              ? await new AccountService().updateConsumerChildAccountDetails(
                  account
                )
              : await new AccountService().updateChildAccountDetails(account);
        } else if (this.$route && this.$route.query && this.$route.query.id) {
          account.child_id = this.$route.query.id;

          response =
            this.account.type === "DEALER"
              ? await new AccountService().updateDealerAccountDetails(account)
              : this.account.type === "CONSUMER"
              ? await new AccountService().updateConsumerAccountDetails(account)
              : await new AccountService().updateAccountDetails(account);
        } else {
          response =
            this.account.type === "DEALER"
              ? await new AccountService().updateDealerAccountDetails(account)
              : this.account.type === "CONSUMER"
              ? await new AccountService().updateConsumerAccountDetails(account)
              : await new AccountService().updateAccountDetails(account);
        }

        this.show = false;

        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("account.AccountUpdate"),
              text: "",
              icon: "EditIcon",
              variant: "success"
            }
          });
          localStorage.setItem("USER_ACCOUNT_NAME", this.account.name);
          if (this.$route.query.rd === "cl") {
            if (window.opener) {
              window.opener.location.reload(true);
            }
            window.close();
          } else {
            if (this.$route.name === "account-update") {
              this.$router.go();
            } else {
              this.$router.go(-1);
            }
            this.$router.go();
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
      }
    },
    async getDealer() {
      try {
        if (this.$route.name === "dealer-creation") {
          this.account.type = "DEALER";
        } else if (this.$route.name === "consumer-creation") {
          this.account.type = "CONSUMER";
        }
        if (this.$route.query.id) {
          this.show = true;
          const me = this;
          let accountIdObj = this.$route.query.id
            ? { account_id: this.$route.query.id }
            : {};
          store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", true);
          let response = this.$route.query.id
            ? this.account.type === "DEALER"
              ? await new AccountService().getSubDealerList({
                  page: 1,
                  type: this.account.type,
                  page_size: 1,
                  ...accountIdObj
                })
              : this.account.type === "CONSUMER"
              ? await new AccountService().getSubConsumerList({
                  page: 1,
                  type: this.account.type,
                  page_size: 1,
                  ...accountIdObj
                })
              : await new AccountService().getSubDealerConsumerList({
                  page: 1,
                  type: this.account.type,
                  page_size: 1,
                  ...accountIdObj
                })
            : this.account.type === "DEALER"
            ? await new AccountService().getDealerList({
                page: 1,
                type: this.account.type,
                page_size: 1
              })
            : this.account.type === "CONSUMER"
            ? await new AccountService().getConsumerList({
                page: 1,
                type: this.account.type,
                page_size: 1
              })
            : await new AccountService().getDealerConsumerList({
                page: 1,
                type: this.account.type,
                page_size: 1
              });

          if (response && response.data) {
            this.show = false;

            if (
              response.data.newAncestor &&
              response.data.newAncestor.length &&
              this.$route.meta.breadcrumb &&
              this.$route.meta.breadcrumb.length
            ) {
              store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
              if (
                response.data.newAncestor &&
                response.data.newAncestor.length &&
                response.data.newAncestor[0].type == "DEALER"
              ) {
                this.$route.meta.breadcrumb = JSON.parse(
                  JSON.stringify(constants.DEALER_ROUTE_META_BREADCRUMB)
                );
                if (
                  this.$route.meta.breadcrumb &&
                  this.$route.meta.breadcrumb.length >= 2
                ) {
                  this.$route.meta.breadcrumb[1].active = false;
                }
              }
              if (
                response.data.newAncestor &&
                response.data.newAncestor.length &&
                response.data.newAncestor[0].type == "CONSUMER"
              ) {
                this.$route.meta.breadcrumb = JSON.parse(
                  JSON.stringify(constants.CONSUMER_ROUTE_META_BREADCRUMB)
                );
                if (
                  this.$route.meta.breadcrumb &&
                  this.$route.meta.breadcrumb.length >= 2
                ) {
                  this.$route.meta.breadcrumb[1].active = false;
                }
              }

              response.data.newAncestor.forEach((a, i) => {
                let active = false;
                this.$route.meta.breadcrumb[1].active = false;
                if (this.$route.params.account_id == a.id) {
                  active = true;
                }
                this.$route.meta.breadcrumb.push({
                  text: a.name,
                  to: {
                    params: { account_id: a.id },
                    name: "sub_dealer"
                  },
                  active,
                  index: a.name + i
                });
                if (
                  a.type &&
                  a.type.toLowerCase() === "dealer" &&
                  this.account.type === "CONSUMER"
                ) {
                  this.$route.meta.breadcrumb[2].to = {
                    params: { account_id: a.id },
                    name: "sub_dealer"
                  };
                  this.$route.meta.breadcrumb.push({
                    text: "Breadcrumb_Consumer",
                    active: true,
                    index: "consumer",
                    to: {
                      params: { account_id: a.id },
                      name: "sub_dealer",
                      query: { tab: "consumer" }
                    },
                    active
                  });
                }
              });
            }
          } else if (response && response.error && response.error.message) {
            store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
        }
        // this.$route.meta.breadcrumb =
        //   this.account.type === "DEALER"
        //     ? JSON.parse(JSON.stringify(constants.DEALER_ROUTE_META_BREADCRUMB))
        //     : JSON.parse(
        //         JSON.stringify(constants.CONSUMER_ROUTE_META_BREADCRUMB)
        //       );
        if (this.account.type === "DEALER") {
        } else {
        }
        const newBreadcrumb = this.$route.meta.breadcrumb.find((e) =>
          [
            this.$t("Breadcrumb_NewDealer"),
            this.$t("Breadcrumb_NewConsumer")
          ].includes(e.text)
        );
        if (!newBreadcrumb || !newBreadcrumb.text) {
          this.$route.meta.breadcrumb.push({
            text:
              this.account.type === "DEALER"
                ? this.$t("Breadcrumb_NewDealer")
                : this.$t("Breadcrumb_NewConsumer"),
            active: true,
            index: "new"
          });
          if (
            this.$route.meta.breadcrumb &&
            this.$route.meta.breadcrumb.length
          ) {
            this.$route.meta.breadcrumb[1].active = false;
          }
        }
      } catch (err) {
        store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    async getAccountDetails() {
      try {
        const requestData = {};
        if (this.$route && this.$route.query && this.$route.query.id) {
          requestData.child_id = this.$route.query.id;
        }
        let response = null;
        store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", true);
        if (
          this.$route.name === "account-update-details" ||
          this.$route.name === "sub-account-update-details" ||
          this.$route.name === "account-update"
        ) {
          response = await new AccountService().getDealerAccountDetails(
            requestData
          );
        } else if (
          this.$route.name === "consumer-account-update-details" ||
          this.$route.name === "consumer-sub-account-update-details" ||
          this.$route.name === "consumer-account-update"
        ) {
          response = await new AccountService().getConsumerAccountDetails(
            requestData
          );
        } else {
          response = await new AccountService().getAccountDetails(requestData);
        }
        if (response && response.data) {
          store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
          const account = response.data && response.data.account;
          this.show = false;
          this.account = {
            name: account.name,
            address: account.address,
            country: account.country,
            region: account.region,
            registration_no: account.registration_no,
            legal_entity: account.legal_entity,
            restrict_sub_accounts: account.restrict_sub_accounts,
            status: account.status,
            logo_file_id: account.logo_file_id,
            allow_whitelabeling: account.allow_whitelabeling || false,
            lang: account.lang,
            theme: account.theme,
            settings: account.settings
          };
          if (
            response.data.newAncestor &&
            response.data.newAncestor.length &&
            this.$route.meta.breadcrumb &&
            this.$route.meta.breadcrumb.length &&
            (this.$route.name === "account-update-details" ||
              this.$route.name === "consumer-account-update-details" ||
              this.$route.name === "sub-account-update-details" ||
              this.$route.name === "consumer-sub-account-update-details")
          ) {
            if (account.type === "CONSUMER") {
              this.$route.meta.breadcrumb = JSON.parse(
                JSON.stringify(constants.CONSUMER_ROUTE_META_BREADCRUMB)
              );
            } else {
              this.$route.meta.breadcrumb = JSON.parse(
                JSON.stringify(constants.DEALER_ROUTE_META_BREADCRUMB)
              );
            }
            if (
              response.data.newAncestor &&
              response.data.newAncestor.length &&
              response.data.newAncestor[0].type == "DEALER"
            ) {
              this.$route.meta.breadcrumb = JSON.parse(
                JSON.stringify(constants.DEALER_ROUTE_META_BREADCRUMB)
              );
              this.$route.meta.breadcrumb[1].active = false;
            }

            response.data.newAncestor.forEach((a, i) => {
              let active = false;
              this.$route.meta.breadcrumb[1].active = false;
              if (this.$route.params.account_id == a.id) {
                active = true;
              }
              this.$route.meta.breadcrumb.push({
                text: a.name,
                to: {
                  params: { account_id: a.id },
                  name: "sub_dealer"
                },
                active,
                index: a.name + i
              });
              if (
                a.type &&
                a.type.toLowerCase() === "dealer" &&
                this.$route.name === "consumer-sub-account-update-details"
              ) {
                this.$route.meta.breadcrumb[2].to = {
                  params: { account_id: a.id },
                  name: "sub_dealer"
                };

                this.$route.meta.breadcrumb.push({
                  text: "Breadcrumb_Consumer",
                  active: true,
                  index: "consumer",
                  to: {
                    params: { account_id: a.id },
                    name: "sub_dealer",
                    query: { tab: "consumer" }
                  },
                  active
                });
              }
            });
            this.$route.meta.breadcrumb.push({
              text:
                account.type === "DEALER"
                  ? this.$t("Breadcrumb_Edit")
                  : this.$t("Breadcrumb_Edit"),
              active: true,
              index: "new"
            });
            if (
              this.$route.meta.breadcrumb &&
              this.$route.meta.breadcrumb.length
            ) {
              this.$route.meta.breadcrumb[1].active = false;
            }
          }

          if (account.logo && account.logo.length) {
            this.filesData = account.logo;
            if (!this.isChildUpdate) {
              store.commit("device/setIcons", account.logo);
            }
          }
          if (
            account &&
            account.white_labeling &&
            account.white_labeling.logo
          ) {
            this.files.url = account.white_labeling.logo;
          }
          if (!this.isAllowedWhiteLabeling || this.isChildUpdate) {
            this.account.lang =
              account.white_labeling && account.white_labeling.language
                ? account.white_labeling.language
                : "en";
            this.account.theme =
              account.white_labeling && account.white_labeling.theme
                ? account.white_labeling.theme
                : "default-layout";
          }
          this.account.lang = account.lang || "en";
          this.validateLogos();
        } else if (response && response.error && response.error.message) {
          store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        store.commit("mapLoader/SET_IS_BREADCRUMB_LOADING", false);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.ac-card {
  height: calc(100vh - 280px);
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.theme-doth {
  height: 22px;
  width: 22px;
  border-radius: 22px;
  float: left;
}
.theme-outer {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  border-radius: 50%;
}
.profile-pic-height-account {
  height: 37px;
}
.logo-content {
  margin-top: 27px;
}
.legal-entity {
  top: 30px;
}
.upload-logo {
  display: flex;
  align-items: flex-end;
  width: 100%;
  .form-group {
    width: 100%;
  }
  .profile-pic-height-account {
    margin-bottom: 1rem;
    margin-left: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .legal-entity {
    top: 0px;
  }
  .RestrictSubAccountsTop {
    top: 0px !important;
  }
  .RestrictSubAccountsHeight {
    height: 40px !important;
  }
  .AllowWhiteLabellinTop {
    top: 0px !important;
  }
}
.RestrictSubAccountsTop {
  top: 30px;
}
.RestrictSubAccountsHeight {
  height: 60px;
}
.AllowWhiteLabellinTop {
  top: 30px;
}
.form-group .is-valid .vs__dropdown-toggle {
  .vs__actions {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
    min-width: 46px;
    justify-content: flex-end;
    position: relative;
    background-position: 4px 9px;
    margin-left: 0px !important;
  }
}
.form-group .is-valid .vs__dropdown-toggle {
  .vs__clear {
    opacity: 0;
    pointer-events: none;
  }
  .vs__search {
    width: calc(100% - 10px);
  }
}
.dark-layout {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(41 48 70) inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #b4b7bd !important;
  }
  .form-control.is-valid {
    border-color: var(--success) !important;
  }
  .form-group .is-valid .vs__dropdown-toggle {
    border-color: var(--success) !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(252, 252, 252) inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #babac4 !important;
}
.form-control.is-valid {
  border-color: var(--success) !important;
}
.form-group .is-valid .vs__dropdown-toggle {
  border-color: var(--success) !important;
}
.is-valid {
  .vs__search {
    height: 0px;
  }
}
@media screen and (max-width: 991px) {
  .card-title {
    font-size: 14px;
  }
}
</style>
