<template>
  <b-modal
    id="modal-add-sensors"
    centered
    :scrollable="true"
    size="lg"
    :hide-header="true"
    no-close-on-esc
    no-close-on-backdrop
    modal-class="modal-add-sensors no-header-modal modal-add-sensors-v1"
    class="ddd123"
  >
    <div style="padding-top: 10px" class="tab-new-ui">
      <div class="slect-account-title-center">
        {{
          tab == "add" || tab == "clone"
            ? $t("device.NewSensor")
            : isViewOnly
            ? $t("device.ViewSensor")
            : $t("device.UpdateSensor")
        }}
      </div>
      <div class="d-flex justify-content-end" v-if="tab == 'add'">
        <div class="active ml-1 mr-1" v-if="!isViewOnly">
          <b-button
            variant="outline-light"
            size="sm"
            class="custom-sensor-upload-button mobile-calculation-table"
            v-b-tooltip.hover.top="$t('device.Upload Sensor')"
          >
            <UploadDark />
            <b-form-file
              class="custom-file-input"
              v-if="!isViewOnly"
              v-model="inputJsonFile"
              @input="importJson"
            />
          </b-button>
        </div>
      </div>
      <div class="sensor-modal-scroll">
        <div>
          <validation-observer
            ref="sensorsRef"
            tag="form"
            #default="{ invalid }"
          >
            <div class="sensors-sc">
              <b-row>
                <b-col sm="6" class="sensor-slection">
                  <validation-provider
                    #default="validationContext"
                    name="Type"
                    rules="required"
                  >
                    <b-form-group
                      :style="tab === 'edit' ? 'pointer-events: none' : ''"
                      :label="$t('device.SensorType') + ' *'"
                    >
                      <v-select
                        id="vue-select"
                        :placeholder="$t('device.SensorType')"
                        v-model="selectedSensor"
                        :options="sensors"
                        :clearable="false"
                        :reduce="(option) => option.type"
                        @search="search"
                        label="type_title"
                        height="80"
                        :class="tab === 'edit' ? 'disabled' : ''"
                      />
                      <b-form-invalid-feedback>
                        {{
                          validationContext.errors[0]
                            ? handleError(validationContext.errors[0])
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col sm="3" class="visiable-sensor">
                  <!-- <b-form-group :label=""> -->
                  <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->

                  <b-form-checkbox
                    v-model="sensorInfo.visible"
                    :disabled="isViewOnly"
                    >{{ $t("device.SensorVisible") }}</b-form-checkbox
                  >
                  <!-- </b-form-group> -->
                </b-col>
                <b-col sm="6">
                  <validation-provider
                    #default="validationContext"
                    name="Sensor Name"
                    rules="required"
                  >
                    <b-form-group :label="$t('device.SensorName') + ' *'">
                      <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->

                      <b-form-input
                        :placeholder="$t('device.SensorNamePlace')"
                        v-model="sensorInfo.name"
                        :disabled="isViewOnly"
                        ref="name_input"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{
                          validationContext.errors[0]
                            ? handleError(validationContext.errors[0])
                            : ""
                        }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm="6">
                  <b-form-group :label="$t('device.SensorDescription')">
                    <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->

                    <b-form-textarea
                      id="textarea"
                      v-model="sensorInfo.description"
                      :disabled="isViewOnly"
                      :placeholder="$t('device.SensorDescriptionPlace')"
                      rows="2"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  v-if="selectedSensorType && selectedSensorType.length"
                >
                  <b-form-group>
                    <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->
                    <label class="parameters-label">{{
                      $t("device.Parameters")
                    }}</label>
                    <div>
                      <parameter-components
                        :parameters="selectedSensorType"
                        :isEditable="!isViewOnly"
                        :protocolName="protocolName"
                        :sensorInfo="sensorInfo"
                        :selectedSensor="selectedSensor"
                        :device="device"
                        v-model="sensorInfo"
                        :sensorsData="device.sensors"
                        @isInvalid="isInvalid"
                        @validationError="showValidationError"
                      />
                      <!-- <b-row>
                      <b-col
                        sm="12"
                        v-for="sensorField in selectedSensorType"
                        :key="sensorField.field"
                      >
                        <b-row
                          v-if="
                            sensorField.source === 'config' &&
                            [
                              'string',
                              'number',
                              'boolean',
                              'timestamp',
                              'datetime'
                            ].includes(sensorField.data_type.toLowerCase())
                          "
                        >
                          <b-col
                            style="margin-top: 27px; padding-right: 10px"
                            sm="3"
                          >
                            <span style="text-transform: capitalize">
                              {{
                                sensorField.label
                                  ? sensorField.label
                                  : sensorField.field &&
                                    formattedString(sensorField.field)
                              }}
                              {{ sensorField.is_required ? "* :" : " :" }}
                            </span>
                          </b-col>
                          <b-col
                            v-if="
                              ['boolean'].includes(
                                sensorField.data_type.toLowerCase()
                              )
                            "
                            style="margin-top: 17px"
                            sm="6"
                          >
                            <b-form-group>
                              <b-form-checkbox
                                :disabled="isViewOnly"
                                v-model="
                                  sensorInfo.fields[sensorField.field].value
                                "
                                style="top: 10px"
                              >
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                          <b-col
                            style="margin-top: 17px"
                            sm="6"
                            v-if="
                              ['timestamp', 'datetime'].includes(
                                sensorField.data_type.toLowerCase()
                              )
                            "
                          >
                            <b-form-group>
                              <div class="flat-picker-date">
                                <flat-pickr
                                  :disabled="isViewOnly"
                                  v-model="
                                    sensorInfo.fields[sensorField.field].value
                                  "
                                  @on-close="
                                    DateTimeFormat(
                                      sensorField.field,
                                      sensorInfo.fields[sensorField.field].value
                                    )
                                  "
                                  :ref="`dateTime_${sensorField.field}`"
                                  class="form-control date-time-input"
                                  :config="{
                                    enableTime: true,
                                    time_24hr: true
                                  }"
                                />
                                <div class="flat-picker-icon">
                                  <a
                                    ><feather-icon
                                      icon="XIcon"
                                      size="24"
                                      @click="clearDate(sensorField.field)"
                                  /></a>
                                </div>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col
                            style="margin-top: 17px"
                            sm="6"
                            v-if="
                              ['string', 'number'].includes(
                                sensorField.data_type.toLowerCase()
                              )
                            "
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="sensorField.field"
                              :type="
                                sensorField.data_type.toLowerCase() == 'number'
                                  ? 'number'
                                  : 'text'
                              "
                              :rules="sensorField.is_required ? 'required' : ''"
                            >
                              <b-form-group>
                                <b-form-input
                                  v-model="
                                    sensorInfo.fields[sensorField.field].value
                                  "
                                  :disabled="isViewOnly"
                                  class="d-inline-block open-tel-parama"
                                  :state="getValidationState(validationContext)"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row v-if="sensorField.source === 'input'">
                          <b-col
                            style="margin-top: 27px; padding-right: 0px"
                            sm="3"
                          >
                            <span style="text-transform: capitalize">
                              {{
                                sensorField.label
                                  ? sensorField.label
                                  : sensorField.field &&
                                    formattedString(sensorField.field)
                              }}
                              {{ sensorField.is_required ? "* :" : " :" }}
                            </span>
                          </b-col>

                          <div>
                            <b-col
                              style="margin-top: 17px"
                              sm="12"
                              class="d-flex parameterssize"
                            >
                              <validation-provider
                                class="main123"
                                #default="validationContext"
                                :name="sensorField.field"
                                :rules="
                                  sensorField.is_required ? 'required' : ''
                                "
                              >
                                <DeviceProtocolParametersModal
                                  v-model="
                                    sensorInfo.fields[sensorField.field].value
                                  "
                                  :isViewOnly="isViewOnly"
                                  :protocolName="protocolName"
                                  :sensorField="{
                                    field: sensorField.field,
                                    data_type: sensorField.data_type
                                  }"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                  {{
                                    validationContext.errors[0]
                                      ? handleError(validationContext.errors[0])
                                      : ""
                                  }}
                                </b-form-invalid-feedback>
                              </validation-provider>
                              <div
                                class="sensor-check ml-1"
                                v-if="
                                  sensorField.data_type &&
                                  sensorField.data_type.toLowerCase() ==
                                    'number'
                                "
                              >
                                <b-form-checkbox
                                  :checked="checkTableValue(sensorField)"
                                  class="disabled-check-box"
                                >
                                </b-form-checkbox>
                              </div>
                              <div class="calculationTable">
                                <CalculationTableModal
                                  v-model="
                                    sensorInfo.fields[sensorField.field].table
                                  "
                                  :isViewOnly="isViewOnly"
                                  :selectTelTabValue="selectTelTabValue"
                                  :sensorInfo="sensorInfo"
                                  :sensorField="sensorField"
                                  :checkBoxEnabled="checkBoxEnabled"
                                  :keyField="sensorField.field"
                                >
                                </CalculationTableModal>
                              </div>
                            </b-col>
                          </div>
                        </b-row>
                      </b-col>
                    </b-row> -->
                      <!-- {{ sensorInfo.fields }} -->
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="d-flex justify-content-center save-btn-footer">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="invalid || isInputValidate"
                v-if="!isViewOnly"
                @click="addDeviceSensors(sensorInfo)"
              >
                {{ $t("device.Save") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click="onCloseDeviceSensors()"
              >
                {{ $t("device.Cancel") }}
              </b-button>
            </div>
          </validation-observer>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import DeviceService from "@/libs/api/device-service";
import formValidation from "@core/comp-functions/forms/form-validation";
// import CalculationTable from "./CalculationTable.vue";
import CalculationTableModal from "./CalculationTableModal.vue";
import store from "@/store";
import DeviceProtocolParameters from "./DeviceProtocolParameters.vue";
import DeviceProtocolParametersModal from "./DeviceProtocolParametersModal.vue";
import BGImageURL from "@/assets/images/new-icon/open-tel-parama.svg";
import DownloadDark from "@/assets/images/new-icon/download-dark.svg";
import UploadDark from "@/assets/images/new-icon/upload-dark.svg";
// import flatPickr from "vue-flatpickr-component";
import ParameterComponents from "@/views/DynamicComponents/ParameterComponents.vue";
import { mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BAvatar,
  BButton,
  BForm,
  BTab,
  BTabs,
  BCard,
  BMedia,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormCheckbox,
  VBTooltip,
  BInputGroupAppend,
  BInputGroup,
  BFormFile
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BImg,
    BForm,
    BCardCode,
    BTab,
    BTabs,
    BCard,
    BMedia,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    // CalculationTable,
    DeviceProtocolParameters,
    VBTooltip,
    BInputGroupAppend,
    BInputGroup,
    BGImageURL,
    CalculationTableModal,
    DeviceProtocolParametersModal,
    // flatPickr,
    ParameterComponents,
    DownloadDark,
    UploadDark,
    BFormFile
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      getValidationState,
      sensor: {},
      calTable: [],
      readOnlyTxt: true,
      selectedSensorType: [],
      isEnabledCalculation: false,
      selectedSensor: "",
      showLoading: false,
      isInputValidate: false,
      validationError: "",
      sensors: [],
      sensorInfo: {
        name: "",
        type: "",
        description: "",
        multi_sensors: false,
        visible: true,
        fields: {},
        isEditable: ""
      },
      refreshTelParaModal: "",
      selectedTelPara: "",

      selectedTable: {},
      selectTelTabValue: [],
      inputJsonFile: []
    };
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },

  mounted() {
    // this.getAllSensors("allList");
    this.sensors = this.sensorsData;
    store.commit("sensor/SET_FUEL_SENSOR_UNIT", "");
  },
  props: [
    "sensorProp",
    "saveDeviceAddSensors",
    "onCloseDeviceAddSensors",
    "refreshModal",
    "tab",
    "deviceDataUpdated",
    "protocolName",
    "isViewOnly",
    "sensorsData",
    "device"
  ],
  watch: {
    selectedSensor(newVal, old) {
      this.selectSensor(true);
    },
    sensorProp() {
      this.getSensorInfo();
    },

    refreshModal() {
      this.getSensorInfo();
    },

    deviceDataUpdated() {}
  },
  computed: {
    ...mapGetters("device", ["getStreamUrlData"])
  },

  methods: {
    async addDeviceSensors(sensorInfo) {
      let res = await this.saveDeviceAddSensors({
        ...sensorInfo,
        ...(Object.keys(this.getStreamUrlData).length && {
          streamUrlData: this.getStreamUrlData
        })
      });
      if (res) {
        this.selectedSensor = "";
        this.sensorInfo = { visible: true };
      }
    },
    onCloseDeviceSensors() {
      this.onCloseDeviceAddSensors();
      this.selectedSensor = "";
      this.sensorInfo = { visible: true };
      this.sensorInfo.fields = {};
      store.commit("sensor/SET_FUEL_SENSOR_UNIT", "");
    },
    isInvalid(flag) {
      this.isInputValidate = !flag;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    clearDate(field) {
      if (
        this.$refs["dateTime_" + field] &&
        this.$refs["dateTime_" + field].length
      ) {
        this.$refs["dateTime_" + field][0].fp.clear();
      }
    },
    checkBoxEnabled() {
      // this.$refs.name_input.focus();
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    DateTimeFormat(filed, value) {
      this.sensorInfo.fields[filed].value = new Date(value);
    },
    search(filter) {
      this.getAllSensors(filter);
    },
    getSensorInfo() {
      const sensorsData = JSON.parse(JSON.stringify(this.sensorProp));

      if (sensorsData && Object.keys(sensorsData).length) {
        this.sensorInfo = {
          name: (sensorsData && sensorsData.name) || "",
          type: (sensorsData && sensorsData.type) || "",
          multi_sensors: (sensorsData && sensorsData.multi_sensors) || false,
          description: (sensorsData && sensorsData.description) || "",
          visible: (sensorsData && sensorsData.visible) || false,
          fields: (sensorsData && sensorsData.fields) || {},
          isEditable: (sensorsData && sensorsData.isEditable) || false,
          index: (sensorsData && sensorsData.index) || "",
          streamUrlData: (sensorsData && sensorsData.streamUrlData) || ""
        };
        this.selectedSensor = this.sensorInfo.type;
        this.selectSensor(false);
      }
    },
    showValidationError(error) {
      this.validationError = error;
    },

    selectSensor(isClearFiled) {
      const selectedSensor = this.sensors.find(
        (e) =>
          e.type &&
          this.selectedSensor &&
          e.type.toLowerCase() === this.selectedSensor.toLowerCase()
      );
      this.selectedSensorType = [];
      if (selectedSensor) {
        if (this.sensorInfo) {
          this.sensorInfo.type = this.selectedSensor;
        }
        this.sensorInfo.multi_sensors = selectedSensor.multi_sensors;

        this.selectedSensorType = JSON.parse(
          JSON.stringify(selectedSensor.fields)
        );
        if (isClearFiled) {
          this.sensorInfo.fields = {};
        }
        let getCustomSensors = [];
        if (this.sensorInfo.fields && this.sensorInfo.fields) {
          getCustomSensors = Object.keys(this.sensorInfo.fields).filter(
            (item) => !selectedSensor.fields.map((e) => e.field).includes(item)
          );
          if (getCustomSensors && getCustomSensors.length) {
            Object.keys(this.sensorInfo.fields).forEach((element) => {
              if (
                !selectedSensor.fields.map((e) => e.field).includes(element)
              ) {
                const sensor = this.sensorInfo.fields[element];
                sensor.field = element;
                this.selectedSensorType.push(sensor);
              }
            });
          }
        }

        this.selectedSensorType.forEach((e) => {
          this.sensorInfo.fields[e.field] = {
            source: e.source,
            value:
              this.sensorInfo.fields[e.field] &&
              this.sensorInfo.fields[e.field].value,

            table:
              (this.sensorInfo.fields[e.field] &&
                this.sensorInfo.fields[e.field].table) ||
              [],
            table_info:
              (this.sensorInfo.fields[e.field] &&
                this.sensorInfo.fields[e.field].table_info) ||
              {},
            isHide: e.component === "custom" ? true : false,
            label:
              (this.sensorInfo.fields[e.field] &&
                this.sensorInfo.fields[e.field].label) ||
              e.field
          };
          if (e.data_type && e.data_type.toLowerCase() === "datetime") {
            this.sensorInfo.fields[e.field].data_type = e.data_type;
          }
        });
      }
    },

    checkTableValue(table) {
      const field = table && table.field;

      const isValid =
        this.sensorInfo &&
        this.sensorInfo.fields &&
        this.sensorInfo.fields[field] &&
        this.sensorInfo.fields[field].table &&
        this.sensorInfo.fields[field].table.length
          ? true
          : false;
      return isValid;
    },
    formattedString(filed) {
      return this.formattedString(filed, "_", " ");
    },
    async getAllSensors(search) {
      try {
        this.showLoading = true;
        let response = await new DeviceService().getAllSensors({
          filter: search || undefined
        });

        this.showLoading = false;
        if (response && response.data) {
          this.getSensorInfo();
          this.sensors = response.data.sensors;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    importJson() {
      this.$emit("uploadSensor", {
        inputJsonFile: this.inputJsonFile,
        uploadJson: "uploadJson"
      });
      setTimeout(() => {
        this.inputJsonFile = [];
      }, 100);
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.sensor-slection {
  .v-select {
    .vs__dropdown-menu {
      max-height: 160px !important;
    }
  }
}

.custom-sensor-upload-button {
  padding: 0.3rem 1.5rem;
  position: relative;
  cursor: pointer;
  .custom-file-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: $percent_100;
    height: $percent_100;
    opacity: 0;
    cursor: pointer;
  }
  .custom-file-label {
    cursor: pointer;
  }
}
</style>
<style lang="scss" scoped>
.open-tel-parama {
  background-image: url("./../../assets/images/new-icon/open-tel-parama.svg");
  background-repeat: no-repeat;
  background-position: center right 7px;
  padding-right: 35px;
}
.form-control[readonly] {
  background-color: white;
}
.disabled-check-box {
  pointer-events: none;
}
.v-select ul {
  max-height: 150px;
}
.modal-add-sensors-v1 .modal-body {
  height: auto !important;
}
.input-prefix-img {
  border: 1px solid var(--gray4);
  height: 37.96px;
  width: 40px;
  border-radius: 0px 7px 7px 0px;
  background: #d8d6de;
  text-align: center;
  svg {
    height: 32px;
    margin: auto;
    padding: 3px;
  }
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 5px;
    }
  }
}
.sensor-modal-scroll {
  .sensors-sc {
    height: calc(100vh - 420px) !important ;
    overflow: auto;
    padding: 0 15px;
  }
}

.parameters-label {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary);
}
@media screen and (max-width: 600px) {
  .visiable-sensor {
    margin-bottom: 10px;
  }
  .parameterssize {
    align-items: baseline;
  }
  .main123 {
    width: 40%;
  }
  .calculationTable {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.open-tel-parama + svg {
  path {
    fill: var(--primary);
  }
}

.calculationTable {
  margin-left: 10px;
  margin-top: -16px;
}
</style>
