<template>
  <div class="auth-wrapper auth-v2 share-location-map-main">
    <b-row class="auth-inner m-0">
      <b-col
        lg="12"
        style="max-height: 60px"
        class="d-flex align-items-start auth-bg px-2 p-1 justify-content-between"
      >
        <div>
          <b-link class="brand-logo-update">
            <DefaultLogo />
          </b-link>
        </div>
        <div class="d-flex DDRTE">
          <dark-Toggler class="d-none dark-toggle d-lg-block" />
          <locale />
        </div>
      </b-col>
      <!-- <b-col
        lg="2"
        style="max-height: 92px"
        class="d-flex share-location-header align-items-start"
      >
        <div style="margin-top: 30px">
          <div class="d-flex">
            <dark-Toggler class="d-none dark-toggle d-lg-block" />
            <locale />
          </div>
        </div>
      </b-col> -->
    </b-row>
    <b-row class="ml-0 mr-0">
      <b-col lg="12">
        <div
          v-if="$route.name === 'location-share-history'"
          class="shared-location-map"
        >
          <map1 ref="unitMap1" class="shared-location"></map1>
          <SharedLinkUnitDetail
            :unit="unit"
            :history="history"
            :account="account"
          ></SharedLinkUnitDetail>
        </div>
        <div
          v-else-if="$route.name === 'location-share-upcoming'"
          class="upcoming-img"
        >
          <UpcomingLive />
          <h3 class="title-w">
            {{ $t("sharedLocation.TrackUsLive") }} :
            {{ $t("sharedLocation.UpcomingLocationSharing") }}
          </h3>
          <h3 class="title-w">
            <span class="link">
              <b-link @click="getShardUrl($route.query.id, true)">
                $t("sharedLocation.Refresh")</b-link
              ></span
            >
          </h3>
        </div>

        <div
          v-else-if="$route.name === 'location-share-expire'"
          class="upcoming-img"
        >
          <LinkExpired />
          <h3 class="title-w">
            {{ $t("sharedLocation.ExpiredLocationSharing") }}
          </h3>
        </div>
        <div
          v-else-if="$route.name === 'location-share-invalid'"
          class="upcoming-img"
        >
          <InvalidURL />
          <h3 class="title-w">
            {{ $t("sharedLocation.locationShareInvalid") }}
          </h3>
        </div>
      </b-col>
    </b-row>
    <Loader :show="show" />
  </div>
</template>

<script>
/* eslint-disable global-require */

import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import map1 from "./map.vue";
import {
  BAvatar,
  BCard,
  VBTooltip,
  BModal,
  BButton,
  BLink,
  BCol,
  BRow,
  BCardText,
  BCardTitle,
  BNavbarNav
} from "bootstrap-vue";
import SocketIOService from "@/libs/socket/sockets";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UnitService from "@/libs/api/unit-service";
import SharedLinkUnitDetail from "./shared/SharedLinkUnitDetail.vue";
import UpcomingLive from "@/assets/images/unit-icon/menu/UpcomingLive.svg";
import LinkExpired from "@/assets/images/unit-icon/menu/LinkExpired.svg";
import InvalidURL from "@/assets/images/unit-icon/menu/InvalidURL.svg";
import Locale from "@/layouts/components/Locale.vue";
import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import moment from "moment";
import DarkToggler from "@/@core/layouts/components/app-navbar/components/DarkToggler.vue";
export default {
  components: {
    BAvatar,
    BCard,
    VBTooltip,
    BModal,
    BButton,
    BLink,
    BCol,
    BRow,
    SharedLinkUnitDetail,
    BCardText,
    BCardTitle,
    map1,
    DefaultLogo,
    Locale,
    DarkToggler,
    BNavbarNav,
    UpcomingLive,
    LinkExpired,
    InvalidURL,
    Loader
  },

  data() {
    return {
      coordinates: [],
      unit: {},
      history: {},
      account: {},
      show: false
    };
  },
  async mounted() {
    if (
      (this.$route.params.id &&
        this.$route.name === "location-share-history") ||
      (this.$route.query.id && this.$route.name === "location-share-upcoming")
    ) {
      this.getShardUrl(this.$route.params.id || this.$route.query.id);
    }
  },
  computed: {},
  methods: {
    decodePolyline(encoded) {
      const coordinates = [];
      let index = 0;
      let lat = 0;
      let lng = 0;

      while (index < encoded.length) {
        lat += decodeValue();
        lng += decodeValue();
        coordinates.push([lat * 1e-5, lng * 1e-5]);
      }

      return coordinates;

      function decodeValue() {
        let result = 0;
        let shift = 0;
        let b;
        do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);

        return result & 1 ? ~(result >> 1) : result >> 1;
      }
    },
    DateTimeConvert(date, e, account) {
      if (!date) {
        return "";
      }
      return this.formattedDateTime(
        date,
        constants.DATE_TIME_FORMAT,
        account.settings
      );
    },
    async getShardUrl(id, skipDuplicateCall) {
      try {
        let requestData = {
          id: id,
          page: 1,
          page_size: 10000
        };
        this.show = true;
        let response = await new UnitService().getPublicUnitHistory(
          requestData
        );

        if (response && response.data && response.data) {
          this.show = false;
          this.unit = response.data.unit;
          this.account = response.data.account;
          if (this.account && this.account.white_labeling) {
            localStorage.setItem(
              "ACCOUNT_WHITE_LABELING",
              JSON.stringify(this.account.white_labeling)
            );
          }
          const code = response.data.history ? response.data.history : {};
          const account = response.data.account ? response.data.account : {};
          const currentTime = this.getCurrentTime(null, null, account.settings);
          const endTime = this.formattedDateTime(
            code.end_time,
            constants.DATE_TIME_FORMAT,
            { ...account.settings, skipFormat: true }
          );
          this.history = {
            ...code,
            start_time: this.formattedDateTime(
              code.start_time,
              constants.DATE_TIME_FORMAT,
              account.settings
            ),
            end_time: this.formattedDateTime(
              code.end_time,
              constants.DATE_TIME_FORMAT,
              account.settings
            ),
            expiry: this.calculateDueStatus(currentTime, endTime),
            message_time: this.unit.position.message_time,
            message_time_tool:
              this.unit && this.unit.position && this.unit.position.message_time
                ? this.DateTimeConvert(
                    this.unit.position.message_time,
                    null,
                    account
                  )
                : null,
            last_sync_time:
              this.unit && this.unit.position && this.unit.position.message_time
                ? this.updateReportTime(
                    this.unit.position.message_time,
                    null,
                    account
                  )
                : null,
            address_link:
              this.unit && this.unit.position && this.unit.position.address_link
                ? this.unit.position.address_link
                : null,
            address:
              this.unit && this.unit.position && this.unit.position.address
                ? this.unit.position.address
                : null
          };
          if (this.unit.isUpcoming && !skipDuplicateCall) {
            if (
              this.$route.query.id &&
              this.$route.name === "location-share-upcoming"
            ) {
              const currentTime = this.getCurrentTime(
                null,
                null,
                account.settings
              );
              const startTime = this.formattedDateTime(
                code.start_time,
                constants.DATE_TIME_FORMAT,
                { ...account.settings, skipFormat: true }
              );

              const now = moment(currentTime);
              const start = moment(startTime);
              const diffMilliseconds = start.diff(now, "milliseconds");
              const fiveMinutesInMs = 5 * 60 * 1000;
              if (fiveMinutesInMs > diffMilliseconds) {
                setTimeout(() => {
                  this.getShardUrl(this.$route.query.id);
                }, diffMilliseconds);
              } else {
                return;
              }
            } else {
              const url =
                this.getCurrentPath() + "/app/location/upcoming?id=" + id;
              window.open(url, "_self");
            }
          } else if (this.unit.isExpired) {
            const url = this.getCurrentPath() + "/app/location/expired";
            window.open(url, "_self");
          } else {
            const me = this;
            this.unit.location_id = id;
            this.unit.account_id =
              this.account && this.account.account_id
                ? this.account.account_id
                : "";
            if (this.$route.name != "location-share-history") {
              const url = this.getCurrentPath() + `/app/share/location/${id}`;
              window.open(url, "_self");
            }
            if (
              !this.unit.isExpired &&
              !this.unit.isUpcoming &&
              code &&
              this.$route.name === "location-share-history"
            ) {
              let coordinates = [];
              if (code.poly_line) {
                coordinates = this.decodePolyline(code.poly_line);
              }
              if (coordinates && coordinates.length && coordinates.length > 5) {
                this.$refs.unitMap1.routeCoordinates = coordinates;
                setTimeout(() => {
                  this.$refs.unitMap1.startRouteMarker();
                  this.$refs.unitMap1.zoomToPolyline();
                }, 1000);
              } else {
                if (
                  this.unit &&
                  this.unit.position &&
                  this.unit.position.lat &&
                  this.unit.position.lng
                ) {
                  let coordinate = [
                    this.unit.position.lat,
                    this.unit.position.lng
                  ];
                  if (coordinates && coordinates.length) {
                    coordinate = coordinates[coordinates.length - 1];
                  }
                  const angle = this.unit.position.angle;
                  this.$refs.unitMap1.setIcon(coordinate, this.unit, angle);
                  // this.$refs.unitMap1.zoomToPolyline();
                }
              }

              setTimeout(() => {
                me.subscribeUnitData(me.unit, account, code);
              }, 1000);
            }
          }
        } else if (response && response.error && response.error.message) {
          this.showLoading = 1;
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          const url = this.getCurrentPath() + "/app/location/invalid";
          window.open(url, "_self");
        }
      } catch (err) {
        console.log(err);
        this.show = false;
        this.showLoading = 1;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    async subscribeUnitData(unit, account, code) {
      if (!this.socket) {
        this.socket = new SocketIOService();
        const socketUrl = `${process.env.VUE_APP_SOCKET_URL}?id=${unit.location_id}&account=${unit.account_id}&is_skip_user=true`;
        await this.socket.setupSocketConnection(socketUrl);
      }

      if (!unit.subscribed) {
        unit.subscribed = true;
        let totalDistance = 0;
        this.socket.subscribeEventWithFunction(`SLL_${unit.id}`, (data) => {
          const currentTime = this.getCurrentTime(null, null, account.settings);
          const endTime = this.formattedDateTime(
            code.end_time,
            constants.DATE_TIME_FORMAT,
            { ...account.settings, skipFormat: true }
          );

          const now = moment(currentTime);
          const end = moment(endTime);

          if (now.isAfter(end)) {
            this.socket.disconnect();
            return;
          }
          this.history.expiry = this.calculateDueStatus(currentTime, endTime);
          this.history.message_time = data.message_time;
          this.history.last_sync_time =
            data && data.message_time
              ? this.updateReportTime(data.message_time, null, account)
              : null;
          this.history.address_link = data.position.address_link;
          this.history.address = data.position.address;
          this.history.message_time_tool =
            data && data.message_time
              ? this.DateTimeConvert(data.message_time, null, account)
              : null;
          this.$refs.unitMap1.routeCoordinates.push([
            data.position.latitude,
            data.position.longitude
          ]);
          this.$refs.unitMap1.startRouteMarker(
            unit,
            data && data.position && data.position.angle
              ? data.position.angle
              : null,
            totalDistance
          );
          // this.$refs.unitMap1.zoomToPolyline();
          this.$refs.unitMap1.mapCenter([
            data.position.latitude,
            data.position.longitude
          ]);
          totalDistance = totalDistance + 1;
        });
      } else if (unit.subscribed) {
        unit.subscribed = false;
        this.socket.unsubscribeEventWithFunction(`SLL_${unit.id}`);
      }
    }
  },
  destroyed() {
    this.socket.unsubscribeEventWithFunction(`SLL_${this.unit.id}`);
  }
};
</script>
<style scoped lang="scss">
.unit-info-card {
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cust-card-unit {
  display: flex;
  align-items: center;
}

.unit-name-ev-block {
  display: flex;
  flex-direction: column;
}

.unit-name-ev-text {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.allow-text {
  font-size: 14px;
  color: #777;
}

.trip-sm-card {
  background-color: #f7f7f7;
  border-radius: 5px;

  margin-bottom: 10px;
}

.event-card-fe {
  display: flex;
  align-items: center;
}

.ev-d-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.event-info {
  font-size: 14px;
  color: #555;
}

.date-time {
  font-size: 14px;
}
.share-location-header {
  z-index: 1000 !important;
}
.upcoming-img {
  margin-top: 14vh;
  display: flex;
  align-content: center;
  // justify-content: center;
  align-items: center;
  min-height: calc(100vh - 92px);
  max-height: calc(100vh - 92px);
  width: 100%;
  flex-direction: column;
}
.upcoming-img svg {
  max-width: 250px;
  margin-bottom: 20px;
}
.title-w {
  text-align: center;
  font-size: 15px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.00875rem;
}
@media only screen and (min-width: 1024px) {
  .upcoming-img svg {
    max-width: 40vw;
    margin-bottom: 20px;
  }
  .title-w {
    text-align: center;
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.00875rem;
  }
}
</style>
<style lang="scss">
.DDRTE {
  .dropdown-menu-right {
    z-index: 9999 !important;
  }
  .dropdown-toggle {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="scss">
.shared-location {
  .vue2leaflet-map {
    height: calc(100vh - 73px) !important;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .shared-location {
    .vue2leaflet-map {
      max-height: calc(100vh - 186px) !important;
      width: 100%;
    }
  }
}
</style>
