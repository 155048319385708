var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ml-1 mb-1"},[(_vm.totalUnits)?_c('b-form-checkbox',{staticStyle:{"min-width":"10px"},attrs:{"title":_vm.$t('Unit.SelectAll')},on:{"change":_vm.clearAll},model:{value:(_vm.isClearAll),callback:function ($$v) {_vm.isClearAll=$$v},expression:"isClearAll"}}):_vm._e()],1),_c('div',{ref:"scrollContainer",staticClass:"unit-list-scroll"},[(_vm.showLoading)?_c('div',{staticClass:"table-responsive"},[_c('b-skeleton-table',{attrs:{"rows":6,"columns":2,"table-props":{ bordered: true, striped: true }}})],1):_c('div',[_c('virtual-list',{staticStyle:{"overflow-y":"auto"},style:(!_vm.showLoading &&
          _vm.totalUnits === 0 &&
          (!_vm.filter || !_vm.filter.value) &&
          _vm.checkAbility({
            action: _vm.constants.PERMISSIONS_ACTION.ADD,
            subject: _vm.constants.PERMISSIONS_MODEL.UNITS
          })
            ? ''
            : { height: 'calc(100vh - 410px)' }),attrs:{"data-key":'id',"data-sources":_vm.unitsData,"estimate-size":10,"dataComponent":{}},on:{"tobottom":_vm.handleScroll},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var unit = ref.item;
return [_c('div',{staticClass:"align-items-center b-card unit-ls-data",class:_vm.selectedCheckbox.includes(unit.id) ? 'border-primary' : ''},[_c('b-media',{staticClass:"media-card d-flex",attrs:{"no-body":""}},[_c('b-form-checkbox',{staticClass:"form-control-merge cursor-pointer",attrs:{"value":unit.id},on:{"change":function($event){return _vm.onCheckClick(unit, $event, _vm.unitsData, false)}},model:{value:(_vm.selectedCheckbox),callback:function ($$v) {_vm.selectedCheckbox=$$v},expression:"selectedCheckbox"}}),_c('b-media-aside',{staticClass:"mr-75 marginLeftImg unit-url"},[_c('b-avatar',{attrs:{"square":"","size":"45","src":unit.icon}})],1),_c('b-media-body',{staticClass:"my-auto mb-body cursor-pointer",on:{"click":function($event){return _vm.clickOnUnitName(unit, true)}}},[_c('h6',{staticClass:"mb-0 marginLeftImg text-col-unit"},[_vm._v(" "+_vm._s(unit.name)+" ")]),_c('div',{staticClass:"unit-account-name"},[_vm._v(" "+_vm._s(unit.operator.name)+" ")])]),(unit && unit.battery && unit.battery.icon)?_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html.top",modifiers:{"html":true,"top":true}}],staticClass:"battery-img-icon curserPointer",attrs:{"src":unit.battery.icon,"title":("<b class='no-wp-txt'>" + (_vm.$t(
                  'unit.batteryLevel'
                )) + ":</b><span class='no-wp-txt'>  " + (unit.battery.level) + "  </span>")}}):_vm._e(),_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html.top",modifiers:{"html":true,"top":true}}],staticClass:"signal-img-icon curserPointer",attrs:{"src":unit.connection.icon,"title":("<b class='no-wp-txt'>" + (_vm.$t(
                  'UnitTrack.ReportedAt'
                )) + ":</b><span class='no-wp-txt'>  " + (_vm.getFormattedTime(
                  unit.message_time
                )) + "  </span>")}}),_c('UnitActionMenu',{attrs:{"unit":unit,"deleteUnit":_vm.deleteUnit,"sharedLocation":_vm.sharedLocation,"allocationUnit":_vm.allocationUnit,"editUnit":unit.is_unit_editable}})],1),(unit.archived == true)?_c('div',{staticClass:"d-flex justify-content-end unit-info-icon"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html.top",modifiers:{"html":true,"top":true}}],staticClass:"mb-0 curserPointer",attrs:{"title":("\n      <b class='no-wp-txt'>" + (_vm.$t('unit.UnitAllocationMessage')) + "</b>\n       ")}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"InfoIcon","size":"15"}})],1)]):_vm._e()],1)]}}])}),(
          !_vm.showLoading &&
          _vm.totalUnits === 0 &&
          (!_vm.filter || !_vm.filter.value) &&
          _vm.checkAbility({
            action: _vm.constants.PERMISSIONS_ACTION.ADD,
            subject: _vm.constants.PERMISSIONS_MODEL.UNITS
          })
        )?_c('div',[_c('div',[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("unit.EmptyUnitCreateMessage"))+" ")])]),_c('div',{staticStyle:{"text-align":"center"}},[(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.ADD,
                subject: _vm.constants.PERMISSIONS_MODEL.UNITS
              })
            )?_c('b-button',{attrs:{"variant":"primary","to":{
              name: 'unit-creation',
              query: { allocate_to: _vm.selectedAccount }
            }}},[_c('feather-icon',{staticClass:"add-icon-s",attrs:{"icon":"PlusIcon","size":"12"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("device.CreateUnit")))])],1):_vm._e()],1)]):_vm._e()],1),_c('AreYouSureModal',{attrs:{"data":_vm.deleteData,"onClose":_vm.onClose,"removedUser":_vm.removedUser}}),_c('SharedLocations',{attrs:{"locationUnit":_vm.locationUnit,"onClose":_vm.onClose,"removedUser":_vm.removedUser}}),(_vm.isAllocationEnable)?_c('AllocationPopUp',{attrs:{"allocationData":_vm.allocationData,"isDevice":false,"isSelectedTab":_vm.isSelectedTab,"onClose":_vm.onClose,"changeInput":_vm.changeInput,"currentUnit":_vm.currentUnit,"refreshed":_vm.refreshed,"getAccountDetail":_vm.getAccountDetail}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }