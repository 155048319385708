<template>
  <b-modal
    id="modal-sm-add-shared-location"
    centered
    @hide="close"
    modal-class="no-header-modal"
    :title="$t('sharedLocation.ShareLiveLocation')"
  >
    <div>
      <p v-if="!generatedUrl" class="select-time-label pl-2">
        {{ $t("sharedLocation.SelectTime") }}
      </p>
      <b-form-radio-group
        v-model="selected"
        :options="options"
        class="pl-2 pb-1"
        name="share-live-location"
        stacked
        v-if="!generatedUrl"
      ></b-form-radio-group>
      <div v-if="generatedUrl" class="gif-img">
        <img :src="urlComplete" alt="Complete Icon" />
      </div>
      <div
        v-if="selected === 'CUSTOM' && !generatedUrl"
        class="custom-time-picker pl-2 pr-2 mt-0"
      >
        <b-form-group :label="$t('sharedLocation.selectDateTime')">
          <date-range-picker
            :minDate="getCurrentTime()"
            v-model="dateRange"
            :single-date-picker="false"
            :timePicker="true"
            :ranges="false"
            :time-picker-increment="1"
          ></date-range-picker>
        </b-form-group>

        <p v-if="errorMessage && !generatedUrl" class="text-danger">
          {{ errorMessage }}
        </p>
      </div>
      <div
        class="d-flex align-items-center justify-content-center mb-2 mt-1"
        v-if="!generatedUrl"
      >
        <b-button
          @click="save"
          variant="primary"
          style="max-width: 300px"
          :disabled="selected == 'CUSTOM' && errorMessage != ''"
          class="w-100"
          >{{ $t("sharedLocation.GenerateLink") }}</b-button
        >
      </div>

      <div v-if="generatedUrl" style="margin: 5px" class="URL-location-w">
        <b-link class="link-w" @click="redirectLink(generatedUrl)">{{
          generatedUrl
        }}</b-link>
        <span :id="'textToCopy'" class="invitation-link-hidden">
          {{ generatedUrl }}
        </span>
        <b-button variant="primary" class="mt-1" @click="copyText('textToCopy')"
          >Copy Link</b-button
        >
      </div>
      <!-- <b-button @click="close" variant="secondary">{{ $t("Close") }}</b-button> -->
    </div>
    <Loader :show="show" />
  </b-modal>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BModal,
  BFormRadioGroup,
  BLink
} from "bootstrap-vue";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UnitService from "@/libs/api/unit-service";
import Loader from "@/layouts/components/Loader.vue";
import URLComplete from "@/assets/images/unit-icon/menu/complate.gif";
export default {
  components: {
    BButton,
    BFormGroup,
    BModal,
    URLComplete,
    BFormRadioGroup,
    DateRangePicker,
    Loader,
    BLink
  },
  data() {
    return {
      generatedUrl: null,
      urlComplete: URLComplete,
      dateRange: {
        startDate: this.getCurrentTime(),
        endDate: moment(this.getCurrentTime()).add(1, "hours").toISOString()
      },
      generatedUrl: "",
      sharedData: {},
      show: false,
      selected: "1H",
      errorMessage: "",
      options: [
        { text: "15 Minutes", value: "15M" },
        { text: "1 Hour", value: "1H" },
        { text: "8 Hours", value: "8H" },
        { text: "Custom Time", value: "CUSTOM" }
      ]
    };
  },
  props: ["unit"],
  watch: {
    dateRange: "validateDateRange"
    // endTime: "validateDateRange"
  },
  methods: {
    redirectLink(redirectLink) {
      window.open(redirectLink, "_blank");
    },
    copyText(elementId) {
      const textElement = document.getElementById(elementId);
      const range = document.createRange();
      range.selectNode(textElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand("copy");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("sharedLocation.LinkCopy"),
            text: "",
            icon: "InfoIcon",
            variant: "success"
          }
        });
      } catch (error) {
        console.error("Unable to copy text: ", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("sharedLocation.LinkCopyFailed"),
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }

      // Clear the selection
      window.getSelection().removeAllRanges();
    },
    getCurrentTime() {
      return moment().toISOString();
    },

    validateDateRange() {
      const start = moment(this.dateRange.startDate);
      const end = moment(this.dateRange.endDate);
      const now = moment();

      if (start.isBefore(now.subtract(1, "minute")) || end.isBefore(now)) {
        this.errorMessage = "Both Start and End times must be in the future.";
        return;
      }

      if (!end.isAfter(start)) {
        this.errorMessage = "End time must be greater than Start time.";
        return;
      }

      if (end.diff(start, "hours") > 24) {
        this.errorMessage = "Time range cannot exceed 24 hours.";
        return;
      }

      this.errorMessage = ""; // Clear error if validation passes
    },
    save() {
      if (this.selected == "15M") {
        this.sharedData = {
          start_time: this.getCurrentTime(),
          end_time: moment(this.getCurrentTime())
            .add(15, "minutes")
            .toISOString()
        };
      } else if (this.selected == "1H") {
        this.sharedData = {
          start_time: this.getCurrentTime(),
          end_time: moment(this.getCurrentTime())
            .add(60, "minutes")
            .toISOString()
        };
      } else if (this.selected == "8H") {
        this.sharedData = {
          start_time: this.getCurrentTime(),
          end_time: moment(this.getCurrentTime()).add(8, "hours").toISOString()
        };
      } else if (this.selected == "CUSTOM") {
        this.validateDateRange();
        if (!this.errorMessage) {
          this.sharedData = {
            start_time: this.convertDatePerTimezone(this.dateRange.startDate),
            end_time: this.convertDatePerTimezone(this.dateRange.endDate)
          };
        } else {
          return;
        }
      }

      this.sharedData.unit_id = this.unit.id;
      this.getShardUrl();
    },
    async getShardUrl() {
      try {
        //   this.sharedData.start_time = "2025-01-13T10:52:09.027Z";
        this.show = true;
        let response = await new UnitService().generateLink(this.sharedData);

        if (response && response.data && response.data) {
          this.show = false;
          this.generatedUrl = response.data.generated_url;
          // this.$emit("refreshed", "");
          // this.show = false;
          // this.close();
        } else if (response && response.error && response.error.message) {
          this.showLoading = 1;
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.showLoading = 1;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    close() {
      this.dateRange = {
        startDate: this.getCurrentTime(),
        endDate: moment(this.getCurrentTime()).add(1, "hours").toISOString()
      };
      this.generatedUrl = "";
      this.sharedData = {};
      this.selected = "1H";
      this.$bvModal.hide("modal-sm-add-shared-location");
      this.$emit("refreshed", "");
    }
  }
};
</script>

<style scoped>
.custom-time-picker {
  margin-top: 1rem;
}

.text-danger {
  color: red;
  font-weight: bold;
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.date-range-picker {
  .reportrange-text {
    border: 1px solid #d8d6de !important;
  }
}
</style>
<style>
.custom-radio {
  padding-bottom: 15px !important;
}
h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.select-time-label {
  font-size: 15px;
  color: #6c757d;
  margin-bottom: 8px;
  font-weight: 600;
  margin-bottom: 14px;
}

.b-form-radio-group {
  margin-top: 5px;
}

.b-form-radio {
  margin-bottom: 8px;
}
</style>
<style lang="scss">
.new-date-picker-25 {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    left: 0 !important;
    transform: translate(0%) !important;
    flex-wrap: nowrap !important;
  }
  .calendars {
    max-height: 60vh;

    flex-wrap: nowrap !important;
  }
  .calendars-container {
    // flex-wrap: nowrap !important;
  }
}
.invitation-link-hidden {
  display: block;
  max-width: 1px;
  position: absolute;
  height: 1px;
  opacity: 0;
  z-index: -9;
  right: 0px;
  overflow: hidden;
}
.modal-title {
  font-size: 21px !important;
  width: 100%;
  display: flex;
  justify-content: center;
}
.URL-location-w {
  word-wrap: break-word;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gif-img {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 120px;
  }
}
.link-w {
  width: 100% !important;
}
#modal-sm-add-shared-location {
  .close {
    display: contents !important;
  }
  .modal-body {
    max-height: calc(100vh - 120px) !important;
  }
}
</style>
