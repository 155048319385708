<template>
  <span>
    <b-img
      class="brand-logo-v1"
      :src="image_url"
      alt="logo"
      v-if="!logos || !logos.length"
    />
    <b-img
      class="brand-logo-v1 brand-logo-v2"
      :class="logo.key"
      :src="logo.url"
      alt="logo"
      v-for="logo in logos"
      :key="logo.key"
    />
  </span>
</template>

<script>
import { BImg } from "bootstrap-vue";

// Note: Vue automatically prefixes the directive name with 'v-'
import store from "@/store";

export default {
  name: "search",
  components: { BImg },
  created() {
    this.setAccountLogo();
    //  setInterval(this.setAccountLogo, 300);
  },

  mounted() {
    //  this.setAccountLogo();
  },
  data() {
    return {
      image_url: this.getCurrentPath() + "/app/images/logo-svg.svg",

      logos: []
    };
  },
  methods: {
    setAccountLogo() {
      if (this.$route.name === "location-share-history") {
        let whiteLabeling = localStorage.getItem("ACCOUNT_WHITE_LABELING");
        whiteLabeling = whiteLabeling ? JSON.parse(whiteLabeling) : null;
        if (
          whiteLabeling &&
          whiteLabeling.logos &&
          whiteLabeling.logos.length
        ) {
          this.logos = whiteLabeling.logos; //
        } else if (whiteLabeling && whiteLabeling.logo) {
          this.image_url = whiteLabeling.logo;
        } else {
          this.image_url = this.getCurrentPath() + "/app/images/logo-svg.svg";
        }
        if (whiteLabeling && whiteLabeling.lang) {
          // this.$i18n.locale = whiteLabeling.lang;
          // console.log(6);
          // localStorage.setItem("lang", this.$i18n.locale);
        }
        if (whiteLabeling && whiteLabeling.theme) {
          // localStorage.setItem("savedClass", whiteLabeling.theme);
        }
      }
      if (this.$route.name === "new-user-onboarding") {
        let whiteLabeling = localStorage.getItem("USER_ACCOUNT_WHITE_LABELING");
        whiteLabeling = whiteLabeling ? JSON.parse(whiteLabeling) : null;
        if (
          whiteLabeling &&
          whiteLabeling.logos &&
          whiteLabeling.logos.length
        ) {
          this.logos = whiteLabeling.logos; //
        } else if (whiteLabeling && whiteLabeling.logo) {
          this.image_url = whiteLabeling.logo;
        } else {
          this.image_url = this.getCurrentPath() + "/app/images/logo-svg.svg";
        }
        if (whiteLabeling && whiteLabeling.lang) {
          this.$i18n.locale = whiteLabeling.lang;
          localStorage.setItem("lang", this.$i18n.locale);
          console.log(7);
        }
        if (whiteLabeling && whiteLabeling.theme) {
          localStorage.setItem("savedClass", whiteLabeling.theme);
        }
      } else {
        let account = localStorage.getItem("USER_ACCOUNT_DETAILS");
        account = account ? JSON.parse(account) : null;
        if (
          account &&
          account.white_labeling &&
          account.white_labeling.logos &&
          account.white_labeling.logos.length
        ) {
          this.logos = account.white_labeling.logos; //
        } else {
          if (this.$store.state.verticalMenu.isVerticalMenuCollapsed) {
            this.image_url = this.getCurrentPath() + "/app/images/logo.png";
          } else {
            this.image_url = this.getCurrentPath() + "/app/images/logo-svg.svg";
          }
        }
      }
    }
  },
  mounted() {},
  watch: {
    "$store.state.device.icons"(val) {
      // console.log(this.$store.state.device.icons);
      if (
        this.$store.state.device.icons &&
        this.$store.state.device.icons.length
      ) {
        this.logos = this.$store.state.device.icons;
      } else {
        this.logos = [];
        this.setAccountLogo();
      }
    },
    "$store.state.verticalMenu.isVerticalMenuCollapsed"(val) {
      if (!this.logos || !this.logos.length) {
        if (!val) {
          this.image_url = this.getCurrentPath() + "/app/images/logo-svg.svg";
        } else {
          this.image_url = this.getCurrentPath() + "/app/images/logo.png";
        }
      }
    },
    "$store.state.verticalMenu.isVerticalMenuMouseHover"(val) {
      if (
        (!this.logos || !this.logos.length) &&
        this.$store.state.verticalMenu.isVerticalMenuCollapsed
      ) {
        if (val) {
          this.image_url = this.getCurrentPath() + "/app/images/logo-svg.svg";
        } else {
          this.image_url = this.getCurrentPath() + "/app/images/logo.png";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.brand-logo-v1 {
  max-width: 135px;
  max-height: 35px;
  img {
    width: 100%;
  }
}
.brand-logo-v2 {
  display: none;
  &.light_expanded {
    display: block;
  }
}
.menu-collapsed {
  .brand-logo-v2 {
    &.light_expanded {
      display: none;
    }
    &.light_collapsed {
      display: block;
    }
  }
}
.dark-layout {
  .brand-logo-v2 {
    &.light_expanded {
      display: none;
    }
    &.dark_expanded {
      display: block;
    }
  }
  .menu-collapsed {
    .brand-logo-v2 {
      &.light_expanded {
        display: none;
      }
      &.dark_expanded {
        display: none;
      }
      &.light_collapsed {
        display: none;
      }
      &.dark_collapsed {
        display: block;
      }
    }
  }
}
.logo-layout {
  .brand-logo-v3 {
    display: none;
  }
}
.menu-collapsed .menu-accordion {
  &:hover {
    .light_collapsed {
      display: none !important;
    }
    .light_expanded {
      display: block !important;
    }
  }
}
.dark-layout .menu-collapsed .menu-accordion {
  &:hover {
    .light_collapsed {
      display: none !important;
    }
    .light_expanded {
      display: none !important;
    }
    .dark_collapsed {
      display: none !important;
    }
    .dark_expanded {
      display: block !important;
    }
  }
}
</style>
