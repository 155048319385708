<template>
  <div>
    <div class="maintenance-page">
      <div class="d-flex mb-1 align-items-center justify-content-between">
        <h4 class="card-title mb-0">
          {{ $t("Maintenance.Reminders") }}
        </h4>
        <div class="filter-btn-outer-new d-flex">
          <b-button
            v-b-tooltip.hover.v-primary
            :title="$t('Maintenance.AddReminder')"
            variant="primary"
            class="mr-1 desktop-hidden"
            :to="{ name: 'maintenance-reminder-create' }"
            v-if="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
              }) && !this.groupName
            "
          >
            <feather-icon icon="PlusIcon" size="12" />
          </b-button>
          <b-button
            variant="outline-primary"
            size="sm"
            v-if="!this.groupName"
            class="mr-1 desktop-hidden setting_icon_padding"
            v-b-tooltip.hover.top="$t('Maintenance.NotificationTooltip')"
            v-b-modal.modal-notification
          >
            <feather-icon icon="SettingsIcon" size="20" />
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="desktop-hidden"
            @click="showFiltermenu = !showFiltermenu"
          >
            <feather-icon icon="FilterIcon" size="15" />
          </b-button>
        </div>

        <div
          class="d-flex flex-wrap filter-card search-display-1"
          v-if="showFiltermenu"
        >
          <div class="mr-2 maintenace-per-page">
            <label class="mt-1 search-label">{{
              $t("Maintenance.Entries")
            }}</label>
            <v-select
              :clearable="false"
              id="vue-select"
              class="per-page-selector d-inline-block fix-83"
              v-model="perPage"
              :options="option"
            />
          </div>
          <div class="notification-per-page">
            <div class="addXIcon">
              <feather-icon
                class="sizeIcon"
                @click="showFiltermenu = !showFiltermenu"
                icon="XIcon"
                size="15"
              />
            </div>
          </div>

          <search
            class="mobile-margin-top no-btm-0"
            style="float: left"
            v-model="searchFiled"
            :searchColumn="searchColumn"
            :operator="operator"
          ></search>
          <b-button
            v-if="!this.groupName"
            variant="outline-primary"
            size="sm"
            class="mr-1 mobile-hidden setting_icon_padding ml-1"
            v-b-tooltip.hover.top="$t('Maintenance.NotificationTooltip')"
            v-b-modal.modal-notification
          >
            <feather-icon icon="SettingsIcon" size="20" />
          </b-button>

          <b-button
            v-b-tooltip.hover.v-primary
            :title="$t('Maintenance.AddReminder')"
            variant="primary"
            class="mobile-hidden"
            :class="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.UPDATE,
                subject: constants.PERMISSIONS_MODEL.ACCOUNT
              })
                ? ''
                : ' ml-1'
            "
            :to="{ name: 'maintenance-reminder-create' }"
            v-if="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
              }) && !this.groupName
            "
          >
            <feather-icon icon="PlusIcon" size="12" />
            <span class="mobile-hidden">{{
              $t("Maintenance.AddReminder")
            }}</span>
          </b-button>
        </div>
      </div>
      <DefaultNotification />
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="7"
          :table-props="{ bordered: true, striped: true }"
          class="device-list2"
        />
      </div>
      <b-table
        class="position-relative maintenance-table dre-table"
        responsive
        show-empty
        v-if="!show"
        sticky-header
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
        :hover="$route.name === 'notification-create'"
        :sort-desc.sync="sortDesc"
        :sort-by.sync="sortBy"
      >
        <template #cell(unit_name)="row">
          <b-media class="align-item-center unit-list-map-icon-parent-1 pt-0">
            <template #aside>
              <span class="unit-list-map-icon-parent">
                <b-avatar if square size="40" :src="row.item.unit_icon" />
              </span>
            </template>
            <h6 :class="!row.item.archived ? 'mb-0' : ''">
              {{ `${row.item.unit_name}` }}
            </h6>
            <!-- <template #title>
              <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
              <span class="d-none d-sm-inline">{{ $t("user.User") }}</span>
            </template> -->
          </b-media>
        </template>
        <template #cell(name)="row">{{ row.item.name }} </template>
        <template #cell(services)="row">
          <ul class="p-0 mt-0 mb-0">
            <li
              class="font-500 services"
              v-for="service in row.item.services"
              :key="service.value"
            >
              {{ service.title }}
            </li>
          </ul>
        </template>
        <template #cell(config)="row">
          <div class="tree-parent-outer">
            <div
              v-for="config in row.item.configuration"
              :key="config.id"
              :class="
                config && row.item.configuration.length > 1
                  ? 'tree-parent-item'
                  : 'tree-parent-item hide-tree-parent-item'
              "
            >
              <span
                class="or-divider"
                v-if="config && row.item.configuration.length > 1"
                >or</span
              >
              <div class="mr-30 white-space" style="min-width: 50%">
                {{ config.value }}
              </div>
              <div class="white-space">{{ config.due }}</div>
            </div>
          </div>
        </template>

        <template #cell(labels)="row">
          <div class="parent" id="parent">
            <DynamicBadge
              :isList="true"
              :entityType="'MAINTAINANCE'"
              :tagsData="row.item.labels"
              :showTextInBadge="Number(7)"
              :event="{ ...row.item, id: row.item.service_id }"
              :maxTagShow="Number(defaultBadgeShow)"
              :listApi="debounceGetAllReminders"
              class="try"
            ></DynamicBadge>
            <b-badge
              class="badge-status-maintenance"
              :variant="resolveFilterBadgeColor(row.item.status)"
            >
            </b-badge>
          </div>
        </template>
        <template #cell(status)="row">
          <b-badge
            v-if="row.item.status"
            class="badge-status-maintenance"
            pill
            variant="primary"
            >{{ row.item.status }}
          </b-badge>
        </template>

        <template #cell(ACTIONS)="row">
          <div class="text-right min-80">
            <span>
              <b-link
                :to="{
                  name: 'maintenance-reminder-view',
                  params: {
                    id: row.item.service_id
                  }
                }"
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.view')"
                  icon="EyeIcon"
                  class="text-primary action-icon cursor-pointe"
                  size="18"
                />
              </b-link>
            </span>
            <span v-if="row.item.notification_id">
              <b-link
                :to="{
                  name: 'notification-edit',
                  params: { id: row.item.notification_id }
                }"
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('toolTip.Notification')"
                  icon="BellIcon"
                  class="text-primary action-icon cursor-pointe"
                  size="18"
                />
              </b-link>
            </span>
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
                }) && !['COMPLETED'].includes(row.item.status)
              "
            >
              <b-link
                :to="{
                  name: 'maintenance-reminder-add-bill',
                  params: { id: row.item.service_id }
                }"
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.costing')"
                  icon="CheckCircleIcon"
                  class="text-primary action-icon cursor-pointe"
                  size="18"
                />
              </b-link>
            </span>
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
                })
              "
            >
              <b-link
                :to="{
                  name: 'Maintenance-History',
                  params: { id: row.item.service_id }
                }"
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.History')"
                  icon="FileTextIcon"
                  class="text-primary action-icon cursor-pointer"
                  size="18"
                />
              </b-link>
            </span>
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
                })
              "
            >
              <b-link
                :to="{
                  name: 'maintenance-reminder-edit',
                  params: {
                    id: row.item.service_id
                  }
                }"
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.update')"
                  icon="EditIcon"
                  class="text-primary action-icon cursor-pointer"
                  size="18"
                />
              </b-link>
            </span>

            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.DELETE,
                  subject: constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
                })
              "
            >
              <feather-icon
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                icon="Trash2Icon"
                class="text-danger action-icon"
                size="18"
                @click="deleteMaintenance(row.item)"
              ></feather-icon>
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalMaintenance > 0"
              v-model="currentPage"
              :total-rows="totalMaintenance"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <AreYouSureModal
      :data="deleteData"
      :status="status"
      :onClose="onClose"
      :removedUser="removeMaintenance"
    />
  </div>
</template>

<script>
import {
  BCard,
  BAlert,
  BButton,
  BRow,
  BCol,
  BImg,
  BTable,
  BProgress,
  BPagination,
  BFormGroup,
  VBTooltip,
  BBadge,
  BLink,
  BMedia,
  BAvatar,
  BSkeletonTable
} from "bootstrap-vue";
import MaintenanceService from "@/libs/api/maintenance-service";
import * as moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Loader from "@/layouts/components/Loader.vue";

import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import Search from "../Common/search.vue";
import DefaultNotification from "../Maintenance/DefaultNotificationPopup.vue";
import vSelect from "vue-select";
import constants from "@/utils/constants";
export default {
  components: {
    BCard,
    BAlert,
    BButton,
    BRow,
    BCol,
    BImg,
    BTable,
    BLink,
    BSkeletonTable,
    BProgress,
    BPagination,
    BFormGroup,
    VBTooltip,
    Search,
    vSelect,
    BBadge,
    Loader,
    DynamicBadge,
    AreYouSureModal,
    BMedia,
    BAvatar,
    DefaultNotification
  },
  props: ["selectedStatus", "groupName"],
  data() {
    return {
      showFiltermenu: false,
      show: false,
      activeClass: "active",
      statusBarValue: 0,
      socketFile: null,
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      status: false,
      items: [],
      totalMaintenance: 0,
      option: ["10", "20", "30"],
      searchColumn: ["name", "unit"],
      sortBy: null,
      sortDesc: true,
      searchFiled: {},
      operator: "ilike",

      deleteData: null,

      defaultBadgeShow: 2,
      tableColumns: [
        {
          key: "unit_name",
          tdClass: "",
          thClass: " min-w-th",
          label: this.$t("Maintenance.tableColumns.UnitName")
        },
        {
          key: "name",
          label: this.$t("Maintenance.tableColumns.serviceName"),
          thClass: " min-w-th"
        },
        {
          key: "services",
          tdClass: "badge-200",
          thClass: "badge-200 min-w-th",
          label: this.$t("Maintenance.tableColumns.ServiceTypes")
        },
        {
          key: "config",
          tdClass: "badge-200",
          thClass: "badge-200 text-center min-w-th",
          label: this.$t("Maintenance.tableColumns.dueDate"),
          sortable: true
        },
        {
          key: "labels",
          tdClass: " label-w-a min-180",
          thClass: " label-w-a min-180 min-w-th",
          label: this.$t("Maintenance.tableColumns.labels")
        },
        {
          key: "status",
          tdClass: "text-center ",
          thClass: "text-center  min-w-th",
          label: this.$t("Maintenance.tableColumns.status")
        },
        {
          key: "ACTIONS",
          thClass: "action-width-maintenance-th text-end min-w-th",
          tdClass: "action-width-maintenance",
          label: this.$t("Maintenance.tableColumns.actions")
        }
      ],
      order: true,
      field: "config",
      timeoutId: null
    };
  },
  setup() {
    const resolveFilterBadgeColor = (filter) => {
      filter = filter && filter.toUpperCase();
      const status = {
        ACTIVE: "light-success",
        INACTIVE: "light-warning",
        DAMAGED: "light-danger",
        BLOCKED: "light-secondary"
      };

      const selectedStatus =
        filter && status[filter] ? status[filter] : status["ACTIVE"];

      return selectedStatus;
    };
    return {
      resolveFilterBadgeColor
    };
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetAllReminders();
    },
    currentPage(newPage) {
      this.debounceGetAllReminders();
    },
    searchFiled(newVal, oldVal) {
      this.filter = newVal;
      this.debounceGetAllReminders();
    },
    selectedStatus(val) {
      this.debounceGetAllReminders();
    },

    sortDesc(newVal) {
      this.order = this.sortDesc;
      this.field = this.sortBy;
      this.debounceGetAllReminders();
    }
  },
  directives: {
    "b-tooltip": VBTooltip
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("resize", this.showLabelSize);
  },
  computed: {},
  methods: {
    debounceGetAllReminders() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllReminders();
      }, 300); // Adjust the delay as necessary
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 991) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    showLabelSize() {
      setTimeout(() => {
        const parentElement = document.querySelector(".parent");
        if (parentElement) {
          const parentWidth = parentElement.offsetWidth;
          this.defaultBadgeShow = Math.floor(parentWidth / 71);
        }
      }, 200);
    },
    onClose() {
      this.debounceGetAllReminders();
      this.$bvModal.hide("modal-sm-remove");
    },
    async removeMaintenance(props) {
      try {
        this.show = true;
        this.onClose();
        let response = await new MaintenanceService().deleteReminder({
          id: props.id
        });
        this.show = false;
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Maintenance.DeletedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.debounceGetAllReminders();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },

    deleteMaintenance(props) {
      this.$bvModal.show("modal-sm-remove");
      this.deleteData = {
        name: props.maintenance_name || props.service_name || props.name,
        id: props.service_id
      };
    },

    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },

    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllReminders();
    },
    async getAllReminders() {
      try {
        this.show = true;
        const me = this;
        const requestData = {
          page: parseInt(this.currentPage),
          page_size: parseInt(this.perPage),
          sort_order: this.sortDesc ? "desc" : "asc"
        };
        if (this.groupName) {
          requestData.label = this.groupName;
        }

        requestData.filters =
          this.filter && Array.isArray(this.filter)
            ? this.filter
            : this.filter && this.filter.value
            ? [this.filter]
            : [];
        if (this.selectedStatus !== "ALL" && !this.groupName) {
          requestData.filters.push({
            field: "status",
            value: [this.selectedStatus],
            operator: "IN"
          });
        }
        if (this.$route.query && this.$route.query.unit) {
          requestData.filters.push({
            field: "unit_id",
            value: [this.$route.query.unit],
            operator: "IN"
          });
        }
        if (this.groupName) {
          requestData.label = this.groupName;
        }

        let response = await new MaintenanceService().getAllReminders(
          requestData
        );
        this.show = false;
        if (response && response.data) {
          this.items = response.data.list || [];
          // this.items = this.items.map((item) => {
          //   if (item.configuration && item.configuration.length) {
          //     item.configuration.forEach((config) => {
          //       if (config.type === "TIME") {
          //         config.value = this.DateTimeConvert(
          //           config.value,
          //           constants.DATE_TIME_FORMAT
          //         );
          //       }
          //     });
          //   }
          //   return item;
          // });

          this.totalMaintenance = response.data.pagination.total_records;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
      this.showLabelSize();
    }
  },
  handlePageChange(newPage) {
    this.updateQueryParam();
  },
  updateQueryParam() {
    const query = {
      ...this.$route.query,
      perPage: this.perPage.toString(),
      page: this.currentPage.toString()
    };
    if (this.filter && this.filter.value) {
      query.filter = this.filter.value;
    }
    this.$router.replace({ query }).catch(() => {});
  },
  mounted() {
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.debounceGetAllReminders();
    });

    window.addEventListener("resize", this.showLabelSize);
  }
};
</script>
<style lang="scss">
#modal-notification___BV_modal_title_ {
  font-size: 20px;
  margin-left: auto;
}
.action-width-maintenance {
  max-width: 250px;
  width: 250px;
  min-width: 250px;
  // text-align: end !important;
  padding: 0.72rem 1rem !important;
  text-align: right !important;
}

.services {
  white-space: nowrap;
}
// .dre-table {
//   tbody td,
//   tr th {
//     text-align: center;
//   }
// }
.try .media-body {
  display: flex;
  // justify-content: center !important;
}
.label-w-a {
  div {
    text-align: left;
  }
}
.maintenance-page {
  .b-table-sticky-header {
    max-height: calc(100vh - 300px) !important;
    min-height: calc(100vh - 300px) !important;
    overflow-y: auto;
    .table {
      width: calc(100% - 18px) !important;
    }
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.label-w-a {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
  text-align: left !important;
}
.btn.btn-icon {
  padding: 0px !important;
}
.disabled-maintenance-icon {
  pointer-events: none;
  opacity: 0.5;
  // background-color: $input-disabled-bg;
}

.maintenance-page {
  .b-table-sticky-header {
    max-height: calc(100vh - 300px) !important;
    min-height: calc(100vh - 300px) !important;
    overflow-y: auto;
    .table {
      width: calc(100% - 18px) !important;
    }
  }
}
.setting_icon_padding {
  padding: 0px 8px 0 8px;
}
.font-500 {
  font-weight: 500;
}
.config-due {
  white-space: nowrap;
}
.font-12 {
  font-size: 12px;
}
</style>
<style lang="scss">
.b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      top: -1px !important;
    }
  }
}
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
    }
  }
}
.tree-parent-outer {
  position: relative;
}
.tree-parent-item {
  position: relative;
  padding-left: 30px;
  // display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
}
.tree-parent-item:first-child:before {
  top: 24px;
  bottom: auto;
}
.tree-parent-item:last-child:before {
  bottom: 24px;
  top: auto;
}
.tree-parent-item:first-child:after {
  top: 22px;
  .or-divider {
    top: 0px;
  }
}
.tree-parent-item:last-child:after {
  bottom: 22px;
}
.tree-parent-item:last-child {
  .or-divider {
    top: -12px;
  }
}

.tree-parent-item:after {
  content: "";
  position: absolute;
  height: calc(100% - 25px);
  width: 2px;
  background-color: #9da1a5;
  left: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.tree-parent-item:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 20px;
  background-color: #9da1a5;
  left: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.or-divider {
  font-size: 12px;
  position: absolute;
  left: -4px;
  top: -5px;
  background: #fff;
  line-height: 11px;
}
.dark-layout {
  .or-divider {
    background: #283046;
  }
}
.tree-parent-item:first-child .or-divider {
  display: none;
}
.mr-30 {
  margin-right: 30px;
  min-width: 85px;
}
.white-space {
  white-space: nowrap;
}

.maintenance-page {
  // table tr th {
  //   text-align: center !important;
  // }
  table tr td {
    text-align: left !important;
  }
}
.hide-tree-parent-item:first-child:before {
  background: transparent;
}
.hide-tree-parent-item:first-child:after {
  background: transparent;
}
.action-width-maintenance-th {
  max-width: 250px;
  width: 250px;
  min-width: 250px;
  text-align: center !important;
  padding: 0.72rem 1rem !important;
  text-align: center !important;
}
@media only screen and (max-width: 991px) {
  .filter-card {
    width: 260px;
    margin-right: 7px;
  }
  .per-page-selector {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .maintenace-per-page,
  .notification-per-page {
    width: 100%;
    margin-right: 0 !important;
  }
}
.min-w-th {
  min-width: 170px;
}
</style>
