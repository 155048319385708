import moment from "moment-timezone";
import constants from "./constants";
import i18n from "@/libs/i18n";
const language = localStorage.getItem("lang") || "en";

const emailRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passwordRegEx = /^.{5,25}$/;

function isValidEmail(email) {
  return emailRegEx.test(email);
}

function isValidPassword(password) {
  return passwordRegEx.test(password);
}

function userAvatarTxt(text) {
  return (text && text.charAt(0).toUpperCase()) || "U";
}

function getRandomBgColor() {
  const variants = ["primary", "success", "warning", "danger", "info", "dark"];

  const random = Math.floor(Math.random() * variants.length);

  return variants[random] || "primary";
}

function formattedErrorMsg(msg) {
  msg = msg.toLowerCase();
  msg = msg.replace("field", "");
  return msg.charAt(0).toUpperCase() + msg.slice(1);
}

function checkUserPermissions(model, action) {
  const userPermissions = localStorage.getItem("USER_PERMISSIONS")
    ? JSON.parse(localStorage.getItem("USER_PERMISSIONS")) || []
    : [];
  const permission = userPermissions.filter((p) => p.subject == model);

  if (permission && permission.length) {
    const permissionAction = permission.filter((r) => r.action == action);

    if (permissionAction && permissionAction.length) {
      return true;
    }
  }
  return false;
}

function formatUnderscore(msg) {
  return msg
    .replace(/[_ ]/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (match) => match.toUpperCase());

  return (
    msg.replace(/[_ ]/g, " ").toLowerCase().charAt(0).toUpperCase() +
    msg.slice(1)
  );
}

function formattedString(msg, field, replaceFiled) {
  msg = msg.toLowerCase();
  msg = msg.replaceAll(field, replaceFiled);
  return msg.charAt(0).toUpperCase() + msg.slice(1);
}
function getAccountSettings() {
  let account = localStorage.getItem("USER_ACCOUNT_DETAILS");
  account = account ? JSON.parse(account) : null;
  return account && account.settings ? account.settings : constants.SETTINGS;
}
function formattedDateTime(dateTime, dateFormat, accountSettings) {
  let settings = getAccountSettings();

  if (accountSettings && accountSettings.timezone) {
    settings = accountSettings;
  }

  if (settings[dateFormat]) {
    dateFormat = settings[dateFormat];
  } else {
    dateFormat = "DD/MM/YYYY hh:mm A";
  }
  if (!dateTime) {
    dateTime = new Date();
  }
  if (settings.skipFormat) {
    return new Date(moment.tz(dateTime, settings.timezone)).toISOString();
  }
  return moment.tz(dateTime, settings.timezone).format(dateFormat);
}
function formattedDateTimeWithoutTimezone(dateTime, dateFormat) {
  const settings = getAccountSettings();

  if (settings[dateFormat]) {
    dateFormat = settings[dateFormat];
  } else {
    dateFormat = "DD/MM/YYYY hh:mm A";
  }
  if (!dateTime) {
    dateTime = new Date();
  }

  return moment(dateTime, settings.timezone).format(dateFormat);
}
function formattedDateTimeDayWise(dateTime, dateFormat, dayPoint) {
  const settings = getAccountSettings();
  if (settings[dateFormat]) {
    dateFormat = settings[dateFormat];
  } else {
    dateFormat = "DD/MM/YYYY hh:mm A";
  }
  if (!dateTime) {
    dateTime = new Date();
  }
  let date = moment(dateTime, dateFormat);
  if (dayPoint === "startOf") {
    date = date.startOf("day");
  } else if (dayPoint === "endOf") {
    date = date.endOf("day");
  }
  return date.format();
}

function getCurrentPath() {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;

  return `${protocol}//${hostname}${port ? ":" + port : ""}`;
}

function updateReportTime(time, start, accountDetails) {
  if (!time) {
    return "";
  }
  let account = accountDetails;
  if (!accountDetails || !accountDetails.settings) {
    account = localStorage.getItem("USER_ACCOUNT_DETAILS");
    account = account ? JSON.parse(account) : null;
  }

  account.settings =
    account && account.settings ? account.settings : constants.SETTINGS;

  let timezone = account.settings.timezone;
  let duration = moment.duration(
    moment.tz(timezone).diff(moment.tz(time, timezone))
  );
  if (start) {
    moment.duration(moment.tz(start, timezone).diff(moment.tz(time, timezone)));
  }

  let timeData = "";
  if (duration.asMinutes() < 59) {
    timeData = moment.tz(time, timezone).fromNow();
  } else if (duration.asMinutes() < 1440) {
    timeData = moment.tz(time, timezone).calendar();
  } else if (duration.asMinutes() < 2880) {
    timeData = moment.tz(time, timezone).format("dddd, h:mm A");
  } else {
    timeData = moment.tz(time, timezone).format("DD/MM/YYYY hh:mm A");
  }
  return timeData;
}

function getDateRange(interval) {
  const settings = getAccountSettings();
  const now = moment().tz(settings.timezone || constants.SETTINGS.timezone);

  switch (interval) {
    case "Today":
      const todayStart = now.clone().startOf("day");
      const todayEnd = now.clone();
      return {
        [interval]: [todayStart, todayEnd]
      };

    case "Yesterday":
      const yesterdayStart = now.clone().subtract(1, "days").startOf("day");
      const yesterdayEnd = now.clone().subtract(1, "days").endOf("day");
      return {
        [interval]: [yesterdayStart, yesterdayEnd]
      };

    case "ThisWeek":
      const startOfWeek = now.clone().startOf("week");
      const endOfWeek = now.clone();
      return {
        [interval]: [startOfWeek, endOfWeek]
      };

    case "LastWeek":
      const startOfLastWeek = now.clone().subtract(1, "weeks").startOf("week");
      const endOfLastWeek = now.clone().subtract(1, "weeks").endOf("week");
      return {
        [interval]: [startOfLastWeek, endOfLastWeek]
      };

    case "ThisMonth":
      const startOfMonth = now.clone().startOf("month");
      const endOfMonth = now.clone();
      return {
        [interval]: [startOfMonth, endOfMonth]
      };

    case "LastMonth":
      const startOfLastMonth = now
        .clone()
        .subtract(1, "months")
        .startOf("month");
      const endOfLastMonth = now.clone().subtract(1, "months").endOf("month");
      return {
        [interval]: [startOfLastMonth, endOfLastMonth]
      };

    case "ThisYear":
      const startOfYear = now.clone().startOf("year");
      const endOfYear = now.clone();
      return {
        [interval]: [startOfYear, endOfYear]
      };

    default:
      return {};
  }
}

function getCustomRange(interval) {
  const dateRange = getDateRange(interval);
  let startDate = new Date(dateRange[interval][0]).toISOString();
  let endDate = new Date(dateRange[interval][1]).toISOString();
  return `${startDate} to ${endDate}`;

  // Add more custom ranges as needed
}
function getCustomRanges1(isDateRange) {
  const dateRangeDefaultText = {
    Today: "Today",
    Yesterday: "Yesterday",
    ThisWeek: "This Week",
    LastWeek: "Last Week",
    ThisMonth: "This Month",
    LastMonth: "Last Month",
    ThisYear: "This Year"
  };

  if (!isDateRange) {
    delete dateRangeDefaultText.ThisWeek;
    delete dateRangeDefaultText.LastWeek;
    delete dateRangeDefaultText.ThisMonth;
    delete dateRangeDefaultText.LastMonth;
    delete dateRangeDefaultText.ThisYear;
  }
  const rangesdata = [];
  const settings = getAccountSettings();
  const now = moment().tz(settings.timezone || constants.SETTINGS.timezone);

  const addRange = (key, start, end) => {
    const ranges = {};
    let startDate = new Date(start).toISOString();
    let endDate = new Date(end).toISOString();
    let dateRange = `${startDate} to ${endDate}`;
    ranges.key = key;
    ranges.label = dateRangeDefaultText[key];
    ranges.value = dateRange;
    rangesdata.push(ranges);
  };

  const dateRangeText = i18n?.messages?.[language]?.dateRange || {};
  Object.keys(dateRangeDefaultText).forEach((layer) => {
    dateRangeDefaultText[layer] =
      dateRangeText[layer] || dateRangeDefaultText[layer];
  });

  const addDefaultRanges = () => {
    addRange("Today", now.clone().startOf("day"), now.clone().endOf("day"));
    addRange(
      "Yesterday",
      now.clone().subtract(1, "day").startOf("day"),
      now.clone().subtract(1, "day").endOf("day")
    );
  };

  const addExtendedRanges = () => {
    addRange(
      "ThisWeek",
      now.clone().startOf("week"),
      now.clone().endOf("week")
    );
    addRange(
      "LastWeek",
      now.clone().subtract(1, "week").startOf("week"),
      now.clone().subtract(1, "week").endOf("week")
    );
    addRange(
      "ThisMonth",
      now.clone().startOf("month"),
      now.clone().endOf("month")
    );
    addRange(
      "LastMonth",
      now.clone().subtract(1, "month").startOf("month"),
      now.clone().subtract(1, "month").endOf("month")
    );
    addRange(
      "ThisYear",
      now.clone().startOf("year"),
      now.clone().endOf("year")
    );
  };

  addDefaultRanges();
  if (isDateRange) {
    addExtendedRanges();
  }

  return rangesdata;
}
function getCurrentTime(time, duration, accountSettings) {
  let settings = getAccountSettings();

  if (accountSettings && accountSettings.timezone) {
    settings = accountSettings;
  }

  let date1 = moment.tz(
    formattedDate,
    settings.timezone || constants.SETTINGS.timezone
  );
  let h = 0;
  let m = 0;
  if (time && duration === "h") {
    h = time;
  } else if (time && duration === "m") {
    m = time;
  }
  const hours = date1.hours() + h;
  const minutes = date1.minutes() + m;
  const seconds = date1.seconds();
  const year = date1.year();
  const month = date1.month() + 1;
  const dateOfMonth = date1.date();
  const formattedDate = `${year}-${month
    .toString()
    .padStart(2, "0")}-${dateOfMonth.toString().padStart(2, "0")} ${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return new Date(formattedDate).toISOString();
}
function getCustomRanges(isDateRange) {
  const dateRangeDefaultText = {
    Today: "Today",
    Yesterday: "Yesterday",
    ThisWeek: "This Week",
    LastWeek: "Last Week",
    ThisMonth: "This Month",
    LastMonth: "Last Month",
    ThisYear: "This Year"
  };

  if (!isDateRange) {
    delete dateRangeDefaultText.ThisWeek;
    delete dateRangeDefaultText.LastWeek;
    delete dateRangeDefaultText.ThisMonth;
    delete dateRangeDefaultText.LastMonth;
    delete dateRangeDefaultText.ThisYear;
  }

  const settings = getAccountSettings();
  // const now = moment().tz(settings.timezone || constants.SETTINGS.timezone);
  const now = moment();
  const ranges = {};
  const addRange = (key, start, end) => {
    ranges[key] = [start.toDate(), end.toDate()];
  };

  const dateRangeText = i18n?.messages?.[language]?.dateRange || {};
  Object.keys(dateRangeDefaultText).forEach((layer) => {
    dateRangeDefaultText[layer] =
      dateRangeText[layer] || dateRangeDefaultText[layer];
  });

  const addDefaultRanges = () => {
    addRange("Today", now.clone().startOf("day"), now.clone().endOf("day"));
    addRange(
      "Yesterday",
      now.clone().subtract(1, "day").startOf("day"),
      now.clone().subtract(1, "day").endOf("day")
    );
  };

  const addExtendedRanges = () => {
    addRange(
      "ThisWeek",
      now.clone().startOf("week"),
      now.clone().endOf("week")
    );
    addRange(
      "LastWeek",
      now.clone().subtract(1, "week").startOf("week"),
      now.clone().subtract(1, "week").endOf("week")
    );
    addRange(
      "ThisMonth",
      now.clone().startOf("month"),
      now.clone().endOf("month")
    );
    addRange(
      "LastMonth",
      now.clone().subtract(1, "month").startOf("month"),
      now.clone().subtract(1, "month").endOf("month")
    );
    addRange(
      "ThisYear",
      now.clone().startOf("year"),
      now.clone().endOf("year")
    );
  };

  addDefaultRanges();
  if (isDateRange) {
    addExtendedRanges();
  }

  return ranges;
}
function getCustomFutureRanges(onlySpecificRanges = false) {
  const dateRangeDefaultText = {
    Today: "Today",
    ThisWeek: "This Week",
    ThisMonth: "This Month",
    ThisYear: "This Year",
    NextWeek: "Next Week",
    NextMonth: "Next Month"
  };
  const settings = getAccountSettings();
  const now = moment().tz(settings.timezone || constants.SETTINGS.timezone);

  const ranges = {};
  const addRange = (key, start, end) => {
    ranges[key] = [start.toDate(), end.toDate()];
  };

  const dateRangeText = {}; // Assuming an i18n setup or another source for date range text.
  Object.keys(dateRangeDefaultText).forEach((layer) => {
    dateRangeDefaultText[layer] =
      dateRangeText[layer] || dateRangeDefaultText[layer];
  });

  const addSpecificRanges = () => {
    addRange("Today", now.clone().startOf("day"), now.clone().startOf("day"));
    addRange(
      "ThisWeek",
      now.clone().startOf("week"),
      now.clone().endOf("week")
    );
    addRange(
      "NextWeek",
      now.clone().add(1, "week").startOf("week"),
      now.clone().add(1, "week").endOf("week")
    );
    addRange(
      "ThisMonth",
      now.clone().startOf("month"),
      now.clone().endOf("month")
    );
    addRange(
      "NextMonth",
      now.clone().add(1, "month").startOf("month"),
      now.clone().add(1, "month").endOf("month")
    );
    addRange(
      "ThisYear",
      now.clone().startOf("year"),
      now.clone().endOf("year")
    );
  };

  if (onlySpecificRanges) {
    addSpecificRanges();
  } else {
    addDefaultRanges();
    addExtendedRanges();
  }

  return ranges;
}
function generateDarkColors(numColors) {
  const darkColors = [];

  for (let i = 0; i < numColors; i++) {
    const red = Math.floor(Math.random() * 128);
    const green = Math.floor(Math.random() * 128);
    const blue = Math.floor(Math.random() * 128);

    // Ensure the overall brightness is low
    const brightness = 0.3 + Math.random() * 0.4; // Adjust the range based on your preference
    const darkenedRed = Math.floor(red * brightness);
    const darkenedGreen = Math.floor(green * brightness);
    const darkenedBlue = Math.floor(blue * brightness);

    const color = `rgb(${darkenedRed},${darkenedGreen},${darkenedBlue})`;
    darkColors.push(color);
  }

  return darkColors;
}

function convertDatePerTimezone(date) {
  let date1 = moment(date);

  const hours = date1.hours();
  const minutes = date1.minutes();
  const seconds = date1.seconds();
  const year = date1.year();
  const month = date1.month() + 1;
  const dateOfMonth = date1.date();
  const formattedDate = `${year}-${month
    .toString()
    .padStart(2, "0")}-${dateOfMonth.toString().padStart(2, "0")} ${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const settings = getAccountSettings();

  const timeFormat = moment.tz(
    formattedDate,
    settings.timezone || constants.SETTINGS.timezone
  );
  console.log(timeFormat.toISOString());
  return timeFormat.toISOString();
}
function calculateDueStatus(rechargeDate, expiryDate, day) {
  const currentDate = moment();
  const expiry = moment(expiryDate);

  // Check if the expiry date has passed
  if (currentDate.isAfter(expiry)) {
    return "Expired";
  }
  const diffInSeconds = expiry.diff(currentDate, "seconds");
  const diffInMinutes = expiry.diff(currentDate, "minutes");
  const diffInHours = expiry.diff(currentDate, "hours");
  const diffInDays = expiry.diff(currentDate, "days");
  const diffInWeeks = expiry.diff(currentDate, "weeks");
  const diffInMonths = expiry.diff(currentDate, "months");

  let status = "";

  // If the expiry is less than 1 minute, show seconds
  if (diffInSeconds < 60) {
    status = `Expires in ${diffInSeconds} seconds`;
  }
  // If expiry is less than 1 hour, show minutes and seconds
  else if (diffInMinutes < 60) {
    const seconds = diffInSeconds % 60; // Get remaining seconds
    status = `Expires in ${diffInMinutes} minutes `;
  }
  // If expiry is less than 1 day, show hours and minutes
  else if (diffInHours < 24) {
    const minutes = diffInMinutes % 60; // Get remaining minutes
    status = `Expires in ${diffInHours} hours `;
  }
  // If expiry is less than 1 week, show days and hours
  else if (diffInDays < 7) {
    const hours = diffInHours % 24; // Get remaining hours
    status = `Expires in ${diffInDays} days `;
  }
  // If expiry is less than 1 month, show weeks and days
  else if (diffInWeeks < 4) {
    const days = diffInDays % 7; // Get remaining days
    status = `Expires in ${diffInWeeks} weeks `;
  }
  // For longer than a month, show months and weeks
  else {
    status = moment(expiryDate).format(constants.SETTINGS.date_format);
  }

  return status;
}
function cleanFieldLabel(fieldName) {
  return (fieldName || "")
    .split("_")
    .join(" ")
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
export default {
  isValidEmail,
  isValidPassword,
  userAvatarTxt,
  getRandomBgColor,
  formattedErrorMsg,
  checkUserPermissions,
  formattedString,
  formattedDateTime,
  formattedDateTimeWithoutTimezone,
  formattedDateTimeDayWise,
  getCurrentPath,
  updateReportTime,
  generateDarkColors,
  formatUnderscore,
  getCustomRanges,
  getCustomRange,
  getCustomRanges1,
  getCustomFutureRanges,
  convertDatePerTimezone,
  getCurrentTime,
  calculateDueStatus,
  cleanFieldLabel
};
