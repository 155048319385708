<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      class="position-relative main-card-padding mb-p-1 mobile-card-padding"
    >
      <div class="mb-2 justify-content-between align-items-center d-flex">
        <div class="d-flex align-items-center">
          <h4 class="mb-0">{{ $t("device.Devices") }}</h4>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div class="d-flex filter-card" v-if="showFiltermenu">
            <div class="alert-per-page align-items-center mr-1">
              <div class="addXIcon">
                <feather-icon
                  class="sizeIcon"
                  @click="showFiltermenu = !showFiltermenu"
                  icon="XIcon"
                  size="15"
                />
              </div>
              <label class="mt-1 search-label">{{
                $t("device.Entries")
              }}</label>
              <v-select
                :clearable="false"
                id="vue-select"
                class="per-page-selector d-inline-block fix-83"
                v-model="perPage"
                :options="option"
              />
            </div>

            <div class="d-flex align-items-center justify-content-end search-w">
              <search
                class="mobile-margin-top no-btm-0 mobile-hidden"
                style="float: left"
                v-model="searchFiled"
                v-if="!showFiltermenu"
                :searchColumn="searchColumn"
                :operator="operator"
              ></search>
              <search
                class="mobile-margin-top no-btm-0 desktop-hidden"
                style="float: left"
                v-model="searchFiled"
                v-if="showFiltermenu"
                :searchColumn="searchColumn"
                :operator="operator"
              ></search>
              <div
                class="AccountFlid data-device-w"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ACCOUNT_READ,
                    subject: constants.PERMISSIONS_MODEL.ACCOUNT
                  }) && !groupName
                "
              >
                <AccountSearch
                  v-model="ownerAccountId"
                  :all="true"
                  :placeholderName="$t('device.OwnerAccount')"
                  :accountsL="accountsList"
                  @input="updateAccountUrl"
                />
              </div>

              <div
                class="AccountFlid data-device-w"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ACCOUNT_READ,
                    subject: constants.PERMISSIONS_MODEL.ACCOUNT
                  }) && !groupName
                "
              >
                <AccountSearch
                  v-model="accountId"
                  :all="true"
                  :placeholderName="$t('device.AllocatedAccount')"
                  :accountsL="accountsList"
                  @input="updateAccountUrl"
                />
              </div>
            </div>
          </div>

          <div class="filter-btn-outer-new ml-1 d-flex">
            <b-button
              v-b-tooltip.hover.v-primary
              :title="$t('device.AddDevice')"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.ADD,
                  subject: constants.PERMISSIONS_MODEL.DEVICES
                }) && !isGroupEntity
              "
              variant="primary"
              :to="{ name: 'add-new-device' }"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="mobile-hidden"> {{ $t("device.AddDevice") }}</span>
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              class="desktop-hidden ml-1"
              @click="showFiltermenu = !showFiltermenu"
            >
              <feather-icon icon="FilterIcon" size="15" />
            </b-button>
          </div>
        </div>
      </div>
      <!-- Table Top -->
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="7"
          :table-props="{ bordered: true, striped: true }"
          class="device-list2"
        />
      </div>
      <b-table
        v-if="!show"
        class="position-relative header-left-align device-list"
        responsive
        show-empty
        sticky-header
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: devices -->
        <template #cell(deviceName)="row">
          <h6 class="mb-0">
            {{ `${row.item.device_name ? `${row.item.device_name}` : " "}` }}
          </h6>
        </template>
        <template #cell(version)="row">
          <h6 class="mb-0">
            {{ `${row.item.version ? `${row.item.version}` : " "}` }}
          </h6>
        </template>
        <template #cell(identifier)="row">
          <h6 class="mb-0">
            {{ `${row.item.identifier ? `${row.item.identifier}` : " "}` }}
          </h6>
        </template>
        <template #cell(allocated_to)="row">
          <h6 class="mb-0">
            {{ `${row.item.allocated_to ? `${row.item.allocated_to}` : " "}` }}
          </h6>
        </template>

        <template #cell(model)="row">{{
          row.item.model_title || row.item.model_name || " "
        }}</template>
        <template #cell(sim_cards)="row">
          <div class="mb-0 sim_number-col">
            <ul v-if="row.item.sim_cards" class="sim_number">
              <li
                :key="index"
                v-for="(sim_card, index) in row.item.sim_cards._arr"
              >
                {{ sim_card.sim_number }} {{ `(${sim_card.sim_operator})` }}
              </li>
            </ul>
          </div>
        </template>
        <template #cell(status)="row"
          ><b-badge
            class="badge-cust"
            :variant="resolveFilterBadgeColor(row.item.status)"
          >
            {{ `${row.item.status ? `${row.item.status}` : " "}` }}
          </b-badge></template
        >
        <template #cell(labels)="row">
          <div class="parent" id="parent">
            <DynamicBadge
              :isList="true"
              :entityType="'DEVICE'"
              :tagsData="row.item.labels"
              :showTextInBadge="Number(7)"
              :event="row.item"
              :maxTagShow="Number(defaultBadgeShow)"
              :listApi="getAlldeviceList"
            ></DynamicBadge>
            <b-badge
              class="badge-cust"
              :variant="resolveFilterBadgeColor(row.item.status)"
            >
            </b-badge>
          </div>
        </template>
        <template #cell(ACTIONS)="row">
          <div class="text-right min-80 d-flex justify-content-end">
            <span :id="row.item.allocate_tool" class="text-primary-icon">
              <span
                v-b-tooltip.hover.top="$t('tooTip.allocation')"
                v-if="
                  row.item.status.toLowerCase() === 'active' &&
                  !restrictAllocation &&
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE,
                    subject: constants.PERMISSIONS_MODEL.DEVICES
                  })
                "
                class="text-primary"
                v-b-modal.unit-allocate
                @click="showPopUp(row.item)"
              >
                <AllocationIcon class="allocate-icon" />
              </span>
            </span>
            <span>
              <b-link
                :to="{
                  name: 'edit-device',
                  params: {
                    id: row.item.id
                  }
                }"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE,
                    subject: constants.PERMISSIONS_MODEL.DEVICES
                  }) &&
                  (row.item.is_device_editable || row.item.self_allocated)
                "
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.update')"
                  icon="EditIcon"
                  class="text-primary action-icon cursor-pointer"
                  size="18"
                />
              </b-link>
            </span>
            <span>
              <b-link
                :to="{
                  name: 'view-device',
                  params: { id: row.item.id }
                }"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.DEVICES
                  })
                "
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.view')"
                  icon="EyeIcon"
                  class="text-primary action-icon cursor-pointe"
                  size="18"
                />
              </b-link>
            </span>
            <span>
              <feather-icon
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.DELETE,
                    subject: constants.PERMISSIONS_MODEL.DEVICES
                  }) &&
                  (row.item.is_device_editable || row.item.self_allocated)
                "
                icon="Trash2Icon"
                class="text-danger action-icon"
                size="18"
                v-b-modal.modal-sm-remove
                @click="deleteDevice(row.item)"
              />
            </span>
          </div>

          <!-- <b-tooltip delay="100" variant="primary" :target="row.item.up_tool">{{
            $t("tooTip.update")
          }}</b-tooltip>
          <b-tooltip delay="100" variant="primary" :target="row.item.id">{{
            $t("tooTip.view")
          }}</b-tooltip> -->
        </template>
        >
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalDevices > 0"
              v-model="currentPage"
              :total-rows="totalDevices"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mb-1 mt-sm-0 de-pdb"
              prev-class="prev-item"
              next-class="next-item"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal id="modal-sm-invitations" centered modal-class="no-header-modal">
      <b-card-text>
        <div class="new-invite-modal-title">{{ $t("user.SendInvite") }}</div>
        <div class="you-invite">{{ $t("user.Invitenewmembers") }}</div>
        <b-form class="mtt-37">
          <b-form-group :label="$t('user.Email')">
            <b-form-input id="name" placeholder="example@gmail.com" />
          </b-form-group>
          <b-form-group :label="$t('user.Role') + '*'" label-for="vue-select">
            <v-select id="vue-select" v-model="selected" :options="option" />
          </b-form-group>
          <div class="d-flex justify-content-center mb-64 mtt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t("user.Accept") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
            >
              {{ $t("user.Decline") }}
            </b-button>
          </div>
        </b-form>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-accept-invite"
      centered
      size="sm"
      modal-class="no-header-modal"
    >
      <b-card-text>
        <div class="d-flex justify-content-center">
          <b-avatar variant="light-primary" size="96" class="size-96">
            <feather-icon size="37" icon="UserPlusIcon" />
          </b-avatar>
        </div>
        <div class="new-invite-title">{{ $t("user.NewInvite") }}</div>
        <div class="you-invite">{{ $t("user.YouInvite") }}</div>
        <div class="invite-name">"{{ $t("user.InviteName") }}"</div>
        <div class="d-flex justify-content-center mb-64 mtt-50">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
          >
            {{ $t("user.Accept") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
          >
            {{ $t("user.Decline") }}
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
    <AreYouSureModal
      :data="deleteData"
      :component="c1"
      :status="status"
      :onClose="onClose"
      :removedUser="removeDevice"
    />
    <!-- <Loader :show="show" /> -->
    <AllocationPopUp
      v-if="isAllocationEnable"
      :allocationData="allocationData"
      :component="c1"
      :status="status"
      :isDevice="true"
      :onClose="onClose"
      :changeInput="changeInput"
      :currentUnit="allocationData"
      :refreshed="refreshed"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BModal,
  BForm,
  BImg,
  BTooltip,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import AccountSearch from "@/views/Unit/Unit-V2/AccountSearch.vue";
import Search from "@/views/Common/search.vue";
import vSelect from "vue-select";
import store from "@/store";
import DeviceService from "@/libs/api/device-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import Loader from "@/layouts/components/Loader.vue";
import AllocationPopUp from "../Unit/AllocationPopUp.vue";
import AllocationIcon from "@/assets/images/icons/allocation.svg";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import constants from "@/utils/constants";
import AccountService from "@/libs/api/account-service";
export default {
  components: {
    BCard,
    BRow,
    Search,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    Loader,
    vSelect,
    BFormGroup,
    BModal,
    BForm,
    AreYouSureModal,
    BImg,
    AllocationPopUp,
    BTooltip,
    VBTooltip,
    BSkeletonTable,
    AllocationIcon,
    DynamicBadge,
    AccountSearch
  },
  data() {
    return {
      tableColumns: [
        {
          key: "device_name",
          tdClass: "mobile-no-wrap",
          label: this.$t("device.deviceName")
        },
        { key: "version", tdClass: "", label: this.$t("device.Version") },
        { key: "identifier", Class: "", label: this.$t("device.identifier") },
        {
          key: "allocated_to",
          tdClass: "",
          label: this.$t("device.allocated_to")
        },
        {
          key: "model",
          tdClass: "mobile-no-wrap",
          label: this.$t("device.Model")
        },
        { key: "sim_cards", Class: "", label: this.$t("device.sim_cards") },
        {
          key: "status",
          tdClass: "status-width",
          thClass: "status-width",
          label: this.$t("device.Status")
        },
        {
          key: "labels",
          tdClass: "status-width-right badge-230",
          thClass: "status-width-right badge-230",
          label: this.$t("device.labels")
        },
        {
          key: "ACTIONS",
          tdClass: "action-width",
          thClass: "action-width",
          label: this.$t("device.actions")
        }
      ],
      // tableColumns: [
      //   "device_name",
      //   "version",
      //   "identifier",
      //   "allocated_to",
      //   "model",
      //   "sim_cards",
      //   "status",
      //   "groups",
      //   "ACTIONS"
      // ],
      showFiltermenu: false,
      ownerAccountId: null,
      accountId: null,
      selected: "10",
      option: ["10", "20", "30"],
      c1: null,
      status: false,
      deleteData: null,
      totalDevices: null,
      perPage: this.$route.query.perPage
        ? this.$route.query.perPage
        : this.nearestPage(
            ["10", "20", "30"],
            parseInt(window.innerHeight / 57)
          ),
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      items: [],
      timeoutId: "",
      show: false,
      restrictAllocation: false,
      filter: this.$route.query.filter || "",
      allocationData: null,
      isAllocationEnable: false,
      defaultBadgeShow: 2,
      accountsList: [],
      searchColumn: ["all"],
      // searchColumn: [
      //   "device_name",
      //   "identifier",
      //   "sim_cards",
      //   "model",
      //   "allocated_to"
      // ],
      searchFiled: {},
      operator: "ilike"
    };
  },
  props: ["isGroupEntity", "groupName"],
  directives: {
    "b-tooltip": VBTooltip
  },
  setup() {
    const resolveFilterBadgeColor = (filter) => {
      filter = filter && filter.toUpperCase();
      const status = {
        ACTIVE: "light-success",
        INACTIVE: "light-warning",
        DAMAGED: "light-danger",
        BLOCKED: "light-secondary"
      };

      const selectedStatus =
        filter && status[filter] ? status[filter] : status["ACTIVE"];

      return selectedStatus;
    };
    return {
      resolveFilterBadgeColor
    };
  },
  watch: {
    $route(to, from) {
      this.ownerAccountId = to.query.owner_account_id || null;
      this.accountId = to.query.account_id || null;
    },
    ownerAccountId(newAccountId, oldAccountId) {
      this.debounceGetAllDeviceList();
    },
    accountId(newAccountId, oldAccountId) {
      this.debounceGetAllDeviceList();
    },
    perPage() {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetAllDeviceList();
    },
    currentPage() {
      this.updateQueryParam();
      this.debounceGetAllDeviceList();
    },
    searchFiled(newVal, oldVal) {
      this.filter = newVal;
      this.debounceGetAllDeviceList();
    }
  },
  mounted() {
    this.ownerAccountId = this.$route.query.owner_account_id || null;
    this.accountId = this.$route.query.account_id || null;
    this.onWindowResize();
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;
      if (filter) {
        this.filter = filter;
      }
      if (page) {
        this.currentPage = parseInt(page);
      }
      if (perPage) {
        this.perPage = parseInt(perPage);
      }
    });
    //window.addEventListener("resize", this.onWindowResize);
    this.getChildAccounts();
    if (
      this.checkAbility({
        action: constants.PERMISSIONS_ACTION.READ,
        subject: constants.PERMISSIONS_MODEL.LABELS
      })
    ) {
      this.tableColumns.splice(-1, 0, {
        key: "labels",
        tdClass: "operator-group-width labels-width",
        thClass: "operator-group-width labels-width text-right"
      });
    }
    this.restrictAllocation =
      localStorage.getItem("USER_SUB_ACCOUNTS_RESTRICTED") == "true"
        ? true
        : false;
    if (this.groupName) {
      this.$router.replace({
        query: { account_id: this.$route.query.account_id }
      });
    }
    if (!this.$route.query.page) {
      if (this.groupName) {
        this.$router.replace({
          query: { account_id: this.$route.query.account_id }
        });
      } else {
        this.$router
          .replace({ query: { ...this.$route.query, page: this.currentPage } })
          .catch((e) => {});
      }

      this.debounceGetAllDeviceList();
    } else {
      this.currentPage = parseInt(this.$route.query.page);
      this.currentPage == 1 && this.debounceGetAllDeviceList();
    }
    window.addEventListener("resize", this.showLabelSize);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
    window.removeEventListener("resize", this.showLabelSize);
  },
  methods: {
    debounceGetAllDeviceList() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAlldeviceList();
      }, 300); // Adjust the delay as necessary
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      // if (this.filter) {
      //   query.filter = this.filter;
      // }
      this.$router.replace({ query }).catch(() => {});
    },
    updateAccountUrl() {
      setTimeout(() => {
        const query = { ...this.$route.query };

        if (!this.ownerAccountId && query.owner_account_id) {
          delete query.owner_account_id;
        }

        if (this.ownerAccountId) {
          query.owner_account_id = this.ownerAccountId;
        }

        if (!this.accountId && query.account_id) {
          delete query.account_id;
        }

        if (this.accountId) {
          query.account_id = this.accountId;
        }

        this.$router.replace({ query }).catch(() => {});
      }, 500);
    },
    updateSearch(value) {
      this.filter = value;
      this.updateUrl();
    },

    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },

    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    async changeInput(allocationData) {
      this.allocationData = allocationData;
    },
    showPopUp(props) {
      this.isAllocationEnable = true;
      setTimeout(() => {
        this.c1 = "src/views/Unit/AllocationPopUp.vue";
      }, 100);

      this.status = true;
      this.allocationData = props;
      this.allocationData["allocatedToId"] =
        this.allocationData["allocated_to_id"];
    },
    editDevice(props, editFlag) {
      if (editFlag) {
        this.$router.push({ name: "edit-device", params: { id: props } });
      } else {
        this.$router.push({ name: "view-device", params: { id: props } });
      }
    },
    deleteDevice(props) {
      this.c1 = "src/layouts/components/AreYouSureModal.vue";
      this.status = true;
      this.deleteData = { name: props.device_name, id: props.id };
    },
    refreshed() {},
    async removeDevice(props) {
      this.deleteData = { name: "", id: "" };
      if (props.id) {
        try {
          this.onClose();
          let response = await new DeviceService().deleteDevice({
            id: props.id
          });

          if (response && response.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("device.DeletedSuccessfully"),
                icon: "EditIcon",
                variant: "success"
              }
            });

            this.getAlldeviceList();
          } else if (response && response.error && response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "EditIcon",
                variant: "error"
              }
            });
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    onClose() {
      this.getAlldeviceList();
      this.$bvModal.hide("modal-sm-remove");
    },
    showLabelSize() {
      setTimeout(() => {
        const parentElement = document.querySelector(".parent");
        if (parentElement) {
          const parentWidth = parentElement.offsetWidth;
          this.defaultBadgeShow = Math.floor(parentWidth / 71);
        }
      }, 200);
    },
    async getAlldeviceList() {
      try {
        this.show = true;
        let requestData = {
          owner_account_id: this.ownerAccountId,
          account_id: this.accountId,
          page: this.currentPage,
          page_size: Number(
            this.$route.query.page_size || parseInt(this.perPage)
          ),
          filter: this.filter.value || ""
        };

        if (this.groupName) {
          requestData.label = this.groupName;
        }

        let response = await new DeviceService().deviceList(requestData);
        if (response && response.data) {
          this.show = false;
          this.items = response.data.devices;
          this.items = this.items.map((d) => {
            d.allocate_tool = `allocate${d.id}`;
            d.up_tool = `up${d.id}`;
            d.del_tool = `del${d.id}`;
            d.labels = d.labels || [];
            return d;
          });

          this.totalDevices = response.data.count;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.showLabelSize();
    },
    async getChildAccounts(filterAccount) {
      try {
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || "",
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });

        if (response && response.data) {
          this.accountsList = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.new-invite-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
  margin-top: 32px;
}

.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}

.invite-name {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  font-weight: 600;
  word-break: break-all;
}

.badge-cust {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--red2);
  padding: 4px 9px !important;
}

.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}

.allocate_svg,
.allocate_svg path {
  fill: var(--primary);
}

.action-width {
  max-width: 155px;
  width: 155px;
  min-width: 155px;
}

.status-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
  text-align: center;
}

.status-width-right,
.operator-group-width.labels-width {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
  text-align: left !important;
}

.group-width {
  max-width: 180px;
  width: 180px;
  min-width: 180px;
  text-align: left;

  .event-Tag .media-body {
    justify-content: flex-start;
  }
}

.new-invite-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
  margin-top: 32px;
}

.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}

.invite-name {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  font-weight: 600;
  word-break: break-all;
}

.badge-cust {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--red2);
  padding: 4px 9px !important;
}

.badge-cust-accepted {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--green2);
  padding: 4px 9px !important;
}

.badge-cust-pending {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--green3);
  padding: 4px 9px !important;
}

.badge-cust-decline {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: $yellow;
  padding: 4px 9px !important;
}
.AccountFlid {
  width: 10vw;
  margin-left: 10px;
  .form-group {
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 991px) {
  .mobile-margin-top {
    margin-bottom: 10px;
  }
  .search-w {
    width: 100% !important;
    display: block !important;
  }
  .AccountFlid {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }
  .filter-card {
    flex-wrap: wrap;
  }
}
// .search-bg {
//   max-width: 250px !important;
// }
</style>
<style lang="scss" scoped>
.data-device-w {
  display: flex;
  .form-group {
    margin-bottom: 0px !important;
  }
}
.b-table-sticky-header {
  max-height: calc(100vh - 270px) !important;
  overflow-y: auto;
}
.table-responsive {
  // min-height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
}
.b-table-sticky-header {
}
.b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      top: -1px !important;
    }
  }
}
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
      top: -1px !important;
    }
  }
}
</style>
