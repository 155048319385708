<template>
  <b-modal
    id="modal-sm-shared-location-list"
    centered
    size="xl"
    modal-class="no-header-modal"
    :title="$t('sharedLocation.ShareLiveLocation')"
    ><div>
      <b-card-text>
        <div style="display: none">{{ $t("ReportsData.SaveReportDesc") }}</div>
        <div class="alerts-search-comp">
          <b-card
            class="alert-search position-relative main-card-padding card-location-show-p"
          >
            <div class="m-2-br-alert-bottm">
              <div class="adduser-btn-row">
                <h4 class="card-title">
                  <b-media class="align-items-center">
                    <template #aside>
                      <b-avatar
                        variant="badge-secondary"
                        size="50"
                        :src="locationUnit.icon"
                      />
                    </template>
                    <h6 class="mb-0 d-flex mobile-no-wrap">
                      {{ locationUnit.name }}
                    </h6>
                  </b-media>
                </h4>
                <div class="filter-btn-outer-new d-flex">
                  <b-button
                    variant="primary"
                    class="btn-icon"
                    @click="generateLink()"
                  >
                    <feather-icon icon="PlusIcon" size="12" /><span
                      class="mobile-hide-text"
                    >
                      {{ $t("sharedLocation.CreateLiveLocation") }}
                    </span>
                  </b-button>
                </div>
              </div>
            </div>

            <SharedLocationList
              ref="genrateLink"
              :unit="locationUnit"
              :perPage="perPage"
            ></SharedLocationList>
            <GenerateSharedLink
              :unit="locationUnit"
              @refreshed="refreshed"
            ></GenerateSharedLink>
          </b-card>
        </div>
      </b-card-text>
    </div>
  </b-modal>
</template>
<script>
import {
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BModal,
  BCardText,
  BForm,
  BMedia,
  BAvatar
} from "bootstrap-vue";
import constants from "@/utils/constants";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import SharedLocationList from "./SharedLocationList.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportService from "@/libs/api/report-service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import GenerateSharedLink from "./GenerateSharedLink.vue";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BFormGroup,
    BModal,
    BCardText,
    BForm,
    BMedia,
    TagMultiSelect,
    ValidationProvider,
    ValidationObserver,
    SharedLocationList,
    BAvatar,
    GenerateSharedLink
  },
  data() {
    return {
      report: {
        report_name: "",
        labels: [],
        report_id: ""
      },
      hasErr: "",
      perPage: 10,
      isShow: false
    };
  },
  directives: {
    // "b-tooltip": VBTooltip,
    Ripple
  },
  props: ["locationUnit"],
  watch: {
    locationUnit() {
      this.report.report_name =
        this.locationUnit.name || this.locationUnit.report_name;
      this.report.labels = this.locationUnit.labels;
      this.report.report_id = this.locationUnit.id;
    }
  },
  methods: {
    refreshed() {
      this.$refs.genrateLink.getOperators();
    },
    async save() {
      // if (!this.isEditable) {
      //   this.$router.push({ name: "report-list" });
      //   return;
      // }
      try {
        this.report.report_id = this.locationUnit.id;
        this.isShow = true;
        const response = await new ReportService().saveReport(this.report);

        if (response && response.data) {
          this.isShow = false;
          // console.log(this.report);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("ReportsData.SavedSuccessFully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.close();

          this.$router.push({ name: "report-list" });
        } else if (response && response.error && response.error.message) {
          this.isShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.isShow = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    generateLink() {
      this.$bvModal.show("modal-sm-add-shared-location");
    },
    close() {
      this.$bvModal.hide("modal-sm-shared-location-list");
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}
.alert-search {
  .alert-per-page {
    margin-left: auto;
    margin-right: 15px;
  }
  .search-filter {
    margin-left: 0;
  }
  .operator-table-width {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .search-filter {
    margin-right: 15px;
  }
}
@media only screen and (max-width: 724px) {
  .mobile-hide-text {
    display: none !important;
  }
  .card-location-show-p {
    .card-body {
      padding: 10px !important;
    }
    .adduser-btn-row {
      padding: 0px !important;
      margin-bottom: 10px !important;
    }
  }
}
</style>
<style lang="scss">
#modal-sm-shared-location-list {
  .operator-table-width .b-table-sticky-header {
    max-height: calc(100vh - 310px) !important;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 767px) {
  #modal-sm-shared-location-list {
    .modal-body {
      max-height: calc(100vh - 120px) !important;
      overflow: auto;
    }
  }
}
#modal-sm-shared-location-list {
  .close {
    display: contents !important;
  }
}
</style>
