<template>
  <div class="operator-table-width">
    <div no-body class="mb-0">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="5"
          :columns="5"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        v-if="!show"
        class="position-relative sim-icon-mb Operator-table child-1-30-percent"
        responsive
        show-empty
        align-v="end"
        :items="items"
        sticky-header
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <template #cell(generated_url)="row">
          <b-link target="_blank" @click="redirectLink(row.item.generated_url)">
            <span class="mobile-no-wrap">{{ row.item.generated_url }}</span>
          </b-link>
        </template>

        <template #cell(ACTIONS)="row">
          <div class="text-left min-80">
            <span
              :id="'textToCopy' + row.item.id"
              class="invitation-link-hidden"
            >
              {{ row.item.generated_url }}
            </span>
            <feather-icon
              v-b-tooltip.hover.v-primary
              icon="CopyIcon"
              :title="$t('tooTip.copy')"
              class="text-primary action-icon"
              size="18"
              @click="copyText('textToCopy' + row.item.id)"
              style="position: relative; cursor: pointer; margin-left: 30px"
            ></feather-icon>
            <span>
              <feather-icon
                v-b-tooltip.hover.v-primary
                :title="$t('tooTip.delete')"
                style="cursor: pointer; margin-left: 20px"
                icon="Trash2Icon"
                class="text-danger action-icon curserPointer mr-2"
                size="18"
                v-b-modal.modal-sm-remove
                @click="deleteOperator(row.item)"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.DELETE,
                    subject: constants.PERMISSIONS_MODEL.OPERATORS
                  })
                "
              />
            </span>
          </div>

          <div class="text-left track_tool"></div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalOperators > 0"
              v-model="currentPage"
              :total-rows="totalOperators"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="handlePageChange"
              :no-paging-nav="false"
              :active-class="activeClass"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <AreYouSureModal
      :customData="{ msgTxt: 'Do you really want to remove the link' }"
      :data="removedOperatorData"
      :onClose="onClose"
      :removedUser="removeLink"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";

import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import UnitService from "@/libs/api/unit-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import * as moment from "moment";
import constants from "@/utils/constants";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BPagination,
    AreYouSureModal,
    VBTooltip,
    BSkeletonTable,
    DynamicBadge,
    VBTooltip
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      tableColumns: [
        {
          key: "generate_date",
          tdClass: "operator-code",
          thClass: "operator-code text-left",
          label: this.$t("sharedLocation.tableColumns.generateDate")
        },
        // {
        //   key: "date",
        //   tdClass: "operator-code",
        //   thClass: "operator-code text-left",
        //   label: this.$t("sharedLocation.tableColumns.date")
        // },
        {
          key: "start_time",
          tdClass: "operator-code",
          thClass: "text-left",
          label: this.$t("sharedLocation.tableColumns.startTime")
        },
        {
          key: "end_time",
          tdClass: "operator-code",
          thClass: "text-left",
          label: this.$t("sharedLocation.tableColumns.endTime")
        },
        {
          key: "generated_url",
          tdClass: "text-left",
          thClass: "text-left",
          label: this.$t("sharedLocation.tableColumns.link")
        },

        {
          key: "ACTIONS",
          tdClass: "operator-action-width",
          thClass: "text-center",
          label: this.$t("sharedLocation.tableColumns.actions")
        }
      ],
      totalOperators: 0,
      // perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      activeClass: "active",
      items: [],
      removedOperatorData: {},
      show: false,
      defaultBadgeShow: 2,
      timeoutId: null
    };
  },
  props: {
    unit: Object,

    perPage: {
      type: Number,
      default: 10
    }
  },

  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }

      this.debounceGetOperators();
    });
    if (
      this.checkAbility({
        action: constants.PERMISSIONS_ACTION.READ,
        subject: constants.PERMISSIONS_MODEL.LABELS
      })
    ) {
    }

    window.addEventListener("resize", this.showLabelSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.showLabelSize);
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      //this.handlePageChange(val);
      this.debounceGetOperators();
    },
    currentPage() {
      this.debounceGetOperators();
    }
  },
  methods: {
    copyText(elementId) {
      const textElement = document.getElementById(elementId);
      const range = document.createRange();
      range.selectNode(textElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand("copy");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("sharedLocation.LinkCopy"),
            text: "",
            icon: "InfoIcon",
            variant: "success"
          }
        });
      } catch (error) {
        console.error("Unable to copy text: ", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("sharedLocation.LinkCopyFailed"),
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }

      // Clear the selection
      window.getSelection().removeAllRanges();
    },
    debounceGetOperators() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getOperators();
      }, 300); // Adjust the delay as necessary
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    showLabelSize() {
      setTimeout(() => {
        const parentElement = document.querySelector(".parent");
        if (parentElement) {
          const parentWidth = parentElement.offsetWidth;
          this.defaultBadgeShow = Math.floor(parentWidth / 71);
        }
      }, 200);
    },
    formattedDates(date, format) {
      return this.formattedDateTime(date, format);
    },
    async getOperators() {
      try {
        this.show = true;
        // if (!this.filter || !this.filter.value) {
        //   if (this.$route.query.filter) {
        //     this.filter = {
        //       field: "all",
        //       operator: "ilike",
        //       value: this.$route.query.filter
        //     };
        //   } else {
        //     this.filter = {};
        //   }
        // }
        let requestData = {
          page: this.currentPage,
          page_size: parseInt(this.perPage),
          unit_id: this.unit.id,
          account_id: this.$route.query.account_id
            ? this.$route.query.account_id
            : localStorage.getItem("USER_ACCOUNT_ID")
          // filters:
          //   this.filter && Object.keys(this.filter).length > 0
          //     ? [this.filter]
          //     : []
        };

        let response = await new UnitService().getAllLinks(requestData);
        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.generate_date = this.formattedDates(
              u.ctime,
              constants.DATE_TIME_FORMAT
            );
            u.date = this.formattedDates(
              u.end_time,
              constants.DATE_TIME_FORMAT
            );
            u.start_time = this.formattedDates(
              u.start_time,
              constants.DATE_TIME_FORMAT
            );
            u.end_time = this.formattedDates(
              u.end_time,
              constants.DATE_TIME_FORMAT
            );
            u.id = u.id;
            u.link = u.link;

            return u;
          });
          this.totalOperators =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
      this.showLabelSize();
    },
    deleteOperator(props) {
      this.status = true;
      this.removedOperatorData = { name: props.name, id: props.id };
    },

    onClose() {
      this.debounceGetOperators();
      this.$bvModal.hide("modal-sm-remove");
    },
    async removeLink(props) {
      this.removedOperatorData = { name: "", id: "" };
      if (props.id) {
        try {
          this.onClose();
          let response = await new UnitService().deleteLink({
            id: props.id
          });

          if (response && response.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("sharedLocation.LinkRemovedSuccessfully"),
                icon: "EditIcon",
                variant: "success"
              }
            });

            this.debounceGetOperators();
          } else if (response && response.error && response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "EditIcon",
                variant: "error"
              }
            });
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        perPage: this.perPage.toString(),
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
    },
    redirectLink(redirectLink) {
      window.open(redirectLink, "_blank");
    }
  },
  computed: {}
};
</script>
<style lang="scss">
.alert-action-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: center;
}
.badge-group {
  height: 15px;
  padding: 1px 9px 1px 9px;
  font-size: 9px;
  line-height: 11px;
}

.Operator-table {
  /*width: calc(100% + 3rem);
  margin-left: -1.5rem;
  margin-right: -1.5rem;*/
  .table {
    td,
    th {
      padding: 0.72rem 1rem;
    }
    th.operator-code,
    td.operator-code {
      padding-left: 1rem;
    }
    .operator-group-width {
      // width: 175px;
      min-width: 175px;
      // max-width: 175px;
      text-align: left !important;
    }
    .operator-action-width {
      width: 135px;
      min-width: 135px;
      max-width: 135px;
    }
    .operator-code {
      width: 280px;
      text-align: left;
      min-width: 180px;
      max-width: 280px;
    }
  }
}
.operator-table-width {
  .table-responsive {
    max-height: calc(100vh - 270px) !important;
    overflow-y: auto;
  }

  .b-table-sticky-header {
    max-height: calc(100vh - 270px) !important;
    overflow-y: auto;
    .table {
      width: calc(100% - 18px) !important;
    }
  }
  .b-table-sticky-header {
    @supports (position: sticky) {
      .table.b-table > thead > tr > .table-b-table-default,
      .table.b-table > tbody > tr > .table-b-table-default,
      .table.b-table > tfoot > tr > .table-b-table-default {
        top: -1px !important;
      }
    }
  }
}
</style>
<style lang="scss">
.dark-layout .b-table-sticky-header {
  @supports (position: sticky) {
    .table.b-table > thead > tr > .table-b-table-default,
    .table.b-table > tbody > tr > .table-b-table-default,
    .table.b-table > tfoot > tr > .table-b-table-default {
      color: #d0d2d6 !important;
    }
  }
}
.invitation-link-hidden {
  display: block;
  max-width: 1px;
  position: absolute;
  height: 1px;
  opacity: 0;
  z-index: -9;
  right: 0px;
  overflow: hidden;
}
</style>
