var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('div',{class:{
    'share-link-details': true,
    'outer-info': true,
    'share-link': true
  }},[_c('div',{staticClass:"unit-info-card new-wall-comp Share-l p-0"},[_c('div',{staticClass:"trip-sm-card"},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('b-avatar',{attrs:{"variant":"light-primary","src":_vm.unit.icon,"pills":"","size":"27"}})],1),_c('div',{staticClass:"unit-title-col ml-1 w-100"},[_c('div',{staticClass:"name-mb unit-name-size d-flex justify-content-between"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.unit.name))])]),_c('div',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html.top",value:({
                    title: ("<div class='unit-name-ev-text'> <LocationStart class='mr-icon' /> <b> " + (_vm.$t(
                      'UnitTrack.StartTime'
                    )) + " : </b> " + (_vm.history.start_time) + "  </div> <div class='unit-name-ev-text'> <LocationEnd class='mr-icon' /> <b> " + (_vm.$t(
                      'UnitTrack.EndTime'
                    )) + " : </b> " + (_vm.history.end_time) + " </div>"),
                    customClass: 'tooltip-card'
                  }),expression:"{\n                    title: `<div class='unit-name-ev-text'> <LocationStart class='mr-icon' /> <b> ${$t(\n                      'UnitTrack.StartTime'\n                    )} : </b> ${\n                      history.start_time\n                    }  </div> <div class='unit-name-ev-text'> <LocationEnd class='mr-icon' /> <b> ${$t(\n                      'UnitTrack.EndTime'\n                    )} : </b> ${history.end_time} </div>`,\n                    customClass: 'tooltip-card'\n                  }",modifiers:{"html":true,"top":true}}],staticClass:"info-address-tool curserPointer"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"InfoIcon","size":"15"}})],1)])])])]),_c('div',{staticClass:"allocate-info top-15-px"}),(_vm.history.address)?_c('div',{staticClass:"unit-name-ev-text d-flex align-items-center",staticStyle:{"margin-bottom":"10px"}},[_c('div',[_c('LocationRun',{staticClass:"mr-icon"})],1),_c('div',{staticClass:"location-ml"},[_c('b-link',{staticClass:"map-link",attrs:{"href":_vm.history.address_link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.history.address ? _vm.history.address : "")+" ")])],1)]):_vm._e(),(_vm.history.last_sync_time)?_c('div',{staticClass:"d-flex justify-content-end main-sy"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.html.top",value:({
              title: ("<div class='unit-name-ev-text'> <LocationStart class='mr-icon' /> <b> " + (_vm.$t(
                'UnitTrack.ReportedAt'
              )) + " : </b> " + (_vm.history.message_time_tool) + "  </div> "),
              customClass: 'tooltip-card'
            }),expression:"{\n              title: `<div class='unit-name-ev-text'> <LocationStart class='mr-icon' /> <b> ${$t(\n                'UnitTrack.ReportedAt'\n              )} : </b> ${history.message_time_tool}  </div> `,\n              customClass: 'tooltip-card'\n            }",modifiers:{"html":true,"top":true}}],staticClass:"last-sync1"},[_vm._v(_vm._s(_vm.$t("sharedLocation.updatedAt"))+" "+_vm._s(_vm.history.last_sync_time)),_c('span',[_c('feather-icon',{staticClass:"text-primary",staticStyle:{"margin-left":"8px"},attrs:{"icon":"InfoIcon","size":"15"}})],1)])]):_vm._e()])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }