<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-4">
      <div class="table-responsive1" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="1"
          :columns="7"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        class="position-relative no-headers invitations-li-h"
        responsive
        v-if="!show"
        show-empty
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->
        <template #cell(user)="row">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                src="RA"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0">
              {{ row.item.account_name }}
            </h6>
            <small class="text-muted">{{ row.item.role_name }}</small>
          </b-media>
        </template>

        <template #cell(time)="row">
          <!-- {{ row.item.mtime }} -->
          {{ formatTime(row.item.mtime) }}
        </template>
        <template #cell(action)="row">
          <b-badge
            v-if="
              row.item.status === 'Accepted' ||
              row.item.status === 'Rejected' ||
              row.item.status === 'Expired'
            "
            pill
            :variant="
              row.item.status === 'Accepted'
                ? 'light-success'
                : row.item.status === 'Rejected'
                ? 'light-danger'
                : row.item.status === 'Expired'
                ? 'light-danger'
                : ''
            "
            class="mr-2"
          >
            {{ $t(`user.Status.${row.item.status}`) }}</b-badge
          >
          <div
            v-if="row.item.status === 'Pending'"
            class="d-flex justify-content-between active-status-btn"
          >
            <b-button
              variant="primary"
              class="mr-1 ml-1"
              size="sm"
              @click="joinWithUserInvite('ACCEPT', row.item.token)"
            >
              <feather-icon size="16" class="mr-0 mr-sm-50" />
              <span class="d-sm-inline">{{
                $t("userInvitations.Accept")
              }}</span>
            </b-button>
            <b-button
              variant="outline-danger"
              class="mr-1"
              size="sm"
              @click="joinWithUserInvite('DECLINE', row.item.token)"
            >
              <feather-icon size="16" class="mr-0 mr-sm-50" />
              <span class="d-sm-inline">{{
                $t("userInvitations.Reject")
              }}</span>
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalUsers > 0"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BTooltip,
  VBTooltip,
  BTable,
  BRow,
  BCol,
  BSkeletonTable,
  BPagination,
  BMedia,
  BAvatar,
  BBadge
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthService from "@/libs/api/auth-service";
import AccountService from "@/libs/api/account-service";
import moment from "moment";
import constants from "@/utils/constants";
import { filter } from "postcss-rtl/lib/affected-props";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BTable,
    BRow,
    BCol,
    BButton,
    BTooltip,
    VBTooltip,
    BSkeletonTable,
    BPagination,
    BMedia,
    BAvatar,
    BBadge
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: {
    searchFiled: Object,
    perPage: {
      type: Number,
      default: 10
    },
    selectedStatus: String
  },
  data() {
    return {
      tableColumns: [
        { key: "user", label: this.$t("historyTableColumns.user") },
        {
          key: "time",
          tdClass: "text-right",
          label: this.$t("historyTableColumns.time")
        },
        {
          key: "action",
          tdClass: "text-right max-size-210",
          label: this.$t("historyTableColumns.action")
        }
      ],
      totalUsers: 0,
      page: "user",
      user: {},
      roles: [],
      currentPage: 1,
      activeClass: "active",
      items: [],
      loggedUser: {},
      show: false,
      isSentInvite: false,
      selectedUser: {},
      isUpdateRole: false,
      deleteData: null,
      timeoutId: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;
      if (filter) {
        this.filter = filter;
      }
      if (page) {
        this.currentPage = parseInt(page);
        // console.log(page);
      }
      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.debounce(500);
    });
  },
  watch: {
    selectedStatus(val) {
      this.debounce();
    },
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.getUserInvitations();
    },

    currentPage(newPage) {
      this.getUserInvitations();
    },

    searchFiled(newVal, oldVal) {
      this.filter = newVal;
      this.debounce(500);
    }
  },

  methods: {
    debounce(func, delay) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getUserInvitations();
      }, 300);
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.getUserInvitations();
    },
    async getUserInvitations() {
      try {
        this.show = true;

        if (!this.filter || !this.filter.value) {
          this.filter = {};
          if (this.$route.query.filter) {
            this.filter = {
              field: "all",
              operator: "ilike",
              value: this.$route.query.filter
            };
          } else {
            this.filter = {};
          }
        }

        let filters =
          this.filter && Object.keys(this.filter).length > 0
            ? [this.filter]
            : [];
        if (this.selectedStatus == "accepted") {
          filters.push({
            field: "accepted",
            value: "true",
            operator: "="
          });
        } else if (this.selectedStatus == "rejected") {
          filters.push({
            field: "decline",
            value: "true",
            operator: "="
          });
        } else if (this.selectedStatus == "pending") {
          filters.push(
            {
              field: "expired",
              value: "now()",
              operator: ">="
            },
            {
              field: "accepted",
              value: "false",
              operator: "="
            },
            {
              field: "decline",
              value: "false",
              operator: "="
            }
          );
        }
        let response = await new AuthService().getUserInvitations({
          page: this.currentPage,
          page_size: parseInt(this.perPage),
          filters: filters,
          isExpired: this.selectedStatus == "expired" ? true : false
        });
        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.account_name);
            return u;
          });
          this.totalUsers = response.data.pagination.total_records || 0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    async joinWithUserInvite(action, token) {
      try {
        this.show = true;
        const body = {
          token: token,
          action: action
        };
        let response = await new AccountService().joinUserWithInvite(body);

        if (response && response.data) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t("userInvitations.Invitation")} ${
                action === "ACCEPT" ? "Accepted" : "Rejected"
              } `,
              text: `${this.$t("userInvitations.UserInvitations")} ${
                action === "ACCEPT" ? "accepted" : "rejected"
              } successfully`,
              icon: "InfoIcon",
              variant: "success"
            }
          });

          this.getUserInvitations();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    formatTime(time) {
      const currentTime = moment(time);
      const currentTimeAgo = moment.duration(moment().diff(currentTime));
      if (currentTimeAgo.asSeconds() < 60) {
        return `${currentTimeAgo.asSeconds().toFixed(0)} seconds ago`;
      } else if (currentTimeAgo.asMinutes() < 60) {
        return `${currentTimeAgo.asMinutes().toFixed(0)} minutes ago`;
      } else if (currentTimeAgo.asHours() < 24) {
        return `${currentTimeAgo.asHours().toFixed(0)} hours ago`;
      } else if (currentTimeAgo.asDays() < 7) {
        return `${currentTimeAgo.asDays().toFixed(0)} days ago`;
      } else {
        return this.formattedDateTime(currentTime, constants.DATE_TIME_FORMAT);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.max-size-210 {
  // max-width: 210px;
  min-width: 210px;
  width: 210px;
}
.invitations-li-h {
  height: calc(100vh - 300px);
  overflow-y: auto;
  .badge.mr-2 {
    min-width: 80px;
  }
}
@media only screen and (max-width: 667px) {
  .invitations-li-h {
    height: calc(100vh - 280px);
  }
}
</style>
