<template>
  <div>
    <div style="padding-top: 10px">
      <b-form-input
        @input="clickTxt()"
        v-model="filter"
        debounce="500"
        :placeholder="$t('device.search')"
        class="search-icon-input collapsed-icons d-inline-block search-bg"
      ></b-form-input>

      <div class="slect-account-title">{{ $t("alert.PopularAlerts") }}</div>
      <div class="event-card-row show-alert-pagination new-alerts-scroll">
        <div
          v-for="event in events"
          :key="event.id"
          class="event-card curserPointer"
          :class="
            event && selectedEvent && selectedEvent.id == event.id
              ? 'active-alerts '
              : ' normal-alerts'
          "
          @click="selectedEvents(event)"
        >
          <span style="display: none"> {{ event.id }}</span>
          <div class="text-row">
            <div class="media-body">
              <h6 class="mb-8 event-name">
                {{ event.title ? event.title : event.name ? event.name : "" }}
              </h6>
            </div>
          </div>
          <b-media class="align-items-start desc-data">
            <span
              :ref="event.id"
              :class="isReadMode(event.id) ? 'new-line' : ''"
            >
              {{ event.description }}
            </span>
            <b
              v-show="isReadMode(event.id) ? true : false"
              style="color: #7367f0"
              v-b-tooltip.click.top="event.description"
              >{{ $t("alert.ReadMore") }}</b
            >
          </b-media>
          <div class="parent">
            <Badge
              :showAllTag="true"
              :tags="event.sensors"
              :showTextInBadge="Number(7)"
              :event="event"
              :maxTagShow="Number(defaultBadgeShow)"
            ></Badge>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          &nbsp;
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalEvents"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  BMedia,
  BAvatar,
  BFormInput,
  VBTooltip,
  BBadge,
  BPopover,
  BRow,
  BCol,
  BPagination
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UnitService from "@/libs/api/unit-service";
import Badge from "@/layouts/components/Badge.vue";

export default {
  components: {
    BCard,
    BButton,
    BMedia,
    BAvatar,
    BFormInput,
    VBTooltip,
    BBadge,
    BPopover,
    BRow,
    BCol,
    Badge,
    BPagination
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: ["getSelectedEvent", "oldSelectedEvent"],
  data() {
    return {
      currentPage: 1,
      perPage: 16,
      totalEvents: 0,
      events: [],
      filter: "",
      maxSensorsShow: 1,
      selectedEvent: {},
      defaultBadgeShow: 2
    };
  },
  watch: {
    perPage() {
      if (!this.perPage) {
        this.perPage = 16;
      }
      this.getAllEvents();
    },
    currentPage() {
      this.getAllEvents();
    },
    filter() {
      this.getAllEvents();
    },
    $route(to, from) {
      this.getAllEvents();
    },
    oldSelectedEvent(val) {
      this.selectedEvent = val || {};
    }
  },
  mounted() {
    this.getAllEvents();
    window.addEventListener("resize", this.showLabelSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", (event) => {});
  },
  methods: {
    showLabelSize() {
      setTimeout(() => {
        const parentElement = document.querySelector(".parent");
        if (parentElement) {
          const parentWidth = parentElement.offsetWidth;
          this.defaultBadgeShow = Math.floor(parentWidth / 81);
        }
      }, 200);
    },
    isReadMode(e) {
      if (
        e &&
        this.$refs &&
        this.$refs[e] &&
        this.$refs[e][0] &&
        this.$refs[e][0].offsetHeight
      ) {
        return this.$refs[e][0].offsetHeight > 54 ? true : false;
      }
    },
    clickTxt() {
      this.getAllEvents();
    },

    async getAllEvents() {
      try {
        this.show = true;

        let response = await new UnitService().getEvents({
          page: this.currentPage,
          page_size: parseInt(this.perPage),
          filters: this.filter
            ? [
                {
                  value: this.filter,
                  operator: "ilike",
                  field: "name"
                }
              ]
            : []
        });

        if (response && response.data) {
          this.show = false;
          this.events = response.data.list || [];
          this.selectedEvent = this.oldSelectedEvent || {};
          this.totalEvents =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    selectedEvents(e) {
      this.selectedEvent = e;
      this.getSelectedEvent(e);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.slect-account-title {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  color: $headings-color;
  margin-top: 25px;
  margin-bottom: 33px;
}
.select-event-row.active-primary {
  padding: 13px 10px;
}

.select-event-row {
}
.event-card-row {
  display: flex;
  flex-wrap: wrap;
}
.event-card {
  width: 24%;
  min-height: 115px;
  margin-right: 1%;
  margin-bottom: 22px;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #b9b9c3;
  &:last-child {
    margin-right: 0%;
  }
  .desc-data {
    min-height: 60px;
  }
}
.media.desc-data {
  align-items: start !important;
}
.mb-8 {
  margin-bottom: 8px;
}
.event-sensors .media-body {
  display: flex;
  flex-wrap: wrap;
}
.line-height {
  line-height: 18px;
}
.new-line {
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media only screen and (max-width: 820px) {
  .event-card {
    width: 100%;
  }
  .slect-account-title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 22px;
  }
}
</style>
<style lang="scss" scoped>
.new-alerts-scroll {
  height: calc(100vh - 350px);
  overflow-y: auto;
  overflow-x: hidden;
}
@media only screen and (max-width: 991px) {
  .new-alerts-scroll {
    height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.show-alert-pagination {
  .event-card {
    max-height: 135px;
  }
}
</style>
