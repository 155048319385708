<template>
  <div
    v-if="
      (widget.id == undefined && !isEdit) || (widget.id !== undefined && isEdit)
    "
  >
    <b-modal
      :id="
        widget.id && widget.id !== 'undefined'
          ? `modal-sm-consumer-dash-${widget.id}`
          : `modal-sm-consumer-dash`
      "
      modal-class="no-header-modal modal-content-dash main-z-index"
      :size="widget && !widget.is_no_filter ? '1000' : 'lg'"
      centered
      :title="`${isEdit ? $t('Edit') : $t('Add')}` + ' ' + widget.title"
      header-class="custom-header-class"
      class="preview-modal-min-height"
      @hide="onHide"
      @shown="getData"
    >
      <span style="display: none">{{ widgetData }}</span>
      <b-row>
        <b-col md="5" lg="5" sm="12">
          <div
            class="customizer-section filter-margin d-flex justify-content-between align-items-center top-control"
          >
            <div v-if="widget && !widget.is_no_filter">
              <h4 class="dash-widget-title">
                {{ $t("ConsumerDashboard.Filters") }}
              </h4>
              <!-- <small>Customize &amp; Preview in Real Time</small> -->
            </div>

            <b-button
              v-if="widget && !widget.is_no_filter"
              style="margin-right: 8px"
              variant="primary"
              class="btn-tour-next"
              @click="apply(widgetTempData)"
              :disabled="widget && widget.is_no_filter ? false : isInValid"
            >
              <span style="padding-right: 4px"
                ><feather-icon icon="RefreshCwIcon" size="14"
              /></span>
              {{ $t("Apply") }}
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row
        style="padding: 0 10px 0px 15x"
        v-if="
          widget &&
          widget.use_unit_filters &&
          widget.use_unit_filters.multiple_units &&
          !widget.disabled_account_units
        "
      >
        <b-col class="global-filter">
          <b-form-checkbox
            v-model="is_global_filter"
            class="form-control-merge cursor-pointer"
            @input="globalFiltersApply"
            v-if="
              widget && !widget.is_no_filter && !widget.disabled_account_units
            "
          >
            {{ $t("Global filter") }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5" lg="5" sm="12" v-if="widget && !widget.is_no_filter">
          <DashboardFilter_
            class="dash-filter-height"
            :widget="widget"
            :isGlobalFilter="is_global_filter"
            :isEdit="isEdit"
            :globalFilters="globalFilters"
            @filterChanges="filterChanges"
            @dashboardChatData="getDashboardChatData"
          ></DashboardFilter_
        ></b-col>
        <b-col
          :md="widget && !widget.is_no_filter ? '7' : '12'"
          :lg="widget && !widget.is_no_filter ? '7' : '12'"
          sm="12"
        >
          <div ref="scrollChart"></div>
          <b-card>
            <vue-apex-charts
              v-if="
                widgetData &&
                widgetData &&
                widgetData.chartOptions &&
                !widgetData.is_dummy &&
                widget.component !== 'table'
              "
              :type="type"
              :options="widgetData.chartOptions"
              :series="widgetData.series"
            />
            <b-card-body
              v-if="widget.component == 'table' && !widgetData.is_dummy"
            >
              <div class="table-responsive reminders-th">
                <b-table
                  :items="widgetData.chartOptions"
                  :fields="widgetData.series"
                  :empty-text="$t('NoMatchingRecordsFound')"
                >
                  <template #cell(unit)="row">
                    <b-media class="align-item-center pt-0">
                      <template #aside>
                        <b-avatar size="50" :src="row.item.unit.icon" />
                      </template>
                      <h6 class="mb-0 text-wrap-w">
                        {{ row.item.unit.name }}
                      </h6>
                    </b-media>
                  </template>
                  <template #cell(services)="row">
                    <ul class="p-0 mt-0 mb-0 text-wrap-w">
                      <li
                        class="font-500 services"
                        v-for="(service, index) in row.item.services"
                        :key="index"
                      >
                        {{ service }}
                      </li>
                    </ul>
                  </template>
                </b-table>
              </div>
            </b-card-body>

            <b-overlay
              v-if="this.widgetData.is_dummy"
              :show="true"
              variant="transparent"
              :opacity="1"
              blur="8px"
              class="height-over disabled"
            >
              <template #overlay><div></div></template>
              <div>
                <span
                  v-if="widgetData && widgetData.is_no_data"
                  class="empty-text-child"
                  >{{
                    widget && widget.is_no_filter
                      ? $t("NODashboardDataMessage")
                      : $t("NODashboardDataMessage")
                  }}</span
                >
                <span
                  v-if="widgetData && !widgetData.is_no_data"
                  class="empty-text-child-1"
                  >{{ $t("dashboard.NoData") }}</span
                >

                <vue-apex-charts
                  v-if="chartOptions && widget.component != 'table'"
                  :type="type"
                  :options="chartOptions"
                  :series="seriesData"
                />
                <b-card-body
                  v-if="widget.component == 'table' && widgetData.is_dummy"
                >
                  <div class="table-responsive">
                    <b-table
                      :items="items"
                      :fields="fields"
                      :empty-text="$t('NoMatchingRecordsFound')"
                    >
                    </b-table>
                  </div>
                </b-card-body>
              </div>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <div
            class="customizer-section d-flex justify-content-center align-items-center top-control"
          >
            <b-button
              style="margin-right: 8px"
              variant="primary"
              class="btn-tour-next"
              @click="addWidget(widgetTempData)"
              :disabled="widget && widget.is_no_filter ? false : isInValid"
            >
              {{ widget.id ? $t("Update Widget") : $t("Add Widget") }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <Loader :show="show" />
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BModal,
  BCardText,
  BForm,
  BRow,
  BCol,
  BCardBody,
  BTable,
  BOverlay,
  BFormCheckbox,
  BMedia,
  BAvatar
} from "bootstrap-vue";
import constants from "@/utils/constants";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportService from "@/libs/api/report-service";
import DashboardChart from "./DashboardChart";
import WidgetDashBoard from "@/libs/dashboard/componets/index";
import DashboardFilter_ from "@/views/Dashboard/Consumer/DashboardFilter_.vue";
import validators from "@/utils/validators";
import dashboard from "@/libs/dashboard/componets/index";
import Loader from "@/layouts/components/Loader.vue";
import FuelUsageWidget from "@/libs/dashboard/FuelUsageWidget";
import { customTooltipFunction } from "@/libs/dashboard/utils/customTooltips";
import * as Utils from "@/libs/utils";
import EventBus from "@/EventBus";
export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BFormGroup,
    BModal,
    BCardText,
    BForm,
    BRow,
    BCol,
    DashboardFilter_,
    Loader,
    DashboardChart,
    VueApexCharts,
    BCardBody,
    BTable,
    BOverlay,
    BFormCheckbox,
    BMedia,
    BAvatar
  },
  data() {
    return {
      is_global_filter: false,
      widgetData: { is_dummy: true, is_no_data: false },
      widgetTempData: {},
      globalFilters: {
        // isAllUnitGroup: true,
        account_id: localStorage.getItem("USER_ACCOUNT_ID"),
        units: [],
        isAllUnit: true,
        unit_groups: []
      },
      hasErr: "",
      is_show_line: false,
      chartOptions: null,
      items: [],
      fields: [],
      seriesData: [],
      charData: {},
      type: "bar",
      show: false,
      isInValid: true,
      minValue: null,
      maxValue: null,
      series: [],
      apiCall: false,
      widgetReq: {}
    };
  },
  created() {
    EventBus.$on("add-global-filter", this.addGlobalFilter);
  },

  directives: {
    // "b-tooltip": VBTooltip,
    Ripple
  },
  props: ["widget", "selectedWidgetType", "isEdit", "id"],
  props: {
    widget: {
      type: Object
    },
    selectedWidgetType: {
      type: String
    },
    isEdit: {
      type: Boolean
    },
    id: {
      type: String
    },
    tempId: {
      type: Number
    }
  },
  mounted() {
    this.updateIsGlobalFilter();
  },
  watch: {
    selectedWidgetType(val) {
      if (val) {
        this.updateWidgetData();
        if (!this.widget.multiple_units) {
          this.updateIsGlobalFilter();
        }
      }
    },

    id(val) {
      if (val !== "1234") {
        this.updateWidgetData();
        if (!this.widget.multiple_units && this.isEdit) {
          this.updateIsGlobalFilter();
          this.apply({
            parameters: this.widget.use_unit_filters.parameters,
            ...this.widget
          });
        }
      }
    },

    tempId() {
      this.updateWidgetData();
    }
  },
  methods: {
    updateIsGlobalFilter() {
      if (this.isEdit && this.widget?.parameters?.is_global_filter) {
        this.is_global_filter = true;
        this.globalFiltersApply(true);
      } else {
        this.is_global_filter = false;
      }
    },
    getData() {
      this.updateIsGlobalFilter();
      if (this.isEdit) {
        const selectedWidget = WidgetDashBoard.find(
          (e) => e.name === this.widget.component
        );
        if (selectedWidget && selectedWidget.type) {
          if (this.widget.component === "table") {
            this.items = selectedWidget.chartOptions;
            this.fields = selectedWidget.series;
          } else {
            this.chartOptions = selectedWidget.chartOptions;
            this.seriesData = selectedWidget.series;
          }
          const isData = !selectedWidget.series.some((series) => {
            if (Array.isArray(series)) {
              return series.length > 0;
            } else if (
              typeof series === "object" &&
              series !== null &&
              "data" in series
            ) {
              return Array.isArray(series.data) && series.data.length > 0;
            } else {
              return Boolean(series); // To check if the series element is a non-zero number or truthy value
            }
          });

          if (isData && !selectedWidget.type === "donut") {
            this.widgetData.is_no_data = false;
            this.widgetData.is_dummy = false;
            this.widgetData.chartOptions = selectedWidget.chartOptions;
            this.widgetData.series = this.widget.series;
          } else if (selectedWidget.series.length) {
            const isData1 = selectedWidget.series.some((series) => {
              if (typeof series === "number") {
                return series > 0;
              }
              return false;
            });
            if (isData1) {
              this.widgetData.is_no_data = false;
              this.widgetData.is_dummy = false;
              this.widgetData.chartOptions = selectedWidget.chartOptions;
              this.widgetData.series = this.widget.series;
            }
          }
          this.type = selectedWidget.name;
        }
      }
    },
    updateWidgetData() {
      const selectedWidget = WidgetDashBoard.find(
        (e) => e.name === this.widget.component
      );
      if (selectedWidget && selectedWidget.type) {
        if (this.widget.component === "table") {
          this.items = selectedWidget.chartOptions;
          this.fields = selectedWidget.series;
        } else {
          this.chartOptions = selectedWidget.chartOptions;
          this.seriesData = selectedWidget.series;
        }
        this.type = selectedWidget.name;
      }
      if (this.widget.is_no_filter) {
        this.apply(this.widgetTempData);
      }
    },
    close() {
      this.$bvModal.hide("modal-sm-consumer-invitations");
    },
    handleError(error) {
      return validators.formattedErrorMsg(error);
    },
    onHide() {
      this.widgetData = JSON.parse(
        JSON.stringify({ is_dummy: true, is_no_data: false })
      );
      this.seriesData = [];
      this.$emit("closeSlider", true);
    },
    filterChanges(isFilter) {
      this.widgetData = JSON.parse(
        JSON.stringify({ is_dummy: true, is_no_data: false })
      );
    },
    getDashboardChatData(e) {
      if (this.widget.is_no_filter) {
        this.isInValid = false;
      } else {
        this.isInValid = e.is_in_valid;
      }

      this.widgetTempData = e;
    },
    globalFiltersApply(e) {
      if (e) {
        this.isInValid = false;
      } else {
        this.isInValid = true;
      }
    },
    async apply(e) {
      try {
        this.show = true;

        this.$nextTick(() => {
          const el = this.$refs.scrollChart;
          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
          }
        });
        let req = {};
        this.makeReq(e);
        req = this.widgetReq;

        if (this.is_global_filter) {
          const globalFilterReq = { ...this.globalFilters };
          globalFilterReq.units = {
            is_all: this.globalFilters.isAllUnit,
            selected: globalFilterReq.units
          };

          globalFilterReq.unit_groups = {
            selected: globalFilterReq.unit_groups
          };
          req = { ...req, ...globalFilterReq };
        }

        let charData = [];
        const selectedWidget = WidgetDashBoard.find(
          (e) => e.name === this.widget.component
        );
        if (selectedWidget && selectedWidget.type) {
          charData = JSON.parse(JSON.stringify(selectedWidget));
        }
        this.filterChanges();
        if (req && !req.account_id) {
          req.account_id = localStorage.getItem("USER_ACCOUNT_ID");
        }
        let response = await new ReportService().getWidgetsData(req);
        this.show = false;
        if (response && response.data && response.data.data) {
          let {
            series,
            categories,
            max_level,
            min_level,
            annotations,
            chartOptions,
            labels,
            colors
          } = response.data.data;

          let isNoData = !series.some((series) => {
            if (Array.isArray(series)) {
              return series.length > 0;
            } else if (
              typeof series === "object" &&
              series !== null &&
              "data" in series
            ) {
              return Array.isArray(series.data) && series.data.length > 0;
            } else {
              return Boolean(series); // To check if the series element is a non-zero number or truthy value
            }
          });

          this.show = false;

          const colorsData = [];
          this.apiCall = true;
          if (min_level && max_level) {
            this.minValue = Number(min_level) * 0.85;
            this.maxValue = Number(max_level) * 1.1;
          }
          // if (this.widget && !this.widget.is_no_filter) {
          // }
          if (
            (this.widget.widget_type === "FuelConsumptionWidget" ||
              this.widget.type === "FuelConsumptionWidget") &&
            this.widget.component === "line"
          ) {
            series = series.map((e, index) => {
              if (
                e.config &&
                e.config.is_show_line &&
                e.config.lines &&
                e.config.lines.length
              ) {
                e.config.lines = e.config.lines.map((l, index) => {
                  if (!l.color) {
                    l.color = Utils.COLOR[index + 10];
                  }
                  return l;
                });
              }
              if (e.color) {
                colorsData.push(e.color);
              } else {
                e.color = Utils.COLOR[index];
                colorsData.push(Utils.COLOR[index]);
              }

              return e;
            });
          } else if (
            this.widget.widget_type !== "FuelConsumptionWidget" &&
            this.widget.type !== "FuelConsumptionWidget" &&
            this.widget.component === "line"
          ) {
            charData.series = series;
            charData.chartOptions.xaxis.categories = categories;
            charData.chartOptions.colors = colors;
          }

          if (this.widget.component === "bar") {
            //  const colors = Utils.randomDarkChartColors(series.length);
            charData.series = series;
            charData.chartOptions.xaxis.categories = categories;
            charData.chartOptions.colors = colors;
            // if (updatedOptions[index].key === "top_alert") {
            //   charData.chartOptions.xaxis.title.text = this.$t("Count");
            // }
          } else if (this.widget.component === "donut") {
            // const colors = this.generateRandomColors(series.length);
            // charData.series = series;
            charData.series = JSON.parse(JSON.stringify(series));
            charData.chartOptions.labels = labels;
            // charData.chartOptions.colors = colors;
          }

          charData.series = JSON.parse(JSON.stringify([]));
          charData.series = JSON.parse(JSON.stringify(series));

          if (this.widget && this.widget.component == "table") {
            this.widgetData.chartOptions = chartOptions;
          }
          if (
            (this.widget.widget_type === "FuelConsumptionWidget" ||
              this.widget.type === "FuelConsumptionWidget") &&
            this.widget.component === "line"
          ) {
            charData.chartOptions.tooltip = {};
            // charData.chartOptions.colors = colorsData;
            if (
              this.widget &&
              !this.widget.is_no_filter &&
              this.widget.component == "line"
            ) {
              if (annotations) {
                charData.chartOptions.annotations = {
                  xaxis: annotations.xaxis
                };
              }

              charData.chartOptions.yaxis.max = function (max) {
                if (this.zoomed) {
                  return max;
                }
                return this.maxValue;
              };
              charData.chartOptions.tooltip = {
                x: {
                  show: true,
                  format: "dd MMM yy HH:mm:ss"
                },
                custom: customTooltipFunction
              };
              charData.chartOptions.yaxis.min = function (min) {
                if (this.zoomed) {
                  return min;
                }
                return this.minValue;
              };
              charData.chartOptions.chart.events = {
                beforeZoom: this.handleZoomedEvent,
                beforeResetZoom: this.handleZoomResetEvent,
                zoomed: this.handleZoomedEvent,

                updated: this.handleUpdateEvent
              };
            }
          }
          this.show = false;
          this.$nextTick(() => {
            if (this.widget && this.widget.component !== "table") {
              this.widgetData = JSON.parse(JSON.stringify(charData));
            }

            this.widgetData.is_no_data = isNoData;
            this.widgetData.is_dummy = isNoData;
          });
        } else if (response && response.error && response.error.message) {
          this.filterChanges();
          this.widgetData.is_no_data = true;
          this.show = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.filterChanges();
        this.widgetData.is_no_data = true;
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    makeReq(e) {
      let options = [];
      let units = { is_all: false, selected: [] };
      if (
        (e.units && e.units.is_all) ||
        (e.units && e.units.selected && e.units.selected.length)
      ) {
        units = e.units;
      }

      if (e.parameters && e.parameters.length) {
        options = e.parameters.map((e) => {
          const obj = {};
          obj.field = e.name;
          obj.value = e.value;

          if (
            e.name === "date_range_dash" &&
            e.range &&
            e.value &&
            [
              "Today",
              "Yesterday",
              "ThisWeek",
              "LastWeek",
              "ThisMonth",
              "LastMonth",
              "ThisYear"
            ].includes(e.value)
          ) {
            obj.value = this.getCustomRange(e.value);
            obj.range = e.value;
          } else if (
            e.name === "date_range_dash" &&
            !e.range &&
            e.value &&
            [
              "Today",
              "Yesterday",
              "ThisWeek",
              "LastWeek",
              "ThisMonth",
              "LastMonth",
              "ThisYear"
            ].includes(e.value)
          ) {
            obj.value = this.getCustomRange(e.value);
            obj.range = e.value;
          }
          return obj;
        });

        const unitSource = e.parameters.find((e) => e.name === "unit_source");
        if (options && options.length) {
          options = options.filter((e) => e.name !== "unit_source");
        }

        if (unitSource && unitSource.value && unitSource.value.length) {
          units.selected = unitSource.value;
        }
      }
      this.widgetReq = { widget_type: this.widget.type };
      if (!this.widget.is_no_filter) {
        this.widgetReq = {
          options,
          widget_type: this.widget.type
            ? this.widget.type
            : this.widget.widget_type,
          account_id: e.account_id
        };
        if (
          this.widget &&
          this.widget.use_unit_filters &&
          this.widget.use_unit_filters.multiple_units
        ) {
          if (Array.isArray(units.selected)) {
            this.widgetReq.units = units;
          } else if (typeof units.selected === "string") {
            this.widgetReq.units = {
              is_all: false,
              selected: [units.selected]
            };
          } else {
            this.widgetReq.units = {
              is_all: true,
              selected: []
            };
          }
        } else {
          this.widgetReq.unit_id = e.unit_id;
        }
        if (
          (e.unit_groups && e.unit_groups.is_all) ||
          (e.unit_groups &&
            e.unit_groups.selected &&
            e.unit_groups.selected.length)
        ) {
          this.widgetReq.unit_groups = e.unit_groups;
        }
      }
    },
    inValid(e) {
      if (this.widget.is_no_filter) {
        this.isInValid = false;
      } else {
        this.isInValid = e;
      }
    },
    handleZoomedEvent(chartContext, opt) {
      if (!opt.yaxis || !opt.yaxis) {
        opt.yaxis = chartContext.w.config.yaxis;
      }
      chartContext.w.config.series = this.widgetData.series;
      opt.yaxis.forEach((axis) => {
        axis.zoomed = true;

        const { max, min } = this.findMaxMinInRange(
          chartContext.w.config.series,
          opt.xaxis.min,
          opt.xaxis.max
        );
        if (max !== -Infinity) {
          axis.max = function () {
            return max * 1.1;
          };
        } else {
          const me = this;
          axis.max = function () {
            return me.maxValue;
          };
        }
        if (min !== Infinity) {
          axis.min = function () {
            return min * 0.9;
          };
        } else {
          const me = this;
          axis.min = function () {
            return me.minValue;
          };
        }
      });
      // Update the chart with new options
    },
    handleZoomResetEvent(chartContext, opt) {
      // Update y-axis max value to the zoomed max value
      opt.config.yaxis.forEach((axis) => {
        axis.zoomed = false;
      });
      this.handleZoomedEvent(chartContext, {
        yaxis: opt.config.yaxis,
        xaxis: opt.config.xaxis
      });
    },
    handleUpdateEvent(chartContext, opt) {
      // Update y-axis max value to the zoomed max value
      chartContext.w.config.series = this.widgetData.series;
      if (this.apiCall) {
        this.apiCall = false;
        opt.config.yaxis.forEach((axis) => {
          axis.zoomed = false;
        });

        this.handleZoomedEvent(chartContext, {
          yaxis: opt.config.yaxis,
          xaxis: opt.config.xaxis
        });
        // this.widgetData.Options.chartOptions.yaxis.max = this.maxValue;
        // this.widgetData.Options.chartOptions.yaxis.min = this.minValue;
      }
    },
    findMaxMinInRange(dataArray, startTimestamp, endTimestamp) {
      let maxValue = -Infinity;
      let minValue = Infinity;

      for (const dataSet of dataArray) {
        for (const [timestamp, value] of dataSet.data) {
          if (timestamp >= startTimestamp && timestamp <= endTimestamp) {
            maxValue = Math.max(maxValue, value);
            minValue = Math.min(minValue, value);
          }
        }
      }

      return { max: maxValue, min: minValue };
    },
    addGlobalFilter(filters, account_id) {
      this.globalFilters.units = (filters.unit && filters.unit.selected) || [];
      this.globalFilters.unit_groups =
        (filters.group && filters.group.selected) || [];
      this.globalFilters.isAllUnit = filters.isAllUnit === false ? false : true;
      this.globalFilters.account_id = account_id;
    },
    async addWidget(e) {
      try {
        this.makeReq(e);
        let saveWidgetReq = {};

        if (this.is_global_filter) {
          const globalFilterReq = { ...this.globalFilters };
          globalFilterReq.units = {
            is_all: this.globalFilters.isAllUnit,
            selected: globalFilterReq.units
          };

          globalFilterReq.unit_groups = {
            selected: globalFilterReq.unit_groups
          };
          saveWidgetReq = {
            ...saveWidgetReq,
            ...globalFilterReq,
            is_global_filter: this.is_global_filter
          };
        } else {
          if (
            this.widget &&
            this.widget.use_unit_filters &&
            this.widget.use_unit_filters.multiple_units
          ) {
            saveWidgetReq.units = this.widgetReq.units || {};
            saveWidgetReq.unit_groups = this.widgetReq.unit_groups || {};
          } else if (this.widgetReq && this.widgetReq.unit_id) {
            saveWidgetReq.unit_id = this.widgetReq.unit_id;
          }
        }
        this.widget.settings.mobile = {
          h: 16,
          maxH: 20,
          maxW: 12,
          minH: 12,
          minW: 12,
          w: 12,
          x: 0,
          y: 0
        };
        const reqs = {
          widget_type: this.widget.type
            ? this.widget.type
            : this.widget.widget_type,
          parameters: {
            ...saveWidgetReq,
            account_id: this.widgetReq.account_id,
            options: this.widgetReq.options || []
          },
          settings: {
            ...this.widget.settings
          }
        };
        this.show = true;
        let response = {};
        if (this.widget.id) {
          response = await new ReportService().updateWidgets({
            ...reqs,
            id: this.widget.id
          });
        } else {
          response = await new ReportService().createWidgets(reqs);
        }

        this.show = false;
        if (response && response.data) {
          this.show = false;
          this.filterChanges();
          EventBus.$emit("add-dashboard-widget", response.data);
          this.$bvModal.hide(
            this.widget.id
              ? `modal-sm-consumer-dash-${this.widget.id}`
              : "modal-sm-consumer-dash"
          );
          // this.$emit("closeSlider", true);
        } else if (response && response.error && response.error.message) {
          this.filterChanges();
          this.widgetData.is_no_data = true;
          this.show = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
      }
    }
  },
  beforeDestroy() {
    EventBus.$off("add-global-filter", this.addGlobalFilter);
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}
.modal-content-dash .modal-content {
  // height: 80vh !important;
}
.modal-content-dash {
  .unit-icon-card {
    min-height: calc(100% - 200px);
  }
}
.modal-1000 {
  max-width: 1300px;
  width: 1300px;
}
#modal-sm-consumer-dash___BV_modal_outer_ {
  z-index: 9999 !important;
}
.custom-header-class {
  .modal-title {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 28px !important;
  }
}
@media screen and (max-width: 991px) {
  .modal-1000 {
    max-width: 100%;
    width: 100%;
  }
  .custom-header-class {
    padding-right: 6px !important;
    .modal-title {
      font-size: 18px !important;
    }
  }
}

// .modal-content-dash {
//   .modal-body {
//     overflow-y: auto;
//     overflow-x: hidden;
//   }
// }
// .modal-content-dash {
//   .modal-content {
//     overflow-x: hidden;
//     width: calc(100% - 15px);
//     display: flex;
//   }
// }

// @media only screen and (min-width: 767px) {
//   .dash-filter-height {
//     max-height: calc(100vh - 300px);
//     min-height: calc(100vh - 300px);
//     overflow-y: auto;
//     overflow-x: hidden;
//   }
// }
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.dash-parent {
  max-width: 100%;
  margin-top: 0px !important;

  // max-height: 50vh;
}
@media only screen and (max-width: 767px) {
  .dash-parent {
    margin-top: 50px !important;
  }
}
.empty-text-parent {
}
.empty-text-child {
  font-weight: bold;
  color: #504747;
  opacity: 100 !important;
  position: absolute;
  top: 19%;
  padding: 10% 37%;
  z-index: 12;
  text-align: center;
}
.empty-text-child-1 {
  font-weight: bold;
  color: #504747;
  opacity: 100 !important;
  position: absolute;
  top: 19%;
  padding: 10% 9%;
  z-index: 12;
  text-align: center;
}
.dark-layout {
  .empty-text-child-1 {
    color: #ffffff;
  }
  .empty-text-child {
    color: #ffffff;
  }
  .apexcharts-menu.apexcharts-menu-open {
    background-color: var(--dark-bg) !important;
  }
}
.apexcharts-element-hidden {
  display: none;
}
.global-filter {
  margin-left: 14px;
  margin-bottom: 10px;
}
.preview-modal-min-height {
  min-height: 300px !important;
}

.filter-margin {
  margin-left: 10px !important ;
  margin-right: 10px !important ;
}
.modal-title {
  // Add Unit's Fuel Usage
  color: black;
  font-size: 28px;
  font-family: Inter;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}
.text-wrap-w {
  text-wrap: nowrap;
}
.reminders-th th {
  text-align: left !important;
}
</style>
