var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{staticClass:"custom-unit-dropdown",attrs:{"variant":"link","toggle-class":"p-0 unit-drop ","no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-left text-body",attrs:{"icon":"MoreVerticalIcon","size":"28"}})]},proxy:true}])},[(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.SHOW_EVENTS,
        subject: _vm.constants.PERMISSIONS_MODEL.UNITS
      })
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",attrs:{"to":{
      name: 'unit-events',
      query: { id: _vm.unit.id, account_id: _vm.$route.query.account_id }
    }}},[_c('span',{staticClass:"menu-icon-outer"},[_c('Events',{staticClass:"drop-down-icon"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Events")))])]):_vm._e(),(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.SHOW_HISTORY,
        subject: _vm.constants.PERMISSIONS_MODEL.UNITS
      })
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",attrs:{"to":{
      name: 'unit-history',
      query: { id: _vm.unit.id, account_id: _vm.$route.query.account_id }
    }}},[_c('span',{staticClass:"menu-icon-outer"},[_c('History',{staticClass:"drop-down-icon"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("unit.History")))])]):_vm._e(),(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.SHOW_TRIPS,
        subject: _vm.constants.PERMISSIONS_MODEL.UNITS
      })
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",attrs:{"to":{
      name: 'unit-trips',
      query: { id: _vm.unit.id, account_id: _vm.$route.query.account_id }
    }}},[_c('span',{staticClass:"menu-icon-outer"},[_c('Trips',{staticClass:"drop-down-icon trips"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Trips")))])]):_vm._e(),(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.READ,
        subject: _vm.constants.PERMISSIONS_MODEL.SERVICE_REMINDERS
      })
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",attrs:{"to":{
      name: 'maintenance-reminder-list',
      query: { unit: _vm.unit.id, account_id: _vm.$route.query.account_id }
    }}},[_c('span',{staticClass:"menu-icon-outer"},[_c('Services',{staticClass:"drop-down-icon trips"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Services")))])]):_vm._e(),(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.SHOW_ACTIVITY,
        subject: _vm.constants.PERMISSIONS_MODEL.UNITS
      })
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",attrs:{"to":{
      name: 'unit-activity',
      query: { id: _vm.unit.id, account_id: _vm.$route.query.account_id }
    }}},[_c('span',{staticClass:"menu-icon-outer"},[_c('Activity',{staticClass:"drop-down-icon"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Activity")))])]):_vm._e(),(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.SHOW_VIDEOS,
        subject: _vm.constants.PERMISSIONS_MODEL.UNITS
      })
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",attrs:{"to":{
      name: 'unit-videos',
      query: { id: _vm.unit.id, account_id: _vm.$route.query.account_id }
    }}},[_c('span',{staticClass:"menu-icon-outer"},[_c('Videos',{staticClass:"drop-down-icon"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Videos")))])]):_vm._e(),(
      _vm.isAllocatedUnit &&
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
        subject: _vm.constants.PERMISSIONS_MODEL.UNITS
      }) &&
      !_vm.unit.archived
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",on:{"click":function($event){return _vm.allocationUnit(_vm.unit)}}},[_c('span',{staticClass:"menu-icon-outer"},[_c('Allocation',{staticClass:"drop-down-icon"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Allocation")))])]):_vm._e(),_c('b-dropdown-item',{staticClass:"unit-menu-item",on:{"click":function($event){return _vm.sharedLocation(Object.assign({}, _vm.unit))}}},[_c('span',{staticClass:"menu-icon-outer"},[_c('LiveShare',{})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("sharedLocation.location")))])]),(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
        subject: _vm.constants.PERMISSIONS_MODEL.UNITS
      }) && _vm.editUnit
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",attrs:{"to":{ name: 'edit-unit', params: { id: _vm.unit.id } }}},[_c('span',{staticClass:"menu-icon-outer"},[_c('Edit',{staticClass:"drop-down-icon edit"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Edit")))])]):_vm._e(),(
      _vm.checkAbility({
        action: _vm.constants.PERMISSIONS_ACTION.DELETE,
        subject: _vm.constants.PERMISSIONS_MODEL.UNITS
      }) && _vm.editUnit
    )?_c('b-dropdown-item',{staticClass:"unit-menu-item",on:{"click":function($event){return _vm.deleteUnit({ id: _vm.unit.id, name: _vm.unit.name })}}},[_c('span',{staticClass:"menu-icon-outer"},[_c('Delete',{staticClass:"drop-down-icon delete"})],1),_c('span',{staticClass:"align-middle ml-51"},[_vm._v(_vm._s(_vm.$t("UnitTrack.Delete")))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }