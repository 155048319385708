<template>
  <div>
    <b-card class="under-line-title">
      <div>
        <b-row
          class="p-b-r-f-1 align-items-center mb-1"
          v-if="$route.name == 'alert-view'"
        >
          <b-col sm="6" cols="6" class="p-0">
            <div class="card-title mb-0">{{ $t("alert.AlertView") }}</div>
          </b-col>
          <b-col sm="6" cols="6" class="d-flex justify-content-end p-0">
            <span
              v-b-tooltip.hover.v-primary
              :title="$t('tooTip.update')"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.ALERTS
                }) && isEditable
              "
            >
              <feather-icon
                @click="redirectToEdit"
                icon="EditIcon"
                class="text-primary action-icon"
                size="20"
                style="position: relative; cursor: pointer"
              ></feather-icon>
            </span>
            <b-button
              variant="outline-primary"
              size="sm"
              v-show="$route.name === 'alert-view'"
              @click="redirectToAlertList"
              class="back-update ml-1 v2-back"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <validation-observer ref="refAlert">
        <b-form>
          <div class="alert-create-step-scroll">
            <div class="alert-view mb-2 primary-light-alert alert-full-size">
              <app-collapse>
                <app-collapse-item
                  :title="
                    eventData && eventData.title
                      ? eventData.title
                      : eventData.name
                      ? eventData.name
                      : ''
                  "
                  :isVisible="false"
                >
                  <div class="text-primary alert-desc">
                    {{ eventData && eventData.description }}
                  </div>
                </app-collapse-item>
              </app-collapse>
            </div>
            <b-row>
              <b-col sm="6">
                <b-form-group :label="$t('account.Name')">
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    vid="Name"
                    :rules="{
                      required: true,
                      min: constants.MIN_ACC_NAME,
                      max: constants.MAX_ACC_NAME
                    }"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="name"
                        ref="name_input"
                        v-model.lazy="alert.name"
                        :state="errors.length > 0 ? false : null"
                        :disabled="!isEditable"
                        :class="{
                          'is-invalid': errors.length > 0,
                          'is-valid': errors.length === 0 && alert.name
                        }"
                        name="name"
                        @input="getUpdatedAlertInfo($refs.refAlert, alert)"
                        :placeholder="$t('alert.placeholderName')"
                      />
                    </b-input-group>
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="6" class="search-disabled">
                <b-form-group :label="$t('alert.Status')">
                  <v-select
                    v-model="alert.status"
                    :clearable="false"
                    :disabled="!isEditable"
                    :class="!isEditable ? 'disabled' : ''"
                    :options="statusList"
                    @input="getUpdatedAlertInfo($refs.refAlert, alert)"
                    :reduce="(option) => option.value"
                    label="name"
                  />
                </b-form-group>
              </b-col>

              <b-col
                sm="6"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.LABELS
                  })
                "
              >
                <b-form-group :label="$t('alert.Labels')">
                  <TagMultiSelect
                    v-model="alert.labels"
                    :class="!isEditable ? 'disabled' : ''"
                    @input="getUpdatedAlertInfo($refs.refAlert, alert)"
                  ></TagMultiSelect>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group :label="$t('alert.Description')">
                  <b-form-input
                    v-model="alert.description"
                    type="text"
                    :disabled="!isEditable"
                    :class="!isEditable ? 'disabled' : ''"
                    @input="getUpdatedAlertInfo($refs.refAlert, alert)"
                    :placeholder="$t('alert.Description')"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <b-form-checkbox
                    :disabled="!isEditable"
                    :class="!isEditable ? 'disabled' : ''"
                    v-model="alert.allow_child"
                    @input="getUpdatedAlertInfo($refs.refAlert, alert)"
                    class="form-control-merge cursor-pointer"
                  >
                    {{ $t("alert.AllowChild") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-if="
                $route.name === 'alert-view' || $route.name === 'alert-edit'
              "
            >
              <AlertConfigure
                v-if="isMap === 'config'"
                :eventData="newEvent"
                :alertInfo="alertInfo"
                :getVariableConfigInfo="getVariableConfigInfo"
                :isEditable="isEditable"
              ></AlertConfigure>

              <AlertMapConfigure
                v-if="isMap === 'map'"
                :eventData="newEvent"
                :isEditable="isEditable"
                :alertInfo="alertInfo"
                :getVariableConfigInfo="getVariableConfigInfo"
              ></AlertMapConfigure>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BTooltip,
  VBTooltip,
  BFormGroup,
  BRow,
  BInputGroup,
  BForm,
  BCol,
  BFormInput,
  BFormCheckbox
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";

import constants from "@/utils/constants";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";
import AlertMapConfigure from "./AlertMapConfigure.vue";
import AlertConfigure from "./AlertConfigure.vue";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BTooltip,
    VBTooltip,
    AppCollapse,
    AppCollapseItem,
    BForm,
    ValidationProvider,
    BInputGroup,
    ValidationObserver,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormInput,
    AlertConfigure,
    TagMultiSelect,
    AlertMapConfigure,
    BFormCheckbox
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: [
    "eventData",
    "getUpdatedAlertInfo",
    "alertInfo",
    "getVariableConfigInfo",
    "isViewAlert"
  ],
  data() {
    return {
      alert: {
        event_id: "",
        name: "alert",
        status: true,
        variables: [],
        description: "",
        labels: [],
        allow_child: false
      },
      isEditable: true,
      newEvent: {},
      isMap: "",
      statusList: [
        { name: this.$t("alert.Active"), value: true },
        { name: this.$t("alert.InActive"), value: false }
      ]
    };
  },
  watch: {
    alertInfo(newVal, old) {
      this.alert = newVal;
    },
    eventData(val) {
      this.newEvent = val;
      this.getAlertInfo();
    },
    $route(to, from) {
      this.getAlertInfo();
    },
    isEditable(val) {
      this.isEditable = val;
    },
    "alert.name"(val) {
      this.$refs.name_input.focus();
      this.getUpdatedAlertInfo(this.$refs.refAlert, this.alert);
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.alert.name = this.alert.name + " clone";
      this.alert.id = null;
    }
    this.getAlertInfo();
  },
  methods: {
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    redirectToAlertList() {
      this.$router.go(-1);
    },
    getAlertInfo() {
      this.newEvent = this.eventData;
      this.alert = JSON.parse(JSON.stringify(this.alertInfo));
      this.getUpdatedAlertInfo(this.$refs.refAlert, this.alertInfo);
      if (
        this.isViewAlert &&
        (this.$route.name === "alert-view" || this.$route.name === "alert-edit")
      ) {
        this.isEditable = false;
      }
      if (
        this.$route.name == "alert-view" &&
        this.$route.params &&
        this.$route.params.id
      ) {
        this.isEditable = false;
      }
      const me = this;
      setTimeout(() => {
        const mapComponent =
          me.newEvent &&
          me.newEvent.variables &&
          me.newEvent.variables.length &&
          me.newEvent.variables.find((e) => e.component == "MAP");
        me.isMap = mapComponent && mapComponent.component ? "map" : "config";
      }, 1000);
    },
    redirectToEdit() {
      this.$router.push({
        name: "alert-edit",
        params: { id: this.$route.params.id }
      });
      this.isEditable = true;
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.search-disabled {
  .vs--disabled.disabled {
    border-radius: 6px;
  }
}
.dark-layout {
  .tag-select.disabled {
    .vs--searchable {
      .vs__dropdown-toggle {
        background: var(--disabled) !important;
      }
    }
  }
  .vs__actions svg {
    fill: none;
  }
}
.alert-desc {
  padding: 0 15px 10px 15px;
}
</style>
<style lang="scss" scoped>
.alert-create-step-scroll {
  max-height: calc(100vh - 290px);
  min-height: calc(100vh - 290px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
  .card-body {
    padding: 0 !important;
  }
}
</style>
