<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2-br-bottm--2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            ><h4 class="card-title">{{ $t("rule.Eventslist") }}</h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <div class="rule-back_button">
              <span id="addEvent">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-tooltip.hover.v-primary
                  :title="$t('tooTip.addEvent')"
                  variant="primary"
                  class="btn-icon btn-margin-10"
                  @click="routeToRuleConfigurations()"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </span>
              <b-button
                variant="outline-primary"
                size="sm"
                class="v2-back"
                @click="routeToEditUnit()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="m-2 m-2-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="search-label">{{ $t("unit.Entries") }}</label>
            <v-select
              :clearable="false"
              id="vue-select"
              class="per-page-selector d-inline-block fix-83"
              v-model="perPage"
              :options="option"
            />
          </b-col>
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          ></b-col>
          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <label class="search-label">{{ $t("rule.Search") }}</label>
              <b-form-input
                class="d-inline-block"
                :placeholder="$t('rule.SearchPlaceholder')"
                v-model="filter"
                debounce="1000"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-table
          class="position-relative unit-list rule-table header-left-align"
          responsive
          show-empty
          align-v="end"
          :items="items"
          :fields="tableColumns"
        >
          <!-- Column: Unit -->

          <template #cell(Events)="row">{{ row.item.rule_name }} </template>
          <template #cell(Enabled)="row">
            <div>
              <b-form-checkbox
                v-model="row.item.enable"
                class="custom-control-success"
                name="check-button"
                @change="toggleUserConfigurations(row)"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </template>
          <template #cell(ACTIONS)="row">
            <div class="action-outer">
              <div class="text-left">
                <span :id="row.item.up_tool">
                  <feather-icon
                    v-b-tooltip.hover.v-primary
                    :title="$t('tooTip.update')"
                    icon="EditIcon"
                    class="text-primary action-icon pointer"
                    size="18"
                    @click="editEventConfigurations(row)"
                  />
                </span>
                <span :id="row.item.del_tool">
                  <feather-icon
                    v-b-tooltip.hover.v-primary
                    :title="$t('tooTip.delete')"
                    icon="Trash2Icon"
                    class="text-danger action-icon pointer"
                    size="18"
                    v-b-modal.modal-sm-remove
                    @click="remove(row.item)"
                  />
                </span>
              </div>
            </div>
          </template>
        </b-table>
      </div>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDeviceRules"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AreYouSureModal
      :data="rule"
      :onClose="onClose"
      :removedUser="removeUserConfigurations"
    />
    <Loader :show="showLoading" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BModal,
  BForm,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import UnitService from "@/libs/api/unit-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import Ripple from "vue-ripple-directive";
import RuleService from "@/libs/api/rule-service";
import Loader from "@/layouts/components/Loader.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    BModal,
    BForm,
    AreYouSureModal,
    BFormCheckbox,
    Loader,
    VBTooltip
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      currentPage: 1,
      totalDeviceRules: 0,
      showLoading: false,
      deviceId: null,
      unitId: null,
      perPage: 5,
      tableColumns: [
        {
          key: "Events",
          tdClass: "",
          label: this.$t("role.tableColumns.Events")
        },
        {
          key: "Enabled",
          tdClass: "role-group-width",
          label: this.$t("role.tableColumns.Enabled")
        },
        {
          key: "ACTIONS",
          tdClass: "role-group-width",
          label: this.$t("role.tableColumns.ACTIONS")
        }
      ],
      option: ["5", "10", "20", "30"],
      items: [],
      rule: null,
      filter: ""
    };
  },
  watch: {
    filter() {
      this.getDeviceRules();
    },
    currentPage() {
      this.getDeviceRules();
    },
    perPage() {
      this.getDeviceRules();
    }
  },
  mounted() {
    this.deviceId = this.$route.query.deviceId;
    this.unitId = this.$route.query.unitId;
    this.getDeviceRules();
  },
  methods: {
    routeToRuleConfigurations() {
      this.$router.push({
        name: "rules",
        query: { deviceId: this.deviceId, unitId: this.unitId }
      });
    },
    routeToEditUnit() {
      this.$router.push({
        name: "edit-unit",
        params: { id: this.unitId }
      });
    },
    remove(ruleData) {
      this.rule = { ...ruleData, name: ruleData.rule_name };
      this.$bvModal.show("modal-sm-remove");
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
    },
    async getDeviceRules() {
      try {
        this.showLoading = true;
        let response = await new RuleService().getDeviceRules({
          unit_id: this.unitId,
          id: this.deviceId,
          filter: this.filter,
          page: this.currentPage,
          page_size: Number(this.perPage)
        });
        if (response && response.data) {
          this.items = response.data.device_rules;
          this.totalDeviceRules = response.data.pagination.total_records;
          if (this.filter == "" && this.totalDeviceRules == 0) {
            this.$router.push({
              name: "rules",
              query: { deviceId: this.deviceId, unitId: this.unitId }
            });
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async toggleUserConfigurations(row) {
      try {
        this.showLoading = true;
        let response = await new RuleService().toggleUserConfigurations({
          id: row.item.id,
          enable: row.item.enable
        });
        if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async removeUserConfigurations(row) {
      try {
        this.showLoading = true;
        let response = await new RuleService().removeUserConfigurations({
          id: row.id
        });
        if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
        this.$bvModal.hide("modal-sm-remove");
        this.getDeviceRules({ id: row.device_id });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    editEventConfigurations(rule) {
      this.$router.push({
        name: "edit-event",
        query: {
          id: rule.item.id,
          unitId: this.unitId,
          deviceId: this.deviceId
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.rule-back_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: $px_10;
  .btn-margin-10 {
    margin-right: $px_10;
    margin-bottom: 0;
  }
}
.action-outer {
  display: flex;
  align-items: center;
}
.action-outer .text-left {
  margin-left: 30px;
}
.leaflet-pane leaflet-tooltip-pane {
  max-width: 100px !important;
}
.leaflet-pane.leaflet-tooltip-pane {
  max-width: 100px !important;
}
</style>
