<template>
  <div
    v-if="isVisible"
    :class="{
      'share-link-details': true,
      'outer-info': true,
      'share-link': true
    }"
  >
    <div class="unit-info-card new-wall-comp Share-l p-0">
      <!-- Close Button inside unit-info-card -->
      <!-- <div
        class="w-100 d-flex justify-content-between card-info-title DDRTY align-items-baseline"
      >
        <h4 class="close-btn">Unit Details</h4>
        <button
          @click="isVisible = false"
          class="close-btn mt-0 pt-0 pr-0 close"
        >
          ✕
        </button>
      </div> -->

      <div class="trip-sm-card">
        <b-card>
          <div class="d-flex align-items-center">
            <div>
              <b-avatar
                variant="light-primary"
                :src="unit.icon"
                pills
                size="27"
              />
            </div>
            <div class="unit-title-col ml-1 w-100">
              <div
                class="name-mb unit-name-size d-flex justify-content-between"
              >
                <div>
                  <b>{{ unit.name }}</b>
                </div>
                <div>
                  <span
                    class="info-address-tool curserPointer"
                    v-b-tooltip.html.top="{
                      title: `<div class='unit-name-ev-text'> <LocationStart class='mr-icon' /> <b> ${$t(
                        'UnitTrack.StartTime'
                      )} : </b> ${
                        history.start_time
                      }  </div> <div class='unit-name-ev-text'> <LocationEnd class='mr-icon' /> <b> ${$t(
                        'UnitTrack.EndTime'
                      )} : </b> ${history.end_time} </div>`,
                      customClass: 'tooltip-card'
                    }"
                  >
                    <feather-icon
                      class="text-primary"
                      icon="InfoIcon"
                      size="15"
                    />
                  </span>
                </div>
              </div>
              <!-- <span>Experin in <b>2 min</b></span> -->
            </div>
          </div>
          <div class="allocate-info top-15-px"></div>
          <div
            style="margin-bottom: 10px"
            class="unit-name-ev-text d-flex align-items-center"
            v-if="history.address"
          >
            <div>
              <LocationRun class="mr-icon" />
            </div>

            <div class="location-ml">
              <b-link
                :href="history.address_link"
                class="map-link"
                target="_blank"
              >
                {{ history.address ? history.address : "" }}
              </b-link>
            </div>
          </div>
          <div
            v-if="history.last_sync_time"
            class="d-flex justify-content-end main-sy"
          >
            <span
              class="last-sync1"
              v-b-tooltip.html.top="{
                title: `<div class='unit-name-ev-text'> <LocationStart class='mr-icon' /> <b> ${$t(
                  'UnitTrack.ReportedAt'
                )} : </b> ${history.message_time_tool}  </div> `,
                customClass: 'tooltip-card'
              }"
              >{{ $t("sharedLocation.updatedAt") }} {{ history.last_sync_time
              }}<span
                ><feather-icon
                  style="margin-left: 8px"
                  class="text-primary"
                  icon="InfoIcon"
                  size="15" /></span
            ></span>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  VBTooltip,
  BTooltip,
  BLink,
  BButton
} from "bootstrap-vue";
import Badge from "@/layouts/components/Badge.vue";
import UnitPlayBack from "./UnitPlayBack";
import CallIcon from "@/assets/images/unit-icon/call.svg";
import AddressIcon from "@/assets/images/unit-icon/address-icon.svg";
import constants from "@/utils/constants";
import LocationStart from "@/assets/images/unit-icon/menu/location-start.svg";
import LocationEnd from "@/assets/images/unit-icon/menu/location-end.svg";
import LocationRun from "@/assets/images/unit-icon/menu/location-run.svg";
export default {
  components: {
    BAvatar,
    Badge,
    VBTooltip,
    BLink,
    BTooltip,
    CallIcon,
    AddressIcon,
    BButton,
    UnitPlayBack,
    LocationStart,
    LocationEnd,
    BCard,
    LocationRun
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      isHideMenu: false,
      isOnlyPlayBack: true,
      isVisible: true
    };
  },

  props: ["unit", "history", "account"],
  mounted() {},
  computed: {},
  methods: {
    clearUnitTripInfo() {},
    DateTimeConvert(date) {
      if (!date) {
        return "";
      }
      return this.formattedDateTime(
        date,
        constants.DATE_TIME_FORMAT,
        this.account.settings
      );
    }
  }
};
</script>

<style lang="scss">
.new-wall-comp {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}
.event-card-fe {
  position: relative;
  margin: 2px 5px;
  width: auto;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #eceded;
  background: var(--white);
  box-sizing: border-box;
  display: inline-block;
  // min-height: 65px;
  min-width: calc(100% / 3);
  .event-info {
    font-size: 13px;
    font-weight: 600;
    padding-right: 10px;
    padding-left: 10px;
    white-space: nowrap;
  }
  .ev-d-flex {
    display: flex;
    align-items: center;
  }
}
.close-btn {
  background-color: transparent !important;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.share-link-details {
  z-index: 1000;
  width: 100%;
  right: 0 !important;
  left: 0 !important; // .outer-span {
  //   position: relative;
  //   // .info-icon-overlay {
  //   //   position: absolute;
  //   //   bottom: 15px;
  //   //   right: 5px;
  //   //   height: 20px;
  //   //   // background-color: var(--white);
  //   //   cursor: pointer;
  //   // }
  // }
  .new-wall-comp {
    .cust-card-unit {
      margin-right: 15px;
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .unit-info-card {
    width: 100%;
    max-width: 500px;
    background-color: var(--white);
    padding: 15px;
    border-radius: 4px 4px 4px 4px;
    max-height: 285px;
    overflow-y: auto;
    box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
    display: flex;
    flex-wrap: wrap;
    .b-avatar {
      .b-avatar-img {
        padding: 4px;
        img {
          object-fit: contain;
        }
      }
    }
    .cust-card-unit {
      display: inline-flex;
      padding: 15px;
      margin-right: 10px;
      min-width: 250px;
      max-width: 250px;
      min-height: 53px;

      .unit-name-ev-block {
        margin-left: 17px;
        padding-left: 15px;
        width: 100%;
        .media.event-Tag {
          padding-top: 5px;
        }
        .unit-name-ev-text {
          margin-left: 17px;
          font-size: 16px;
          font-weight: 500;
          color: var(--unit-bg-dark);
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          max-width: 87%;
          white-space: nowrap;
        }
        .unit-light-ev-text {
          font-size: 14px;
          min-width: 70%;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          max-width: 255px;
          white-space: nowrap;
        }
      }
      .card-data-ev {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        justify-content: space-between;
      }
    }

    .call-icon,
    .address-icon {
      margin-right: 10px;
      path {
        fill: var(--primary);
      }
    }
  }
  .outer-info .close {
    position: absolute;
    right: -10px;
    top: -10px;
    height: 30px;
    width: 30px;
    background-color: var(--white) !important;
    box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
    z-index: 999999;
    padding: 0;
    border-radius: 6px;
    opacity: 1;
  }
}
.dark-layout {
  .share-link-details {
    .unit-info-card {
      background-color: var(--unit-bg-dark);
      .cust-card-unit {
        .unit-name-ev-block {
          .unit-name-ev-text {
            color: var(--light);
          }
        }
      }
      .shift-time {
        color: var(--light);
      }
    }
  }
  .event-card-fe {
    background-color: var(--dark46);
  }

  .outer-info .close {
    background-color: var(--dark46) !important;
    color: var(--white);
  }
}
.outer-info.share-link:after {
  display: none;
}
.trip-sm-card {
  width: 100%;
  margin: 1%;
  .event-card-fe {
    width: 100%;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  }
  .card {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 767px) {
  // .trip-card {
  //   max-height: 80px !important;
  // }
  .share-link.outer-info {
    top: auto !important;
    height: auto !important;
  }
  .share-link-details {
    .trip-sm-card {
      width: 90%;
      // margin-bottom: 8px;
      .event-card-fe {
        width: 100%;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      }
      .card-body {
        padding: 0.8rem;
      }
    }
    // .trip-sm-card:nth-child(odd) {
    //   padding-left: 5px;
    // }
    // .trip-sm-card:nth-child(even) {
    //   padding-right: 5px;
    // }
  }
  .tooltip-card {
    .tooltip-inner {
      min-width: 230px;
    }
  }
  .share-link-details {
    .unit-info-card {
      max-height: 100%;
      width: 100% !important;
      .cust-card-unit,
      .cust-card-unit.last-cust-card-unit {
        min-width: 100%;
        max-width: 100%;
        min-height: 50px;
        margin-right: 0px;
      }
      .address-block {
        .address-txt {
          max-height: none;
        }
      }
    }
  }
}
.no-wp-txt {
  white-space: nowrap;
  font-size: 11px;
}
.unit-info-main {
  border-radius: 8px;
  background-color: var(--white);
  padding: 15px;
  min-height: 122px;
  box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  .v-select ul {
    margin-top: -80px !important;
  }
}
.Share-l {
  width: fit-content;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.mr-icon {
  margin-right: 6px;
}
.close-btn {
  display: none;
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .share-link-details .unit-info-card {
    max-height: 100%;
    max-width: 100% !important;
    margin: 0px 12px 0px 12px !important;
  }
}
@media screen and (max-width: 767px) {
  .share-link-details .trip-sm-card .event-card-fe {
    width: 100%;
  }
  .close-btn {
    display: block;
  }
  .share-link-details .trip-sm-card {
    width: 100%;
    margin-bottom: 2%;
  }
  // .share-link-details .trip-sm-card:nth-child(odd) {
  //   padding-left: 0px !important;
  // }
  // .share-link-details .trip-sm-card:nth-child(even) {
  //   padding-right: 0px !important;
  // }
  .event-card-fe {
    margin: 0 !important;
  }
  [dir] .outer-info {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
.card-info-title {
  margin-left: 1px;
  margin-right: 3px;
}
</style>
<style lang="scss">
.dark-layout .DDRTY .close {
  background-color: transparent !important;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.unit-title-col {
  display: flex;
  flex-direction: column;
}
// .name-mb {
//   margin-bottom: 5px;
// }
.location-ml {
  margin-left: 7px;
  font-size: 12px;
}
.unit-name-size {
  font-size: 12px;
}
.last-sync1 {
  font-size: 10px;
  position: absolute;
  margin-top: -9px;
}
.map-link {
  max-width: calc(100% - 32px);
}
</style>
