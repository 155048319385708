<template>
  <div class="share-location-map">
    <l-map
      ref="unitMap"
      :zoom="zoom"
      :center="latLng"
      class="map-size"
      @ready="initializeMap"
    >
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <l-polyline
        v-if="routeCoordinates.length"
        :lat-lngs="routeCoordinates"
        color="blue"
      />
    </l-map>

    <!-- Buttons -->
  </div>
</template>

<script>
import L from "leaflet";
import MapLayers from "@/libs/map/MapLayers";
import { LMap, LTileLayer, LPolyline } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import angleJs from "@/libs/map/js/angle";

export default {
  components: {
    LMap,
    LTileLayer,
    LPolyline
  },
  data() {
    return {
      latLng: [0, 0], // Initial center coordinates
      zoom: 6,
      routeCoordinates: [],
      unitMarker: null,
      startMarker: null, // Marker for the start of the route
      endMarker: null, // Marker for the end of the route
      socketSimulation: null, // Simulated socket connection
      recenterEnable: true,
      userInteracted: false
    };
  },
  methods: {
    initializeMap() {
      const map = this.$refs.unitMap.mapObject;
      map.zoomControl._zoomInButton.title = this.$t("ZoomIn") || "Zoom In";
      map.zoomControl._zoomOutButton.title = this.$t("ZoomOut") || "Zoom Out";

      const me = this;
      let recenterButton = null; // Reference to the recenter button

      let RecenterControl = L.Control.extend({
        onAdd: function (map) {
          // Create recenter button
          recenterButton = L.DomUtil.create(
            "a",
            "leaflet-control-recenter leaflet-bar-part"
          );

          recenterButton.href = "#";
          recenterButton.title = me.$t("RecenterMap") || "Recenter Map";
          recenterButton.innerHTML = `<img class="playback-recenter" src="${me.getCurrentPath()}/app/images/crosshair.png" alt="Recenter" />`;

          // Handle click event
          L.DomEvent.on(recenterButton, "click", (e) => {
            L.DomEvent.stopPropagation(e);
            L.DomEvent.preventDefault(e);

            if (me.recenterEnable) {
              recenterButton.innerHTML = `<img class="playback-recenter" src="${me.getCurrentPath()}/app/images/crosshair-off.png" alt="Recenter" />`;
              me.recenterEnable = false;
            } else {
              recenterButton.innerHTML = `<img class="playback-recenter" src="${me.getCurrentPath()}/app/images/crosshair.png" alt="Recenter" />`;
              me.zoomToPolyline(true);
              me.recenterEnable = true;
            }
          });

          return recenterButton;
        },

        onRemove: function (map) {
          // Clean up events if necessary
          if (recenterButton) {
            L.DomEvent.off(recenterButton, "click");
          }
        }
      });

      // Add the custom control to the map
      new RecenterControl({ position: "topleft" }).addTo(map);

      new MapLayers(map);
      map.invalidateSize();

      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latLng = [position.coords.latitude, position.coords.longitude];
          },
          () => {
            this.latLng = [0, 0];
            this.zoom = 3;
          }
        );
      }

      map.on("zoomend", (e) => {
        if (me.userInteracted) {
          if (me.recenterEnable) {
            me.recenterEnable = false;
            recenterButton.innerHTML = `<img class="playback-recenter" src="${me.getCurrentPath()}/app/images/crosshair-off.png" alt="Recenter" />`;
          }
        }
        me.userInteracted = false;
      });
      map.on("moveend", (e) => {
        if (me.userInteracted) {
          if (me.recenterEnable) {
            me.recenterEnable = false;
            recenterButton.innerHTML = `<img class="playback-recenter" src="${me.getCurrentPath()}/app/images/crosshair-off.png" alt="Recenter" />`;
          }
        }
        me.userInteracted = false;
      });
      map.on("mousedown", (e) => {
        if (
          e?.originalEvent?.target &&
          e.originalEvent.target.classList.contains("playback-recenter")
        ) {
          return;
        }
        me.userInteracted = true;
      });

      map.on("wheel", (e) => {
        me.userInteracted = true;
      });
    },

    zoomToPolyline(skipFlag) {
      if (!this.recenterEnable && !skipFlag) {
        return;
      }
      const map = this.$refs.unitMap.mapObject;
      if (this.routeCoordinates.length) {
        const bounds = L.latLngBounds(this.routeCoordinates);
        map.fitBounds(bounds); // Zoom to fit polyline
      }
    },

    stopAddingCoordinates() {
      if (this.socketSimulation) {
        clearInterval(this.socketSimulation);
        this.socketSimulation = null;
      }
    },
    startRouteMarker(unit, angle, totalDistance) {
      const map = this.$refs.unitMap.mapObject;
      if (this.unitMarker) {
        this.unitMarker.remove();
        this.unitMarker = null; // Clear reference for garbage collection
      }
      if (this.routeCoordinates.length) {
        const startCoord = this.routeCoordinates[0];

        if (!this.startMarker) {
          this.startMarker = L.marker(startCoord, {
            icon: L.icon({
              iconUrl:
                this.getCurrentPath() + "/app/images/unit-icon/start-point.svg",
              iconSize: [38, 38],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -38]
            })
            //    rotationAngle: angle || 0
          }).addTo(map);
        } else {
          this.startMarker.setLatLng(startCoord);
          //    this.startMarker.setRotationAngle(angle || 0);
        }
      }

      if (this.routeCoordinates.length > 1) {
        const endCoord =
          this.routeCoordinates[this.routeCoordinates.length - 1];

        if (!this.endMarker || totalDistance == 0) {
          if (this.endMarker) {
            if (this.endMarker) {
              this.endMarker.remove();
              this.endMarker = null; // Clear reference for garbage collection
            }
          }
          this.endMarker = L.marker(endCoord, {
            icon: L.icon({
              iconUrl:
                unit && unit.icon
                  ? unit.icon
                  : this.getCurrentPath() +
                    "/app/images/unit-icon/end-point.svg",
              iconSize: [38, 38],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -38]
            }),
            rotationAngle: angle || 0
          }).addTo(map);
        } else {
          this.endMarker.setLatLng(endCoord);
          this.endMarker.setRotationAngle(angle || 0);
        }
      }
    },
    setIcon(startCoord, unit, angle) {
      const map = this.$refs.unitMap.mapObject;

      this.unitMarker = L.marker(startCoord, {
        icon: L.icon({
          iconUrl:
            unit && unit.icon
              ? unit.icon
              : this.getCurrentPath() + "/app/images/unit-icon/end-point.svg",
          iconSize: [38, 38],
          iconAnchor: [22, 38],
          popupAnchor: [-3, -38]
        }),
        rotationAngle: angle || 0
      }).addTo(map);
      const bounds = L.latLngBounds([startCoord]);
      map.fitBounds(bounds);
    },
    mapCenter(startCoord) {
      const map = this.$refs.unitMap.mapObject;

      const bounds = L.latLngBounds([startCoord]);
      map.fitBounds(bounds);
    },
    updateRouteMarkers(avatarUrl) {
      const map = this.$refs.unitMap.mapObject;

      // Add or update the start marker

      // Add or update the end marker
      if (this.routeCoordinates.length > 1) {
        const endCoord =
          this.routeCoordinates[this.routeCoordinates.length - 1];
        if (!this.endMarker) {
          this.endMarker = L.marker(endCoord, {
            icon: L.icon({
              iconUrl: avatarUrl,
              iconSize: [38, 38],
              iconAnchor: [22, 38],
              popupAnchor: [-3, -38]
            })
          }).addTo(map);
        } else {
          this.endMarker.setLatLng(endCoord);
        }
        this.zoomToPolyline();
      }
    }
  },
  beforeDestroy() {
    this.stopAddingCoordinates();
  }
};
</script>

<style>
button {
  margin-top: 10px;
  padding: 8px 16px;
  cursor: pointer;
}
</style>
<style lang="scss">
.map-size {
  height: 90vh;
  width: 100%;
}
.share-location-map {
  .leaflet-control-recenter {
    background-color: white;
    border: 1px solid #c4b7b7;
    cursor: pointer;
    margin-left: 11px !important;
    padding: 3px;
  }
}
.playback-recenter {
  width: 16px;
  height: 16px;
}
</style>
