<template>
  <div>
    <b-modal
      id="unit-allocate"
      centered
      size="lg"
      modal-class="no-header-modal"
      @hide="closeUnitAllocate"
    >
      <b-card-text class="modal-h-s">
        <h4 class="text-center">
          {{
            allocationData && allocationData.allocatedToId
              ? $t("device.ReallocateTo")
              : $t("device.AllocateTo")
          }}
        </h4>
        <div
          class="unit-scroll-a"
          :class="
            $route.name === 'devices-list' || 'unit-list' ? 'unit-scroll-h' : ''
          "
        >
          <validation-observer ref="unitAllocationRules">
            <b-row>
              <b-col :md="isDevice ? 12 : 6">
                <validation-provider
                  #default="validationContext"
                  name="field"
                  rules="required"
                >
                  <b-form-group
                    :label="
                      isDevice
                        ? allocationData && allocationData.allocatedToId
                          ? $t('device.DeviceReallocateTo')
                          : $t('device.DeviceallocateTo')
                        : $t('unit.UnitReAllocateTo')
                    "
                    :state="getValidationState(validationContext)"
                  >
                    <treeselect
                      v-model="allocate_to_id"
                      :multiple="false"
                      :options="unitReAllocateTo"
                      :searchable="true"
                      ref="treeselect"
                      :clearable="false"
                      :clear-on-select="true"
                      :allowClearingDisabled="true"
                      @open="handleMenuOpen"
                      @select="handleTreeSelect"
                      @search-change="searchAccount"
                    >
                      <div
                        slot="option-label"
                        class="tree-select-opction"
                        slot-scope="{ node }"
                        :title="node.label"
                      >
                        {{ node.label }}
                      </div>
                    </treeselect>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{
                        validationContext.errors[0]
                          ? handleError(validationContext.errors[0])
                          : ""
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>

          <div class="d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="!allocate_to_id"
              @click="checkDeviceAllocated(id, $event)"
            >
              {{ isDevice ? $t("device.Allocate") : $t("device.Save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              @click="onCloseAllocation()"
              class="mt-2"
            >
              {{ $t("device.Cancel") }}
            </b-button>
          </div>

          <!-- <hr />
          <div class="mt-1">
            <h6 class="text-center mr-b-1">{{ $t("device.history") }}</h6>

            <History
              :isPaginationVisible="false"
              :myHistoryData="allocationHistory"
              :currentPage="currentPage"
              :perPage="perPage"
              :totalDevices="totalUnit"
              :callMyApiPart="showAllocation"
            />
          </div> -->
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
      id="is-device-allocated-unit"
      centered
      size="sm"
      modal-class="no-header-modal"
    >
      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          :disabled="!allocate_to_id"
          v-b-modal.unit-allocate
          @click="deviceReallocation"
        >
          {{ $t("unit.Yes") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          @click="onCloseModel"
          class="mt-2"
        >
          {{ $t("unit.No") }}
        </b-button>
      </div>
    </b-modal>
    <Loader :show="show" />
  </div>
</template>
<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Loader from "@/layouts/components/Loader.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BBadge,
  BCardText,
  BTab,
  BTabs,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import { required } from "@validations";
import AccountService from "@/libs/api/account-service";
import UnitService from "@/libs/api/unit-service";
import DeviceService from "@/libs/api/device-service";
import History from "@/layouts/components/History.vue";
import * as moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Ripple from "vue-ripple-directive";
import constants from "@/utils/constants";
import DateTimePicker from "../DynamicComponents/DateWithTimePicker.vue";

export default {
  name: "AllocationPopUp",
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BBadge,
    BCardText,
    History,
    Treeselect,
    BTab,
    BTabs,
    DateTimePicker,
    BFormCheckbox,
    Loader
  },

  props: [
    "allocationData",
    "onClose",
    "changeInput",
    "unit",
    "isDevice",
    "refreshed"
  ],
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      id: null,
      allocate_to_id: null,
      old_allocate_to_id: null,
      unit_driver_id: null,
      showLoading: false,
      unitReAllocateTo: [],
      unitDriver: [],
      required,
      refFormObserver,
      getValidationState,
      start_date: this.formattedDateTime(constants.DATE_TIME_FORMAT),
      end_date: null,
      disabledDate: this.DateTimeConvert(),
      endDateDisabled: null,
      filter_start_date: null,
      filter_end_date: null,
      allocationHistory: [],
      currentPage: 1,
      modelText: "",
      totalUnit: 0,
      perPage: 10,
      minTime: null,
      isFetchingOptions: false,
      isUnitAllocated: "",
      filterAccount: "",
      cannotAllocate: "",
      isTransferUnitData: false,
      show: false
    };
  },
  mounted() {
    this.getChildAccounts();
    this.unitAllocationHistory();
  },
  watch: {
    allocationData(val) {
      this.allocate_to_id = this.isDevice
        ? this.allocationData.allocate_to_id
          ? this.allocationData.allocate_to_id
          : this.allocationData.allocatedToId
        : this.allocationData.allocate_to_id
        ? this.allocationData.allocate_to_id
        : this.allocationData["allocated_id"];
    }
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  beforeDestroy() {
    removeEventListener("scroll", (event) => {});
  },
  methods: {
    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitAllocationRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    },
    onCloseAllocation() {
      this.$bvModal.hide("unit-allocate");
    },
    onCloseUnitAllocation() {
      this.$bvModal.hide("is-unit-allocated");
    },
    handleMenuOpen() {
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu();
        const hasReachedEnd =
          menu.scrollHeight - menu.scrollTop <= menu.clientHeight * 0.25;
        menu.addEventListener("scroll", () => {
          if (hasReachedEnd) this.fetchOptions();
        });
      });
    },

    async fetchOptions() {
      if (this.isFetchingOptions) return;
      this.isFetchingOptions = true;

      let response = await new AccountService().getChildAccounts({
        isChild: true,
        page: this.currentPage,
        page_size: parseInt(this.perPage),
        filterAccount: filterAccount || undefined
      });

      if (response && response.data) {
        this.unitReAllocateTo = response.data;
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.isFetchingOptions = false;
    },

    showAllocation() {
      this.unitAllocationHistory();
      this.$bvModal.show("unit-allocate");
    },
    async unitAllocationHistory() {
      if (this.allocationData && this.allocationData.id) {
        let response = this.isDevice
          ? await new DeviceService().deviceAllocationHistory({
              device_id: this.allocationData.id,
              start_date: null,
              end_date: null,
              page: 1,
              page_size: 1
            })
          : await new UnitService().unitAllocationHistory({
              id: this.allocationData.id
                ? this.allocationData.id
                : this.allocationData.unit_id,
              start_date: this.convertDatePerTimezone(this.filter_start_date),
              end_date: this.convertDatePerTimezone(this.filter_end_date),
              page: 1,
              page_size: 1
            });
        if (response && response.data) {
          this.show = false;
          let allocateHostory = [];
          if (
            !this.isDevice &&
            response.data.unitAllocationHistory &&
            response.data.unitAllocationHistory.length
          ) {
            response.data.unitAllocationHistory.map((h) => {
              let history = {
                account: h.account,
                start_date: h.start_date,
                end_date: h.end_date ? h.end_date : "",
                unit_id: h.unit_id,
                isEditable: h.isEditable ? h.isEditable : "",
                isDeleteable: h.isDeleteable ? h.isDeleteable : ""
              };
              allocateHostory.push(history);
            });
          } else if (
            this.isDevice &&
            response.data.deviceAllocationHistory &&
            response.data.deviceAllocationHistory.length
          ) {
            response.data.deviceAllocationHistory.map((h) => {
              let history = {
                account: h.account,
                start_date: this.DateTimeConvert(h.start_date),
                end_date: h.end_date ? this.DateTimeConvert(h.end_date) : ""
              };
              allocateHostory.push(history);
            });
          }

          this.allocationHistory = allocateHostory;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    closeUnitAllocate() {
      this.end_date = null;
      this.isTransferUnitData = false;
    },
    async getChildAccounts(filterAccount) {
      try {
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || undefined,
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });

        if (response && response.data && !this.isDevice) {
          this.unitReAllocateTo = response.data;
        } else if (response && response.data && this.isDevice) {
          function recursive(data) {
            if (data && data.type.toUpperCase() == "CONSUMER") return true;

            if (data && data.children) {
              for (let i = 0; i < data.children.length; i++) {
                if (recursive(data.children[i])) {
                  // delete data.children[i];
                  data.children[i]["isDisabled"] = true;
                }
              }
            }
            return false;
          }
          recursive(response.data[0]);
          this.unitReAllocateTo = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        if (this.allocationData && this.allocationData.allocate_to_id) {
          this.allocate_to_id = this.allocationData.allocate_to_id
            ? this.allocationData.allocate_to_id
            : this.allocationData["allocated_id"];

          this.start_date = this.allocationData.start_date
            ? moment(this.allocationData.start_date).format()
            : null;
          this.end_date = this.allocationData.end_date
            ? moment(this.allocationData.end_date).format()
            : null;
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    onCloseModel() {
      this.$bvModal.hide("is-device-allocated-unit");
    },
    async checkDeviceAllocated() {
      try {
        if (!this.allocate_to_id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.PleaseSelectAccount"),
              icon: "EditIcon",
              variant: "danger"
            }
          });
          return;
        }
        if (
          this.allocationData.allocatedToId &&
          this.allocationData.allocate_to_id &&
          this.allocationData.allocatedToId ===
            this.allocationData.allocate_to_id
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.alreadyAllocatedSameAccount"),
              icon: "EditIcon",
              variant: "danger"
            }
          });
          return;
        }

        this.deviceReallocation();
        this.onCloseModel();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async deviceReallocation(id, e) {
      try {
        const requestData = {
          sim_id: this.allocationData.id,
          old_sim_owner_id: this.allocationData.allocatedToId,
          account_id: this.allocate_to_id
        };
        this.show = true;
        this.$bvModal.hide("unit-allocate");
        this.$bvModal.hide("is-device-allocated-unit");
        let response = await new DeviceService().reallocateSim(requestData);
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("sim.AllocatedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.show = false;
          this.refreshed();
          // this.unitAllocationHistory();
          this.$bvModal.hide("unit-allocate");
          this.$bvModal.hide("is-device-allocated-unit");
          this.onClose();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (error) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    getMessage(e) {
      const delimiter = "Correlation ID: ";
      const parts = e.split(delimiter);
      if (parts.length) {
        return parts[0];
      } else {
        return e + "?";
      }
    },
    handleTreeSelect(e) {
      this.changeInput({
        ...this.allocationData
      });
    }
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.flat-picker-date {
  display: flex;
  justify-content: center;
  align-items: center;
  .form-control {
    border-radius: 0.357rem 0rem 0rem 0.357rem;
  }
}
.flat-picker-icon {
  border-right: 1px solid var(--gray4);
  border-top: 1px solid var(--gray4);
  border-bottom: 1px solid var(--gray4);
  border-radius: 0rem 0.357rem 0.357rem 0rem;
  padding: 0.438rem 1rem;
}
#unit-allocate .modal-dialog.modal-lg {
  max-width: 685px;
}
// .unit-scroll-a{
//    max-height: calc(100vh - 330px) !important;
//    overflow-y: auto;
//    overflow-x: hidden;

// }
.modal-h-s {
  max-height: 99vh;
}
</style>
<style lang="scss">
.unit-scroll-h {
  .scroll-h-table-create {
    height: auto !important;
  }
}
</style>
<style lang="scss">
.new-date-picker-25 {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    left: 0 !important;
    transform: translate(0%) !important;
  }
}
</style>
<style lang="scss" scoped>
.margin-left-8px {
  margin-left: 8px;
}
</style>
